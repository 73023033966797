import React from "react";
// react plugin used to create charts
import Loader from "../components/Loader/Loader";
import ReactTable from "react-table";
import ReactDatetime from "react-datetime";
import LoadingOverlay from 'react-loading-overlay';
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Collapse,
    FormGroup,
    Input,
    Label,
    Row,
    Col,
} from "reactstrap";


const o_date = new Intl.DateTimeFormat();
const f_date = (m_ca, m_it) => Object({ ...m_ca, [m_it.type]: m_it.value });
const m_date = o_date.formatToParts().reduce(f_date, {});

if (m_date.month.length < 2) {
    m_date.month = "0" + m_date.month
}
if (m_date.day.length < 2) {
    m_date.day = "0" + m_date.day
}

class OfferReminder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            toDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            fromDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            loadingBookingsToday: true,
            loadingComplaints: true,
            loadingBookings: false,
            sendingEmailActive: false,
            offerArray: [],
            reklamasjon: 0,
            spinnerActive: false,
            rabatt: 0,
        };

        this.refreshClick = this.refreshClick.bind(this);
        this.confirmSendEmail = this.confirmSendEmail.bind(this);
    }

    setRabatt(e){
        this.setState({
            rabatt: e.target.value,
        })
    }

    confirmSendEmail(){
        this.confirmAlert("Er du sikker på at du vil sende epost til kundene og gi "+this.state.rabatt +"% rabatt på mottatt tilbud?")
    }

    sendEmail() {
        this.hideAlert();
    
        this.setState({
            spinnerActive: true,
        });
    
        fetch(global.config.server.connection.url + "/api/CRM/SendOfferReminder?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate +"&discount=" + this.state.rabatt, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(
            (result) => {
                this.setState({
                    spinnerActive: false,
                });
                this.successAlert();
            }
        )
        .catch(error => {
            // Handle the error (e.g., server error) here
            console.error('Error:', error.message);
            this.setState({
                spinnerActive: false,
            });
        });
    }
    
    successAlert = () => {
        this.setState({
          alert: (
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Bra jobbet!"
              onConfirm={() => this.hideAlert()}
              confirmBtnBsStyle="success"              
            >
              Alt i orden, epost er sendt til kundene.
            </ReactBSAlert>
          )
        });
      };

    confirmAlert = (message) => {
        this.setState({
          alert: (
            <ReactBSAlert
              warning
              showCancel
              style={{ display: "block", marginTop: "-100px" }}
              title="Sende epost med rabatt?"
              onConfirm={() => this.sendEmail()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="info"
            >
              {message}
            </ReactBSAlert>
          )
        });
      };
    
      hideAlert = () => {
        this.setState({
          alert: null
        });
      };

    refreshClick() {

        this.setState({
            spinnerActive: true,
        })
        fetch(global.config.server.connection.url + "/api/CRM/Offers?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    this.setState({
                        loadingBookings: false,
                        spinnerActive: false,
                        offerArray: result.map((prop, key) => {
                            return {
                                id: key,
                                id: prop["id"],
                                kundeNr: prop["kundeNr"],
                                totalSum: prop["totalSum"],
                                customerEmail: prop["customerEmail"],
                                reminderSent: prop["reminderSent"],
                                reminderDiscount: prop["reminderDiscount"]+" %",
                            }
                        })
                    });
                },
            )
    }



    componentDidMount() {

        fetch(global.config.server.connection.url + "/api/Dashboards/JobsTodayAllCompanies", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        loadingBookingsToday: false,
                        bookings: result
                    });
                },
            )

        fetch(global.config.server.connection.url + "/api/Dashboards/ComplaintsTodayAllCompanies", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        loadingComplaints: false,
                        complaints: result
                    });
                },
            )

        fetch(global.config.server.connection.url + "/api/CRM/Offers?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("RESULT", result)
                    this.setState({
                        loadingBookings: false,
                        spinnerActive: false,
                        offerArray: result.map((prop, key) => {
                            return {
                                id: key,
                                id: prop["id"],
                                kundeNr: prop["kundeNr"],
                                totalSum: prop["totalSum"],
                                customerEmail: prop["customerEmail"],
                                reminderSent: prop["reminderSent"],
                                reminderDiscount: prop["reminderDiscount"] +" %",
                            }
                        })
                    });
                },
            )
    }

    changeFromDate = (event) => {
        this.setState({ ...this.state, fromDate: event.format("DD-MM-YYYY") })
    }

    changeToDate = (event) => {
        this.setState({ ...this.state, toDate: event.format("DD-MM-YYYY") })
    }


    render() {

        const { loadingBookingsToday, loadingComplaints, loadingBookings, spinnerActive, sendingEmailActive } = this.state;

        if (loadingBookingsToday || loadingComplaints || loadingBookings || sendingEmailActive) {
            return <Loader />
        }

        return (
            <>
                {this.state.alert}
                <div className="content">

                    <Row>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-chart-bar-32 text-warning" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Bookinger i fjor</p>
                                                <CardTitle tag="p">
                                                    {this.state.bookings[0][0]}
                                                </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-calendar" /> På denne dag, for ett år siden
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-chart-bar-32 text-warning" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Bookinger i dag</p>
                                                <CardTitle tag="p">
                                                    {this.state.bookings[0][1]}
                                                </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-calendar" />Totalt antall bookinger i dag
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-favourite-28 text-primary" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Budsjett</p>
                                                <CardTitle tag="p">33</CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-bar-chart" />Budsjetterte bookinger i dag
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>


                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-ambulance text-danger" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Reklamasjoner</p>
                                                <CardTitle tag="p"> {this.state.complaints}</CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-thumbs-down" /> Reklamasjoner booket i dag
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Oversikt tilbud</CardTitle>
                                    <Row>
                                        <Col md="2">
                                            <Label>Fra dato:</Label>
                                            <ReactDatetime
                                                value={this.state.fromDate}
                                                closeOnSelect={true}
                                                dateFormat='DD-MM-YY'
                                                timeFormat={false}
                                                onChange={this.changeFromDate}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Velg fra-dato",
                                                }}


                                            />
                                        </Col>
                                        <Col md="2">
                                            <Label>Til dato:</Label>
                                            <ReactDatetime
                                                value={this.state.toDate}
                                                closeOnSelect={true}
                                                dateFormat='DD-MM-YY'
                                                timeFormat={false}
                                                onChange={this.changeToDate}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Velg til-dato"
                                                }}
                                            />
                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop: 10 + 'px' }}>
                                        <Col md="12">
                                            <Button className="btn-round" color="success" onClick={e => this.refreshClick()}>
                                                Hent tilbud
                                            </Button>
                                        </Col>
                                    </Row>

                                </CardHeader>

                                <LoadingOverlay
                                    active={spinnerActive}
                                    spinner
                                    styles={{
                                        spinner: (base) => ({
                                            ...base,
                                            width: '150px',
                                            '& svg circle': {
                                                stroke: 'rgba(107, 208, 152, 1)'
                                            }
                                        })
                                    }}
                                    text='Loading your content...'
                                >
                                </LoadingOverlay>

                                <CardBody>
                                    <ReactTable
                                        data={this.state.offerArray}
                                        defaultPageSize={5}
                                        showPaginationTop
                                        showPaginationBottom
                                        previousText="Forrige"
                                        nextText="Neste"
                                        className="-striped -highlight success-pagination"
                                        columns={[
                                            {
                                                accessor: "id",
                                                visible: false,
                                                show: false,
                                            },
                                            {
                                                Header: "Tilbud Id",
                                                accessor: "id",
                                                sortable: false,
                                                filterable: false,
                                                width: 100,
                                            },
                                            {
                                                Header: "KundeNr.",
                                                accessor: "kundeNr",
                                                filterable: false,
                                                sortable: false,
                                                width: 110,
                                            },
                                            {
                                                Header: "Epost.",
                                                accessor: "customerEmail",
                                                filterable: false,
                                                sortable: false,
                                            },
                                            {
                                                Header: "Total Sum",
                                                accessor: "totalSum",
                                                filterable: false,
                                                sortable: false,
                                                width: 100,
                                            },
                                            {
                                                Header: "Påminnelse",
                                                accessor: "reminderSent",
                                                filterable: false,
                                                sortable: false,
                                                width: 130,
                                            },
                                            {
                                                Header: "Rabatt",
                                                accessor: "reminderDiscount",
                                                filterable: false,
                                                sortable: false,
                                                width: 100,
                                                headerStyle: { textAlign: 'left' },
                                            },
                                        ]}
                                    />

                                    <Row style={{ marginTop: 10 + 'px' }}>
                                        
                                        <Col md="12">
                                            <p style={{paddingTop: 40 + 'px'}}>Skriv inn rabatt i % for aktuelle tilbud:  
                                                <input style={{width: 40 + 'px', marginLeft: 20 +'px'}} onChange={e => this.setRabatt(e)}></input> %
                                            </p>
                                           
                                        </Col>
                                        <Col md="12">
                                            <Button className="btn-round" color="success" onClick={e => this.confirmSendEmail()}>
                                                Send tilbud/ rabatt
                                            </Button>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </div>
            </>
        );
    }
}

export default OfferReminder;
