import React from "react";
import Loader from "../Loader/Loader";
import ReactTable from "react-table";
import ReactDatetime from "react-datetime";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import LoadingOverlay from 'react-loading-overlay';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Label,
    Row,
    Col,
} from "reactstrap";


const o_date = new Intl.DateTimeFormat();
const f_date = (m_ca, m_it) => Object({ ...m_ca, [m_it.type]: m_it.value });
const m_date = o_date.formatToParts().reduce(f_date, {});

if (m_date.month.length < 2) {
    m_date.month = "0" + m_date.month
}
if (m_date.day.length < 2) {
    m_date.dat = "0" + m_date.day
}

class CustomerSubscriptionAnswers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            fromDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            isLoadingCampaignAnswers: true,
            isLoading: true,
            subscriptionsArray: [],
            selectedCampaignArray: [],
            spinnerActive: false,
            selectedCampaign: 1,
        };

        this.fetchData = this.fetchData.bind(this);
        this.setVisibility = this.setVisibility.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }


    fetchData() {

        this.setState({
            spinnerActive: true,
            campaignAnswerCount: 0,
        })

        //Subscription answers
        fetch(global.config.server.connection.url + "/api/CustomerSurvey/SubscriptionAnswers?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate +"&selectedCampaign=" +this.state.selectedCampaign, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoadingCampaignAnswers: false,
                        spinnerActive: false,
                        isLoading: false,
                        subscriptionsArray: result.map((prop, key) => {
                            return {
                                id: key,
                                customerId: prop["customerId"],
                                jobId: prop["jobId"],
                                answer: prop["answer"],
                                date: prop["date"],
                                comment: prop["comment"],
                                executionDate: prop["executionDate"],
                                contactPreference: prop["contactPreference"],
                                customerLink: 'http://intranet-elektris-alb-1863918207.eu-west-1.elb.amazonaws.com/booking/htdocs/adm/index.php?action=editcompanypopup&companyId=' +prop["customerId"]
                            }
                        }),
                        
                    });
                },
            )

    }


    setVisibility(event) {
        if(this.state.subscriptionsArray.filter(item => item.answer === event).length > 0) {
            return 'block'
         }
         else
         {
            return 'none'
         }
    }

    changeFromDate = (event) => {
        this.setState({ ...this.state, fromDate: event.format("DD-MM-YYYY") })
    }

    changeToDate = (event) => {
        this.setState({ ...this.state, toDate: event.format("DD-MM-YYYY") })
    }


    render() {

        const { isLoading, spinnerActive, isLoadingCampaignAnswers } = this.state;

        if (isLoading || isLoadingCampaignAnswers) {
            return <Loader />
        }

        return (
            <>
                <div className="content">

                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Abonnementsønsker</CardTitle>
                                    <Row>
                                        <Col md="2">
                                            <Label>Fra dato:</Label>
                                            <ReactDatetime
                                                value={this.state.fromDate}
                                                closeOnSelect={true}
                                                dateFormat='DD-MM-YY'
                                                timeFormat={false}
                                                onChange={this.changeFromDate}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Velg fra-dato",
                                                }}
                                            />
                                        </Col>
                                        <Col md="2">
                                            <Label>Til dato:</Label>
                                            <ReactDatetime
                                                value={this.state.toDate}
                                                closeOnSelect={true}
                                                dateFormat='DD-MM-YY'
                                                timeFormat={false}
                                                onChange={this.changeToDate}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Velg til-dato"
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <Button color="success" onClick={e => this.fetchData()}>
                                                Oppdater
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        
                                        <Col md="4">
                                            <br></br>
                                          <h5>Antall treff: {this.state.subscriptionsArray.length}</h5>
                                        </Col>
                                    </Row>
                                    <br></br><hr></hr>
                                </CardHeader>

                                <LoadingOverlay
                                    active={spinnerActive}
                                    spinner
                                    styles={{
                                        spinner: (base) => ({
                                            ...base,
                                            width: '150px',
                                            '& svg circle': {
                                                stroke: 'rgba(107, 208, 152, 1)'
                                            }
                                        })
                                    }}
                                    text='Loading your content...'
                                >
                                </LoadingOverlay>

                                <CardBody style={{display: this.setVisibility(1)}}>
                                <h5>Bytting av lyspærer og batterialarmer. (NOK 399 pr. mnd - ingen bindingstid)</h5>
                                    <ReactTable
                                       showPagination={false} 
                                        pageSize = {this.state.subscriptionsArray.filter(item => item.answer === 1).length}
                                        data={this.state.subscriptionsArray.filter(item => item.answer === 1)}
                                        className="-striped"
                                        columns={[
                                            {
                                                Header: "Kunde ID",
                                                accessor: "customerId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Job ID",
                                                accessor: "jobId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Dato",
                                                accessor: "date",
                                                width: 150,
                                                filterable: false,

                                            },
                                            {
                                                Header: "Når",
                                                accessor: "executionDate",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kontakt",
                                                accessor: "contactPreference",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kommentar",
                                                accessor: "comment",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Link",
                                                accessor: "customerLink",
                                                width: 110,
                                                filterable: false,
                                                Cell: props => <a target='top' href={props.value}>Kundekort</a>,
                                                headerStyle: { textAlign: 'left' }
                                            },
                                        ]}
                                    />
                                </CardBody>
                                
                                <CardBody style={{display: this.setVisibility(2)}}>
                                <h4>Småfix - el og annet. (NOK 999 pr. mnd - ingen bindingstid)</h4>
                                    <ReactTable
                                       showPagination={false} 
                                       pageSize = {this.state.subscriptionsArray.filter(item => item.answer === 2).length}
                                       data={this.state.subscriptionsArray.filter(item => item.answer === 2)}
                                       className="-striped"
                                        columns={[
                                            {
                                                Header: "Kunde ID",
                                                accessor: "customerId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Job ID",
                                                accessor: "jobId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Dato",
                                                accessor: "date",
                                                width: 150,
                                                filterable: false,

                                            },
                                            {
                                                Header: "Når",
                                                accessor: "executionDate",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kontakt",
                                                accessor: "contactPreference",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kommentar",
                                                accessor: "comment",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Link",
                                                accessor: "customerLink",
                                                width: 110,
                                                filterable: false,
                                                Cell: props => <a target='top' href={props.value}>Kundekort</a>,
                                                headerStyle: { textAlign: 'left' }
                                            },
                                        ]}
                                    />
                                </CardBody>

                                <CardBody style={{display: this.setVisibility(3)}}>
                                <h4>Boligalarm med videovervåking og bevegelsessensor (NOK 950 pr. mnd - ett års bindingstid)</h4>
                                    <ReactTable
                                        showPagination={false} 
                                        pageSize = {this.state.subscriptionsArray.filter(item => item.answer === 3).length}
                                        data={this.state.subscriptionsArray.filter(item => item.answer === 3)}
                                        className="-striped"
                                        columns={[
                                            {
                                                Header: "Kunde ID",
                                                accessor: "customerId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Job ID",
                                                accessor: "jobId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Dato",
                                                accessor: "date",
                                                width: 150,
                                                filterable: false,

                                            },
                                            {
                                                Header: "Når",
                                                accessor: "executionDate",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kontakt",
                                                accessor: "contactPreference",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kommentar",
                                                accessor: "comment",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Link",
                                                accessor: "customerLink",
                                                width: 110,
                                                filterable: false,
                                                Cell: props => <a target='top' href={props.value}>Kundekort</a>,
                                                headerStyle: { textAlign: 'left' }
                                            },
                                        ]}
                                    />
                                </CardBody>

                                <CardBody style={{display: this.setVisibility(4)}}>
                                <h4>Smarthuspakken (NOK 890 pr. mnd - ingen bindingstid)</h4>
                                    <ReactTable
                                        showPagination={false} 
                                        pageSize = {this.state.subscriptionsArray.filter(item => item.answer === 4).length}
                                        data={this.state.subscriptionsArray.filter(item => item.answer === 4)}
                                        className="-striped"
                                        columns={[
                                            {
                                                Header: "Kunde ID",
                                                accessor: "customerId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Job ID",
                                                accessor: "jobId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Dato",
                                                accessor: "date",
                                                width: 150,
                                                filterable: false,

                                            },
                                            {
                                                Header: "Når",
                                                accessor: "executionDate",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kontakt",
                                                accessor: "contactPreference",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kommentar",
                                                accessor: "comment",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Link",
                                                accessor: "customerLink",
                                                width: 110,
                                                filterable: false,
                                                Cell: props => <a target='top' href={props.value}>Kundekort</a>,
                                                headerStyle: { textAlign: 'left' }
                                            },
                                        ]}
                                    />
                                </CardBody>

                                <CardBody style={{display: this.setVisibility(6)}}>
                                <h5>Strømgaranti. (NOK 899 pr. mnd - to års bindingstid)</h5>
                                    <ReactTable
                                       showPagination={false} 
                                        pageSize = {this.state.subscriptionsArray.filter(item => item.answer === 6).length}
                                        data={this.state.subscriptionsArray.filter(item => item.answer === 6)}
                                        className="-striped"
                                        columns={[
                                            {
                                                Header: "Kunde ID",
                                                accessor: "customerId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Job ID",
                                                accessor: "jobId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Dato",
                                                accessor: "date",
                                                width: 150,
                                                filterable: false,

                                            },
                                              {
                                                Header: "Når",
                                                accessor: "executionDate",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kontakt",
                                                accessor: "contactPreference",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kommentar",
                                                accessor: "comment",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Link",
                                                accessor: "customerLink",
                                                width: 110,
                                                filterable: false,
                                                Cell: props => <a target='top' href={props.value}>Kundekort</a>,
                                                headerStyle: { textAlign: 'left' }
                                            },
                                        ]}
                                    />
                                </CardBody>

                                <CardBody style={{display: this.setVisibility(7)}}>
                                <h5>Bytt strømleverandør. (Prismatch - ingen bindingstid)</h5>
                                    <ReactTable
                                       showPagination={false} 
                                        pageSize = {this.state.subscriptionsArray.filter(item => item.answer === 7).length}
                                        data={this.state.subscriptionsArray.filter(item => item.answer === 7)}
                                        className="-striped"
                                        columns={[
                                            {
                                                Header: "Kunde ID",
                                                accessor: "customerId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Job ID",
                                                accessor: "jobId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Dato",
                                                accessor: "date",
                                                width: 150,
                                                filterable: false,

                                            },
                                              {
                                                Header: "Når",
                                                accessor: "executionDate",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kontakt",
                                                accessor: "contactPreference",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kommentar",
                                                accessor: "comment",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Link",
                                                accessor: "customerLink",
                                                width: 110,
                                                filterable: false,
                                                Cell: props => <a target='top' href={props.value}>Kundekort</a>,
                                                headerStyle: { textAlign: 'left' }
                                            },
                                        ]}
                                    />
                                </CardBody>

                                <CardBody style={{display: this.setVisibility(8)}}>
                                <h5>Just Green. (Markedspris - ingen bindingstid)</h5>
                                    <ReactTable
                                       showPagination={false} 
                                        pageSize = {this.state.subscriptionsArray.filter(item => item.answer === 8).length}
                                        data={this.state.subscriptionsArray.filter(item => item.answer === 8)}
                                        className="-striped"
                                        columns={[
                                            {
                                                Header: "Kunde ID",
                                                accessor: "customerId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Job ID",
                                                accessor: "jobId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Dato",
                                                accessor: "date",
                                                width: 150,
                                                filterable: false,

                                            },
                                              {
                                                Header: "Når",
                                                accessor: "executionDate",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kontakt",
                                                accessor: "contactPreference",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kommentar",
                                                accessor: "comment",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Link",
                                                accessor: "customerLink",
                                                width: 110,
                                                filterable: false,
                                                Cell: props => <a target='top' href={props.value}>Kundekort</a>,
                                                headerStyle: { textAlign: 'left' }
                                            },
                                        ]}
                                    />
                                </CardBody>

                                <CardBody style={{display: this.setVisibility(9)}}>
                                <h5>Strøm og mobil pakken. (Prismatch - ingen bindingstid)</h5>
                                    <ReactTable
                                       showPagination={false} 
                                        pageSize = {this.state.subscriptionsArray.filter(item => item.answer === 9).length}
                                        data={this.state.subscriptionsArray.filter(item => item.answer === 9)}
                                        className="-striped"
                                        columns={[
                                            {
                                                Header: "Kunde ID",
                                                accessor: "customerId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Job ID",
                                                accessor: "jobId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Dato",
                                                accessor: "date",
                                                width: 150,
                                                filterable: false,

                                            },
                                              {
                                                Header: "Når",
                                                accessor: "executionDate",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kontakt",
                                                accessor: "contactPreference",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kommentar",
                                                accessor: "comment",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Link",
                                                accessor: "customerLink",
                                                width: 110,
                                                filterable: false,
                                                Cell: props => <a target='top' href={props.value}>Kundekort</a>,
                                                headerStyle: { textAlign: 'left' }
                                            },
                                        ]}
                                    />
                                </CardBody>

                                <CardBody style={{display: this.setVisibility(5)}}>
                                <h4>Ikke interessert</h4>
                                    <ReactTable
                                      showPagination={false} 
                                      pageSize = {this.state.subscriptionsArray.filter(item => item.answer === 5).length}
                                      data={this.state.subscriptionsArray.filter(item => item.answer === 5)}
                                      className="-striped"
                                        columns={[
                                            {
                                                Header: "Kunde ID",
                                                accessor: "customerId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Job ID",
                                                accessor: "jobId",
                                                width: 110,
                                                filterable: false,
                                            },
                                            {
                                                Header: "Dato",
                                                accessor: "date",
                                                width: 150,
                                                filterable: false,

                                            },
                                            {
                                                Header: "Når",
                                                accessor: "executionDate",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kontakt",
                                                accessor: "contactPreference",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Kommentar",
                                                accessor: "comment",
                                                filterable: false,
                                            },
                                            {
                                                Header: "Link",
                                                accessor: "customerLink",
                                                width: 110,
                                                filterable: false,
                                                Cell: props => <a target='top' href={props.value}>Kundekort</a>,
                                                headerStyle: { textAlign: 'left' }
                                            },
                                        ]}
                                    />
                                </CardBody>



                            </Card>
                        </Col>
                    </Row>



                </div>
            </>
        );
    }
}

export default CustomerSubscriptionAnswers;
