import React from "react";
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom'
import AdminLayout from "layouts/Admin.jsx";
import Loader from "../components/Loader/Loader"
export class AuthRequired extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      validToken: false,
      error: null,
      isAdmin: false,
      isEmployee: false,
      isCustomerService: false,
    };
  }

  componentDidMount() {
    let currentComponent = this;

    fetch(global.config.server.connection.url + "/api/Account/ValidateToken2", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      },
    }).then(
      function (response) {
        if (response.status !== 200) {
          currentComponent.setState({
            loading: false,
            validToken: false,
          });
        }
        else {
          response.text().then(function (data) {

            if (data === '"1"') { //1 = admin
              currentComponent.setState({
                isAdmin: true,
              });
            }
            if (data === '"2"') { //2 = employee
              currentComponent.setState({
                isEmployee: true,
              });
            }
            if (data === '"3"') { //3 = customerService
              currentComponent.setState({
                isCustomerService: true,
              });
            }
            currentComponent.setState({
              loading: false,
              validToken: true,
            });
          });
        }
      })
  }


  render() {

    const { loading, validToken, isAdmin, isCustomerService, isEmployee } = this.state;

    if (loading) {
      return <Loader />
    }

    if (!validToken) {
      return (<Redirect to='/auth/login' />);
    } else {
      return (<Route path="/admin" render={props => <AdminLayout {...props} isAdmin={isAdmin} isEmployee={isEmployee} isCustomerService={isCustomerService} />} />);
    }
  }
}
export default AuthRequired;