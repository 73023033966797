import React from "react";
//import {Redirect } from "react-router-dom";
import Loader from "components/Loader/Loader";
import classnames from "classnames";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";


class ResetPwd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      email: "",
      error: null,
      loading: false,
      isReset: false,
      emailState: "",
      emailFocus: ""
    };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  successAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="That's it!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          If the email provided is registered in our database, we will send a temporarily password.
        </ReactBSAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
    window.location.replace("/auth/login")
  };

  handleClick() {
    if (this.state.emailState === "has-success") {
      this.setState({ loading: true });

      fetch(global.config.server.connection.url + "/api/Account/ResetPassword?email=" + this.state.email, {

        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      })
        .then(
          (result) => {
            this.setState({
              loading: false,
              isReset: true
            });
            console.log("ALERT NOW")
            this.successAlert()
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              loading: false,
              error
            });
            console.log("ERROR reseting password")
          }
        )
    }
  }

  handleChange({ target }) {
    if (this.verifyEmail(target.value)) {
      this.setState({ emailState: "has-success" });
    } else {
      this.setState({ emailState: "has-danger" });
    }
    this.setState({ email: target.value });
  }

  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  render() {
    const { error, loading } = this.state;

    if (this.state.isReset) {
      return (
        <>
          {this.state.alert}
        </>
      )
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (loading) {
      return (
        <div className="content">
          <Loader />
        </div>
      )
    } else {

      return (
        <>
          {this.state.alert}

          <div className="login-page">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                  <Form action="" className="form" method="">
                    <Card className="card-login">
                      <CardHeader>
                        <CardHeader>
                          <h3 className="header text-center">Reset password</h3>
                        </CardHeader>
                      </CardHeader>
                      <CardBody>
                        <p className="header text-center">Enter your email address and recieve an auto generated password. Remember to change your password after login.</p>
                        <InputGroup
                          className={classnames(this.state.emailState, {
                            "input-group-focus": this.state.emailFocus
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name="email"
                            placeholder="Email (required)"
                            type="email"
                            value={this.state.email}
                            onChange={e => this.handleChange(e)}
                            onFocus={e => this.setState({ emailFocus: true })}
                          />
                          {this.state.emailState === "has-danger" ? (
                            <label className="error">A valid email address is required</label>
                          ) : null}

                        </InputGroup>
                      </CardBody>
                      <CardFooter>
                        <Button
                          block
                          className="btn-round mb-3"
                          color="warning"
                          href="#"
                          onClick={this.handleClick}
                        >
                          Send pwd
                        </Button>

                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </Container>
            <div
              className="full-page-background"
              style={{
                backgroundImage: `url(${require("assets/img/bg/electric_bg_1.jpg")})`
              }}
            />
          </div>
        </>
      );
    }
  }
}

export default ResetPwd;
