/**
 *- Application file
 */
// libraries
import React, { useState, useRef } from 'react';

// our stuff
import 'bryntum-scheduler/scheduler.stockholm.css';
import Booking from './Booking';
import Popup from './Popup/Popup';
import NotificationAlert from "react-notification-alert";





function BookingWrapperRokea() {

    const notifyEl = useRef();


    const [popupShown, showPopup] = useState(false),
        [eventRecord, setEventRecord] = useState(null),
        [eventStore, setEventStore] = useState(null),
        [resourceStore, setResourceStore] = useState(null),
        [showKontrollSkjema, setShowKontrollSkjema] = useState('block'),
        [showBookingSkjema, setshowBookingSkjema] = useState('none'),
        main = useRef()
        ;

    const showEditor = (eventRecord) => {
        const { eventStore, resourceStore } = main.current.refs.scheduler.schedulerInstance;
        setEventStore(eventStore);
        setResourceStore(resourceStore);
        setEventRecord(eventRecord);
        showPopup(true);
    }

    const hideEditor = () => {
        setEventRecord(null);
        showPopup(false)
    }

    const hideKontrollSkjema = () => {
        setShowKontrollSkjema('none')
        setshowBookingSkjema('block')
    }

    const displayKontrollSkjema = () => {
        setShowKontrollSkjema('block')
        setshowBookingSkjema('none')
    }

    function myFunc(place, text, color) {
        var options = {};
        options = {
            place: place,
            message: (
                <div>
                    <div>
                        <b>{text}</b>
                    </div>
                </div>
            ),
            type: color,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 3
        }

        notifyEl.current.notificationAlert(options);
    }

    function testingFunc() {
        console.log("IN TESTING FUNCTION")
    }

    return (
        <React.Fragment>

            <NotificationAlert ref={notifyEl} />

            <Booking hideKontrollSkjema={hideKontrollSkjema} displayKontrollSkjema={displayKontrollSkjema} 
                showEditor={showEditor} 
                notifyJobUpdated={() => myFunc('tr', 'Jobber er oppdatert', 'success')} 
                notifyJobCreated={() => myFunc('tr', 'Jobber er booket')}
                notifyJobDeleted={() => myFunc('tr', 'Jobben er slettet', 'success')}  
                testing={() => testingFunc()} ref={main} />

            <div>
                {popupShown ?
                    <Popup
                        text="Popup text"
                        closePopup={hideEditor}
                        eventRecord={eventRecord}
                        eventStore={eventStore}
                        resourceStore={resourceStore}
                        hideKontrollSkjema={hideKontrollSkjema}
                        showKontrollSkjema={showKontrollSkjema}
                        showBookingSkjema={showBookingSkjema}
                        notifyBlacklistedCustomer={() => myFunc('tc', 'OBS: Kunden er svartelistet', 'danger')}
                        notifyInvalidPostalCode={() => myFunc('tl', 'OBS: Postnummeret er utenfor vårt område', 'danger')}
                        notifyNewCustomer={() => myFunc('tl', 'Ny kunde', 'info')}
                        notifyJobCreated={() => myFunc('tl', 'Jobben er booket', 'success')}
                        notifyMissingChecked={() => myFunc('tc', 'Du må velge et alternativ', 'danger')}
                        notifyMissingValues={() => myFunc('tc', 'Du må fylle inn alle verdier', 'danger')}
                    ></Popup> :
                    null}
            </div>
        </React.Fragment>
    );
}

export default BookingWrapperRokea;

// eof
