import React from "react";


class Loader extends React.Component {
  render() {
    return (
      <div className="loading">Loading&#8230;</div>
    );
  }
}

export default Loader;