import React from "react";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import Loader from "../components/Loader/Loader";
import chartCustomerSurvey from '../Dashboards/Charts/CustomerSurvey';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import NewVsOldCustomers from "../components/Custom/NewVsOldCustomers";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
} from "reactstrap";

const current = new Date();
const thisMonth = current.toLocaleString('no-nb', { month: 'long' });
current.setMonth(current.getMonth() - 1);
const previousMonth = current.toLocaleString('no-nb', { month: 'long' });
current.setMonth(current.getMonth() - 1);
const prevPrevMonth = current.toLocaleString('no-nb', { month: 'long' });

const chartCustomerSurveyThreeMonths = {
    data: {
        labels: [
            prevPrevMonth,
            previousMonth,
            thisMonth,
        ],
        datasets: [
            {
                label: "Boligelektrikeren",
                borderColor: "#fcc468",
                pointRadius: 3,
                pointHoverRadius: 3,
                fill: false,
                borderWidth: 3,
                data: [],
                backgroundColor: "#fcc468",
                hoverBorderColor: "#fcc468",
            },
        ]
    },
    options: {
        legend: {
            display: true
        },

        tooltips: {
            enabled: true
        },

        scales: {
            yAxes: [
                {
                    ticks: {
                        fontColor: "#9f9f9f",
                        beginAtZero: false,
                        maxTicksLimit: 8,
                        //padding: 20
                    },
                    gridLines: {
                        drawBorder: false,
                        zeroLineColor: "transparent",
                        color: "rgba(255,255,255,0.05)"
                    }
                }
            ],

            xAxes: [
                {
                    barPercentage: 1.6,
                    gridLines: {
                        drawBorder: false,
                        color: "rgba(255,255,255,0.1)",
                        zeroLineColor: "transparent",
                        display: false
                    },
                    ticks: {
                        padding: 20,
                        fontColor: "#9f9f9f"
                    }
                }
            ]
        }
    }
};

const chartEmployeeScoreThreeMonths = {
    data: {
        labels: [
            prevPrevMonth,
            previousMonth,
            thisMonth,
        ],
        datasets: [
            {
                label: "Boligelektrikeren",
                borderColor: "#fcc468",
                pointRadius: 3,
                pointHoverRadius: 3,
                fill: false,
                borderWidth: 3,
                data: [],
                backgroundColor: "#fcc468",
                hoverBorderColor: "#fcc468",
            },
            {
                label: "Test",
                borderColor: "#fcc468",
                pointRadius: 3,
                pointHoverRadius: 3,
                fill: false,
                borderWidth: 3,
                data: [],
                backgroundColor: "#fcc468",
                hoverBorderColor: "#fcc468",
            },
        ]
    },
    options: {
        legend: {
            display: true
        },

        tooltips: {
            enabled: true
        },

        scales: {
            yAxes: [
                {
                    ticks: {
                        fontColor: "#9f9f9f",
                        beginAtZero: false,
                        maxTicksLimit: 8,
                        //padding: 20
                    },
                    gridLines: {
                        drawBorder: false,
                        zeroLineColor: "transparent",
                        color: "rgba(255,255,255,0.05)"
                    }
                }
            ],

            xAxes: [
                {
                    barPercentage: 1.6,
                    gridLines: {
                        drawBorder: false,
                        color: "rgba(255,255,255,0.1)",
                        zeroLineColor: "transparent",
                        display: false
                    },
                    ticks: {
                        padding: 20,
                        fontColor: "#9f9f9f"
                    }
                }
            ]
        }
    }
};

class CustomerSurvey extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSurveyAnswersReady: false,
            isSurveyAnswersThreeMonthsReady: true,
            isLastWeekReady: false,
            isLastMonthReady: false,
            isLastThreeMonthsReady: false,
            isEmployeeScoreLastThreeMonthsReady: true, //not in use yet - just to make it render
            isTotalScoreListReady: false,
            isTopListReady: false,
            isBottomListReady: false,
            isAllTimesReady: false,

            bottomList: null,
            weekTopList: null,
            lastThreeMonths: null,
            lastWeekCount: null,
            lastMonthCount: null,
            allTimesCount: null,
            surveyResultsArray: [],
            surveyLastYearResultsArray: [],
            selectedPeriod: 1,
            selectedPeriodPrTeam: 1,
        };

        this.selectedPeriodChange = this.selectedPeriodChange.bind(this)
        this.bottonScoreList = this.bottonScoreList.bind(this);
        this.topScoreList = this.topScoreList.bind(this);
        // this.totalScoreList = this.totalScoreList.bind(this);
        

    }

    async componentDidMount() {

        var today = new Date();
        fetch(global.config.server.connection.url + "/api/CustomerSurvey/GetCustomerSurveyAnswers?fromDate=" + new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).toISOString(), {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    this.setState({
                        isSurveyAnswersReady: true,
                        surveyResultsArray: result.map((prop, key) => {
                            return {
                                id: key,
                                question: prop["question"],
                                answer: prop["answer"]
                            }
                        })
                    });
                },
            )

        fetch(global.config.server.connection.url + "/api/CustomerSurvey/LastWeekCount", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLastWeekReady: true,
                        lastWeekCount: result
                    });
                },
            )


        fetch(global.config.server.connection.url + "/api/CustomerSurvey/LastMonthCount", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLastMonthReady: true,
                        lastMonthCount: result
                    });
                },
            )

        fetch(global.config.server.connection.url + "/api/CustomerSurvey/AllTimesCount", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isAllTimesReady: true,
                        allTimesCount: result
                    });
                },
            )

        await fetch(global.config.server.connection.url + "/api/CustomerSurvey/TopList?days=7", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        weekTopList: result,
                        isTopListReady: true,
                    });
                },
            )

        await fetch(global.config.server.connection.url + "/api/CustomerSurvey/BottomList?days=7", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        weekBottomList: result,
                        isBottomListReady: true,
                    });
                },
            )



        

        await fetch(global.config.server.connection.url + "/api/CustomerSurvey/lastThreeMonths", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    var boligelektrikerenTemp = []
                    boligelektrikerenTemp.push(result.filter(customerSurvey => customerSurvey.MonthName === prevPrevMonth).reduce((a, v) => a = a + v.AvgAnswer, 0))
                    boligelektrikerenTemp.push(result.filter(customerSurvey => customerSurvey.MonthName === previousMonth).reduce((a, v) => a = a + v.AvgAnswer, 0))
                    boligelektrikerenTemp.push(result.filter(customerSurvey => customerSurvey.MonthName === thisMonth).reduce((a, v) => a = a + v.AvgAnswer, 0))

                    this.setState({
                        lastThreeMonthsArray: boligelektrikerenTemp,
                        isLastThreeMonthsReady: true,
                        // lastThreeMonths: result.map((prop, key) => {
                        //     return {
                        //         id: key,
                        //         MonthName: prop["MonthName"],
                        //         QuestionId: prop["QuestionId"],
                        //         AvgAnswer: prop["AvgAnswer"]
                        //     }
                        // })
                    });
                },
            )

            //SCORE PR EMPLOYEE - LAST THREE MONTHS
            // await fetch(global.config.server.connection.url + "/api/CustomerSurvey/EmployeeScoreThreeMonths", {
            //     method: "GET",
            //     headers: {
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json',
            //         'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            //     },
            // })
            //     .then(res => res.json())
            //     .then(
            //         (result) => {
            //             console.log(result)
            //             var temp = []
            //             temp.push(result.filter(customerSurvey => customerSurvey.monthName === prevPrevMonth).reduce((a, v) => a = a + v.averageScore, 0))
            //             temp.push(result.filter(customerSurvey => customerSurvey.monthName === previousMonth).reduce((a, v) => a = a + v.averageScore, 0))
            //             temp.push(result.filter(customerSurvey => customerSurvey.monthName === thisMonth).reduce((a, v) => a = a + v.averageScore, 0))
    
            //             this.setState({
            //                 employeeScoreThreeMonthsArray: temp,
            //                 isEmployeeScoreLastThreeMonthsReady: true,
            //                 // employeeScoreLastThreeMonths: result.map((prop, key) => {
            //                 //     return {
            //                 //         id: key,
            //                 //         MonthName: prop["monthName"],
            //                 //         QuestionId: prop["averageScore"],
            //                 //         AvgAnswer: prop["employeeId"]
            //                 //     }
            //                 // })
            //             });
            //         },
            //     ) 

            await fetch(global.config.server.connection.url + "/api/CustomerSurvey/totalScoreList", {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
                },
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            totalScoreList: result,
                            isTotalScoreListReady: true,
                        });
                    },
                )
    }

    selectedPeriodChange = (event) => {
        var today = new Date();
        var anotherDate;

        if (event.target.value === 1) //last week
            anotherDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).toISOString()
        if (event.target.value === 2) //last month
            anotherDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30).toISOString()
        if (event.target.value === 3) //last three months
            anotherDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 90).toISOString()
        if (event.target.value === 4) //all times
            anotherDate = new Date(today.getFullYear() - 10, today.getMonth(), today.getDate()).toISOString()

        console.log(anotherDate);
        fetch(global.config.server.connection.url + "/api/CustomerSurvey/GetCustomerSurveyAnswers?fromDate=" + anotherDate, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isSurveyAnswersReady: true,
                        selectedPeriod: event.target.value,
                        surveyResultsArray: result.map((prop, key) => {
                            return {
                                id: key,
                                question: prop["question"],
                                answer: prop["answer"]
                            }
                        })
                    });
                },
            )
    };

    // selectedPeriodPrTeamChange = (event) => {
    //     var today = new Date();
    //     var anotherDate;
    //     if (event.target.value === 1) //last week
    //         anotherDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).toISOString()
    //     if (event.target.value === 2) //last month
    //         anotherDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30).toISOString()
    //     if (event.target.value === 3) //last three months
    //         anotherDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 90).toISOString()
    //     if (event.target.value === 4) //all times
    //         anotherDate = new Date(today.getFullYear() - 10, today.getMonth(), today.getDate()).toISOString()

    //     fetch(global.config.server.connection.url + "/api/CustomerSurvey/GetCustomerSurveyAnswersPrTeam?fromDate=" + anotherDate, {
    //         method: "GET",
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
    //         },
    //     })
    //         .then(res => res.json())
    //         .then(
    //             (result) => {
    //                 this.setState({
    //                     isSurveyAnswersReady: true,
    //                     selectedPeriodPrTeam: event.target.value,
    //                     surveyResultsArrayPrTeam: result.map((prop, key) => {
    //                         return {
    //                             id: key,
    //                             companyName: prop["companyName"],
    //                             nextProp: prop["cq"]
    //                         }
    //                     })
    //                 });
    //             },
    //         )
    // };

    topScoreList = () => {

        let aa1JobLink = "https://fixel.rokea.no/booking/htdocs/adm/index.php?action=editjob&jobID=";
        let aa1CustomerLink = "https://fixel.rokea.no/booking/htdocs/adm/index.php?action=editcompanypopup&companyId="
        let elektrisJobLink = "https://boligelektrikeren.rokea.no/booking/htdocs/adm/index.php?action=editjob&jobID=";
        let elektrisCustomerLink = "https://boligelektrikeren.rokea.no/booking/htdocs/adm/index.php?action=editcompanypopup&companyId="
                
        const renderList = () => {
            const listItems = [];
            for (let i = 0; i < this.state.weekTopList.length; i++) {
              listItems.push(<li><a style={{color: '78D096'}} target="top" href={this.state.weekTopList[i].companyId !== 2 ? aa1CustomerLink + this.state.weekTopList[i].customerId : elektrisCustomerLink + this.state.weekTopList[i].customerId}>{this.state.weekTopList[i].companyName} </a> tildelte {this.state.weekTopList[i].answerTotal} poeng til <b>{this.state.weekTopList[i].employeeName} </b> for jobb <a target="top" href={this.state.weekTopList[i].companyId !== 2 ? aa1JobLink + this.state.weekTopList[i].jobId : elektrisJobLink + this.state.weekTopList[i].jobId}>{this.state.weekTopList[i].jobId}</a></li>);
            }
            return listItems;
          };

        return <ul>{renderList()}</ul>
    }


    bottonScoreList = () => {

        let aa1JobLink = "https://fixel.rokea.no/booking/htdocs/adm/index.php?action=editjob&jobID=";
        let aa1CustomerLink = "https://fixel.rokea.no/booking/htdocs/adm/index.php?action=editcompanypopup&companyId="
        let elektrisJobLink = "https://boligelektrikeren.rokea.no/booking/htdocs/adm/index.php?action=editjob&jobID=";
        let elektrisCustomerLink = "https://boligelektrikeren.rokea.no/booking/htdocs/adm/index.php?action=editcompanypopup&companyId="
        
        const renderList = () => {
            const listItems = [];
            for (let i = 0; i < this.state.weekBottomList.length; i++) {
              listItems.push(<li><a style={{color: 'red'}} target="top" href={this.state.weekBottomList[i].companyId !== 2 ? aa1CustomerLink + this.state.weekBottomList[i].customerId : elektrisCustomerLink + this.state.weekBottomList[i].customerId}>{this.state.weekBottomList[i].companyName} </a> tildelte {this.state.weekBottomList[i].answerTotal} poeng til <b>{this.state.weekBottomList[i].employeeName} </b> for jobb <a target="top" href={this.state.weekBottomList[i].companyId !== 2 ? aa1JobLink + this.state.weekBottomList[i].jobId : elektrisJobLink + this.state.weekBottomList[i].jobId}>{this.state.weekBottomList[i].jobId}</a></li>);
            }
            return listItems;
          };

        return <ul>{renderList()}</ul>
    }

    // totalScoreList = () => {

    //     const renderList = () => {
    //         const listItems = [];
    //         for (let i = 0; i < this.state.totalScoreList.length; i++) {
    //           listItems.push(<li>Heisann</li>);
    //         }
    //         return listItems;
    //       };

    //     return <ul>{renderList()}</ul>
    // }

    render() {
        const { isLastWeekReady, isLastMonthReady, isSurveyAnswersThreeMonthsReady, isLastThreeMonthsReady, isTopListReady, isBottomListReady, isEmployeeScoreLastThreeMonthsReady } = this.state;

        if (!isSurveyAnswersThreeMonthsReady || !isLastWeekReady || !isLastMonthReady || !isLastThreeMonthsReady || !isTopListReady || !isBottomListReady || !isEmployeeScoreLastThreeMonthsReady) {
            return <Loader />
        }

        //Anbefale
        chartCustomerSurvey.data.datasets[0].data = [this.state.surveyResultsArray[0].answer]
        //Effektivitet
        chartCustomerSurvey.data.datasets[1].data = [this.state.surveyResultsArray[1].answer]
        //Gjenbruk
        chartCustomerSurvey.data.datasets[2].data = [this.state.surveyResultsArray[2].answer]
        //Kvalitet
        chartCustomerSurvey.data.datasets[3].data = [this.state.surveyResultsArray[3].answer]
        //Påliteligheht
        chartCustomerSurvey.data.datasets[4].data = [this.state.surveyResultsArray[4].answer]
        //Pris
        chartCustomerSurvey.data.datasets[5].data = [this.state.surveyResultsArray[5].answer]
        //Totalpris
        chartCustomerSurvey.data.datasets[6].data = [this.state.surveyResultsArray[6].answer]


        chartCustomerSurveyThreeMonths.data.datasets[0].data = [this.state.lastThreeMonthsArray[0], this.state.lastThreeMonthsArray[1], this.state.lastThreeMonthsArray[2]]
        // chartEmployeeScoreThreeMonths.data.datasets[0].data = [this.state.employeeScoreThreeMonthsArray[0], this.state.employeeScoreThreeMonthsArray[1], this.state.employeeScoreThreeMonthsArray[2]]
        // chartEmployeeScoreThreeMonths.data.datasets[1].data = [this.state.employeeScoreThreeMonthsArray[0][1], this.state.employeeScoreThreeMonthsArray[1][1], this.state.employeeScoreThreeMonthsArray[2][1]]

        console.log("The ARRAY: ", this.state.employeeScoreThreeMonthsArray)

        return (
            
            <>
                <div className="content">
                    <Row>
                        <Col md="4">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-chart-bar-32 text-warning" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Siste 7 dager</p>
                                                <CardTitle tag="p">
                                                    {this.state.lastWeekCount}
                                                </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-calendar" />Besvarelser siste 7 dager
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col md="4">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-chart-bar-32 text-warning" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Siste 30 dager</p>
                                                <CardTitle tag="p">
                                                    {this.state.lastMonthCount}
                                                </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-calendar" />Besvarelser siste 30 dager
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-chart-bar-32 text-warning" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Siden oppstart</p>
                                                <CardTitle tag="p">
                                                    {this.state.allTimesCount}
                                                </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-calendar" />Totalt antall besvarelser
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <NewVsOldCustomers />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <Card className="car-chart">
                                <CardHeader>
                                    <Row>
                                        <Col md="8">
                                            <p style={{ fontSize: 16 + 'px' }} className="card-category">Boligelektrikeren - Kundeundersøkelse</p>
                                        </Col>

                                        <Col md="4" style={{ float: "right" }}>
                                            <InputLabel id="demo-simple-select-label" style={{ float: "left" }}>Velg periode:</InputLabel>
                                            <Select style={{ width: 200 + 'px', marginRight: 40 + 'px' }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={this.state.selectedPeriod}
                                                label="Selskap"
                                                onChange={this.selectedPeriodChange}
                                            >
                                                <MenuItem value={1}>Siste 7 dager</MenuItem>
                                                <MenuItem value={2}>Siste måned</MenuItem>
                                                <MenuItem value={3}>Siste tre måneder</MenuItem>
                                                <MenuItem value={4}>Alle </MenuItem>
                                            </Select>
                                        </Col>
                                    </Row>
                                    {/* <p className="card-category">Gjennomstnittlig score pr. selskap.</p> */}

                                </CardHeader>
                                <CardBody>
                                    <Bar
                                        data={chartCustomerSurvey.data}
                                        options={chartCustomerSurvey.options}
                                    />
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="6">
                            <Card className="card-chart">
                                <CardHeader>
                                    <p style={{ fontSize: 16 + 'px' }} className="card-category">Boligelektrikeren - Kundeundersøkelse</p>
                                    <p className="card-category">Gjennomstnittlig score siste <b>tre måneder</b> (10 er best)</p>
                                </CardHeader>
                                <CardBody>
                                    <Line 
                                        data={chartCustomerSurveyThreeMonths.data}
                                        options={chartCustomerSurveyThreeMonths.options}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* <Row>
                    <Col md="12">
                            <Card className="card-chart">
                                <CardHeader>
                                    <p style={{ fontSize: 16 + 'px' }} className="card-category">Boligelektrikeren - Kundeundersøkelse</p>
                                    <p className="card-category">Score pr ansatt siste tre måneder</p>
                                    <br></br>
                                    <InputLabel id="demo-simple-select-label" style={{ float: "left" }}>Velg ansatt:</InputLabel>
                                    <br></br>
                                    <Select style={{ width: 200 + 'px', margiLeft: 40 + 'px' }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.selectedPeriod}
                                        label="Selskap"
                                        onChange={this.selectedPeriodChange}
                                    >
                                        <MenuItem value={1}>Siste 7 dager</MenuItem>
                                        <MenuItem value={2}>Siste måned</MenuItem>
                                        <MenuItem value={3}>Siste tre måneder</MenuItem>
                                        <MenuItem value={4}>Alle </MenuItem>
                                    </Select>
                                </CardHeader>
                                <CardBody>
                                    <Line 
                                        data={chartEmployeeScoreThreeMonths.data}
                                        options={chartEmployeeScoreThreeMonths.options}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col md="6">
                            <Card className="card-chart">
                                <CardHeader>

                                    <Row>
                                        <Col md="9" xs="10">
                                            <p style={{ fontSize: 16 + 'px' }} className="card-category">Fornøyde kunder siste 7 dager</p>
                                            <p className="card-category">Total score på 8 eller høyere </p>
                                        </Col>
                                        <Col md="3" xs="2">
                                            <div className="icon-big text-center icon-success">
                                                <i style={{ fontSize: 40 + "px" }} className="nc-icon nc-satisfied text-success" />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="stats">
                                        <div className="stats">
                                            {this.topScoreList()}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="6">
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col md="9" xs="10">
                                            <p style={{ fontSize: 16 + 'px' }} className="card-category">Misfornøyde kunder siste 7 dager</p>
                                            <p className="card-category">Total score på 5 eller lavere.</p>
                                        </Col>
                                        <Col md="3" xs="2">
                                            <div className="icon-big text-center icon-warning">
                                                <i style={{ fontSize: 40 + "px" }} className="fa fa-thumbs-down text-danger" />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="stats">
                                        <div className="stats">
                                                {this.bottonScoreList()}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>

                    {/* <Row>
                    <Col md="12">
                            <Card className="card-chart">
                                <CardHeader>
                                    <Row>
                                        <Col md="9" xs="10">
                                            <p style={{ fontSize: 16 + 'px' }} className="card-category">Gjennnomsnittlig score pr. montør</p>
                                            <p className="card-category">Snitt score pr. montør for de jobber som er ratet av kunder.</p>
                                        </Col>
                                        <Col md="3" xs="2">
                                            <div className="icon-big text-center icon-warning">
                                                <i style={{ fontSize: 40 + "px" }} className="fa fa-comments text-success" />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="stats">
                                        <div className="stats">
                                                {this.totalScoreList()}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}


                </div>
            </>
        );
    }
}

export default CustomerSurvey;
