import React from "react";
import ReactTable from "react-table";
import Loader from "../components/Loader/Loader";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  FormGroup,
  Input,
} from "reactstrap";

class Campaign extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: null,
      loading: false,
      campaignArray: [],
      activeCampaigns: [],
      error: false,
      modalNotice: false,
      selectedCampaign: [],
      selectedCompany: 4,
      fromDate: new Date(),
      toDate: new Date() + 10,
    };
    this.updateCampaign = this.updateCampaign.bind(this)
    this.fromDateChangedHandler = this.fromDateChangedHandler.bind(this)
    this.toDateChangedHandler = this.toDateChangedHandler.bind(this)
    this.campaignSwitchChanged = this.campaignSwitchChanged.bind(this)
    this.companyChange = this.companyChange.bind(this)
  }


  componentDidMount() {
    this.loadCampaignArrays('4'); //elektris
  }

  loadCampaignArrays(companyID) {

    this.setState({ loading: true });
    fetch(global.config.server.connection.url + "/api/Marketing/GetCampaigns?comanyID=" + companyID, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            loading: false,
            campaignArray: result.map((campaign, key) => {
              return {
                id: key,
                campaignId: campaign["id"],
                name: campaign["name"],
                activeFrom: campaign["activeFrom"].substring(0, 10),
                activeTo: campaign["activeTo"].substring(0, 10),
                averagePrJob: campaign["averagePrJob"],
                jobsCompleted: campaign["jobsCompleted"],
                turnover: campaign["turnover"],
                actions: (
                  <div className="actions-right">
                    {/* button to edit campaing */}
                    <Button
                      onClick={() => {
                        let obj = this.state.campaignArray.find(o => o.id === key);
                        this.toggleModalNotice(obj);
                      }}
                      color="primary"
                      size="md"
                      className="btn-icon btn-link edit"
                    >
                      <i className="fa fa-edit" />
                    </Button>{" "}
                  </div>
                )
              }
            }),
            activeCampaigns: result.filter(campaign => new Date(campaign.activeTo) - new Date() > 0).map((campaign, key) => {
              return {
                id: key,
                campaignId: campaign["id"],
                name: campaign["name"],
                activeFrom: campaign["activeFrom"].substring(0, 10),
                activeTo: campaign["activeTo"].substring(0, 10),
                averagePrJob: campaign["averagePrJob"],
                jobsCompleted: campaign["jobsCompleted"],
                turnover: campaign["turnover"],
                actions: (
                  <div className="actions-right">
                    {/* button to edit campaing */}
                    <Button
                      onClick={() => {
                        let obj = this.state.campaignArray.find(o => o.id === key);
                        this.toggleModalNotice(obj);
                      }}
                      color="primary"
                      size="md"
                      className="btn-icon btn-link edit"
                    >
                      <i className="fa fa-edit" />
                    </Button>{" "}
                  </div>
                )
              }
            }),
          })
        },
        (error) => {
          this.setState({
            loading: false,
            error: true
          });
        }
      )
  }

  fromDateChangedHandler = date => {
    let campaign = this.state.selectedCampaign;

    var theDate = new Date(date);
    date = theDate.getDate();
    var month = theDate.getMonth();
    var year = theDate.getFullYear();
    var dateString = year + "-" + (month + 1) + "-" + date;

    campaign.activeFrom = dateString
    this.setState({
      selectedCampaign: campaign,
    })
  }

  toDateChangedHandler = date => {
    let campaign = this.state.selectedCampaign;

    var theDate = new Date(date);
    date = theDate.getDate();
    var month = theDate.getMonth();
    var year = theDate.getFullYear();
    var dateString = year + "-" + (month + 1) + "-" + date;

    campaign.activeTo = dateString
    this.setState({
      selectedCampaign: campaign,
    })
  }

  campaignSwitchChanged = (event) => {
    if (!event.target.checked) {
      this.setState({
        activeCampaigns: this.state.campaignArray,
      })
    } else {
      this.setState({
        activeCampaigns: this.state.campaignArray.filter(campaign => new Date(campaign.activeTo) - new Date() > 0),
      })
    }
  }

  async updateCampaign() {
    if (this.state.selectedCampaign.campaignId === undefined) //new campagin -> set campaignId = 0 and handle in backend
      this.state.selectedCampaign.campaignId = 0

    try {
      var response = await fetch(global.config.server.connection.url + "/api/Marketing/updateCampaign", {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
        },
        body: JSON.stringify({ id: this.state.selectedCampaign.campaignId, name: this.state.selectedCampaign.name, activeFrom: this.state.selectedCampaign.activeFrom, activeTo: this.state.selectedCampaign.activeTo, cityID: this.state.selectedCompany })
      })
    }
    catch (ex) {
      this.errorAlert("AUCH - her skjedde det noe galt: " + ex.message)
      return;
    }

    if (response.ok) {
      this.setState({
        modalNotice: !this.state.modalNotice,
      });
      this.successAlert("Alt OK!")
      return;
    }

    if (!response.ok) {
      this.setState({
        modalNotice: !this.state.modalNotice,
      });
      this.errorAlert("AUCH - her skjedde det noe galt.")
      return;
    }
  }

  companyChange = (event) => {
    this.loadCampaignArrays(event.target.value);
    this.setState({
      selectedCompany: event.target.value
    });
  };

  nameChange = (event) => {
    let campaign = this.state.selectedCampaign;
    switch (event.target.id) {
      case 'name':
        {
          campaign.name = event.target.value
          this.setState({
            selectedCampaign: campaign
          });
          return
        }
      default:
        return
    }
  };

  successAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={message}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          Du må kanskje oppdatere for å se endringene.
        </ReactBSAlert>
      )
    });
  };

  errorAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="OPS!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {message}
        </ReactBSAlert>
      )
    });
  };

  confirmAlert = (message, obj) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="Delete campaign?"
          onConfirm={() => this.onConfirmDelete(obj)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="info"
        >
          {message}
          <br></br><br></br>
          <span style={{ fontSize: '.9em', color: '#EB6532' }}><i>If the campaign has an associated <b> warehouse</b>, the warehouse will be made available to another campaign</i></span>
        </ReactBSAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  toggleModalNotice = (obj) => {
    console.log(obj)
    this.setState({
      modalNotice: !this.state.modalNotice,
      selectedCampaign: obj
    });
  };

  toCurrency(numberString) {
    let number = parseFloat(numberString);
    return number.toLocaleString('no-NO');
  }

  render() {

    const { error, loading, activeCampaigns } = this.state;


    if (loading) {
      return <Loader />
    }

    if (error) {
      return (
        <div className="content">
          <h2>Ops - Error</h2>
        </div>
      )
    }

    return (
      <>
        {this.state.alert}

        <div className="content">

          <Modal
            isOpen={this.state.modalNotice}
            toggle={this.toggleModalNotice}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.toggleModalNotice}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <h5 className="modal-title" id="myModalLabel">
                Administrere kampanje
              </h5>
            </div>
            <div className="modal-body">
              <div className="instruction">
                <Row>
                  <Col md="12">
                    <strong style={{ marginRight: 100 + 'px' }}> Selskap: </strong>
                    <FormGroup style={{ marginTop: 10 + 'px' }}>
                      <Select style={{ width: 200 + 'px', marginRight: 40 + 'px' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.selectedCompany}
                        label="Selskap"
                        variant="outlined"
                        onChange={this.companyChange}
                      >
                        <MenuItem value={4}>Elektris</MenuItem>
                        <MenuItem value={6}>Elfiksern</MenuItem>
                        <MenuItem value={3}>Elmesteren</MenuItem>
                        <MenuItem value={2}>Fixel</MenuItem>
                      </Select>
                    </FormGroup>

                    <strong>Navn på kampanje: </strong>
                    <FormGroup style={{ marginTop: 10 + 'px' }}>
                      <Input id="name" style={{ color: 'black', size: 14 + 'px' }} defaultValue={this.state.selectedCampaign.name} type="text" onChange={this.nameChange} />
                    </FormGroup>

                    <strong>Detaljer: </strong>
                    <FormGroup style={{ marginTop: 10 + 'px' }}>
                      <textarea id="details" style={{ color: 'black', size: 14 + 'px' }} cols="40" rows="3"></textarea>
                    </FormGroup>


                    <strong>Aktiv fra: </strong>
                    <br></br>
                    <form noValidate>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ float: 'left' }} id="activeFrom">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          autoOk={true}
                          value={this.state.selectedCampaign.activeFrom}
                          onChange={this.fromDateChangedHandler}
                        />
                      </MuiPickersUtilsProvider>
                    </form>
                    <br></br>
                    <strong>Aktiv til: </strong>
                    <br></br>
                    <form noValidate>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ float: 'left' }}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          autoOk={true}
                          id="activeTo"
                          value={this.state.selectedCampaign.activeTo}
                          onChange={this.toDateChangedHandler}
                        />
                      </MuiPickersUtilsProvider>
                    </form>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <Button
                onClick={() => {
                  this.updateCampaign();
                }}
                color="primary"
                size="md"
                className="btn-round"
              >
                <i className="fa fa-check" /> Lagre kampanje
              </Button>{" "}


            </div>
          </Modal>

          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Kampanjer</CardTitle>

                  <InputLabel id="demo-simple-select-label">Velg selskap</InputLabel>
                  <Select style={{ width: 200 + 'px', marginRight: 40 + 'px' }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.selectedCompany}
                    label="Selskap"
                    onChange={this.companyChange}
                  >
                    <MenuItem value={4}>Elektris</MenuItem>
                    <MenuItem value={6}>Elfiksern</MenuItem>
                    <MenuItem value={3}>Elmesteren</MenuItem>
                    <MenuItem value={2}>Fixel</MenuItem>
                  </Select>

                  <FormControlLabel label="Vis bare aktive kampanjer" labelPlacement="start" control={
                    <Switch defaultChecked color="primary" size="small" onChange={this.campaignSwitchChanged} />
                  } />

                  <Button style={{ width: 200 + 'px', float: 'right' }} color="success" onClick={this.toggleModalNotice}>Ny kampanje</Button>{' '}
                </CardHeader>

                <CardBody>
                  <ReactTable
                    data={activeCampaigns}
                    filterable
                    columns={[
                      {
                        Header: "Kampanjenavn",
                        accessor: "name",
                        filterable: false,
                      },
                      {
                        Header: "Aktiv fra",
                        accessor: "activeFrom",
                        filterable: false,
                        width: 120,
                      },
                      {
                        Header: "Aktiv til",
                        accessor: "activeTo",
                        filterable: false,
                        width: 120,
                      },
                      {
                        Header: "Jobber",
                        accessor: "jobsCompleted",
                        filterable: false,
                        width: 120,
                      },
                      {
                        Header: "Omsetning",
                        accessor: "turnover",
                        filterable: false,
                        Cell: props => <div>{this.toCurrency(props.value)} </div>,
                        width: 120,
                      },
                      {
                        Header: "Snitt",
                        accessor: "averagePrJob",
                        filterable: false,
                        Cell: props => <div>{this.toCurrency(props.value)} </div>,
                        width: 120,
                      },
                      {
                        Header: "",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        width: 80,
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom
                    /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
                    className="-striped -highlight none-pagination"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Campaign;
