import React from "react";
import Loader from "components/Loader/Loader";
import GoogleApiWrapper from "views/maps/GoogleHeatMap.jsx";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { Row } from "reactstrap";

class GoogleMaps extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      originalCoordinates: [],
      coordinates: [],
      loading: "",
      selectedDate: new Date(),
      selectedMontor: 'Alle',
      selectedTeam: 'Alle',
      startDate: '',
    };

    this.selectedDateChanged = this.selectedDateChanged.bind(this)
    this.selectedMontorChanged = this.selectedMontorChanged.bind(this)
    this.selectedTeamChanged = this.selectedTeamChanged.bind(this)
    this.loadCoordinates = this.loadCoordinates.bind(this)
  }


  componentDidMount() {
    this.loadCoordinates(new Date());
  }

  loadCoordinates(date) {

    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();

    var companyID = 0
    if (this.props.location.pathname.includes("heatmapAll"))
      companyID = 4
    if (this.props.location.pathname.includes("team1"))
      companyID = 3
    if (this.props.location.pathname.includes("team2"))
      companyID = 2
    if (this.props.location.pathname.includes("team3"))
      companyID = 6

    this.setState({
      loading: true,
    });

    fetch(global.config.server.connection.url + "/GoogleHeatMapData?companyID=" + companyID + "&date='" + yyyy + "-" + mm + "-" + dd + "'") //companyID is deprecated
      .then((response) => response.json())
      .then(
        (result) => {
          //create unique array of Montører
          var array = [];
          for (let i = 0; i < result.length; i++) {
            let lat = { lat: result[i].lat };
            let name = { name: result[i].employeeUserName };
            let person = Object.assign(lat, name);
            array.push(person)
          }
          var uniqueEmployees = array.reduce((unique, o) => {
            if (!unique.some(obj => obj.name === o.name)) {
              unique.push(o);
            }
            return unique;
          }, []);

          this.setState({
            selectedDate: date,
            originalCoordinates: result,
            coordinates: result,
            loading: false,
            selectedMontor: 'Alle',
            employeeArray: uniqueEmployees.map(employee => (
              <MenuItem key={Math.random()} value={employee.name}>{employee.name}</MenuItem>
            )),
          });
        });

  }

  selectedDateChanged = date => {
    this.loadCoordinates(date)
  }

  selectedTeamChanged({ target }) {
    if (target.value === 'Alle') {

      let selectedEmployee = this.state.originalCoordinates

      let array = [];
      for (let i = 0; i < selectedEmployee.length; i++) {
        let name = { name: selectedEmployee[i].employeeUserName };
        let person = Object.assign(name);
        array.push(person)
      }
      let uniqueEmployees = array.reduce((unique, o) => {
        if (!unique.some(obj => obj.name === o.name)) {
          unique.push(o);
        }
        return unique;
      }, []);

      this.setState({
        coordinates: this.state.originalCoordinates,
        selectedTeam: target.value,
        selectedMontor: 'Alle',
        employeeArray: uniqueEmployees.map(employee => (
          <MenuItem key={Math.random()} value={employee.name}>{employee.name}</MenuItem>
        )),
      });
    }
    else {
      let selectedEmployee = this.state.originalCoordinates.filter(obj => {
        return obj.team === parseInt(target.value)
      })

      let array = [];
      for (let i = 0; i < selectedEmployee.length; i++) {
        let name = { name: selectedEmployee[i].employeeUserName };
        let person = Object.assign(name);
        array.push(person)
      }
      let uniqueEmployees = array.reduce((unique, o) => {
        if (!unique.some(obj => obj.name === o.name)) {
          unique.push(o);
        }
        return unique;
      }, []);

      this.setState({
        coordinates: selectedEmployee,
        selectedTeam: target.value,
        selectedMontor: 'Alle',
        employeeArray: uniqueEmployees.map(employee => (
          <MenuItem key={Math.random()} value={employee.name}>{employee.name}</MenuItem>
        )),
      });
    }
  }

  selectedMontorChanged({ target }) {
    if (target.value === 'Alle') {
      this.setState({
        coordinates: this.state.originalCoordinates,
        selectedMontor: target.value
      });
    }
    else {
      var selectedEmployee = this.state.originalCoordinates.filter(obj => {
        return obj.employeeUserName === target.value
      })

      this.setState({
        coordinates: selectedEmployee,
        selectedMontor: target.value
      });
    }
  }

  render = () => {

    const { loading, coordinates } = this.state;

    if (loading) {
      return (
        <div className="content">
          <Loader />
        </div>
      )
    }

    return (
      <div className="content">

        <Row>
          <p style={{ paddingRight: 10 + 'px' }}>Velg team:</p>
          <Select labelId="label"
            id="select"
            style={{ paddingLeft: 10 + 'px', width: 170 + 'px' }}
            value={this.state.selectedTeam}
            onChange={this.selectedTeamChanged}>
            <MenuItem value='Alle'>Alle</MenuItem>
            <MenuItem value='1'>Team 1</MenuItem>
            <MenuItem value='2'>Team 2</MenuItem>
            <MenuItem value='3'>Team 3</MenuItem>

          </Select>

          <p style={{ paddingRight: 10 + 'px' }}>Velg montør:</p>
          <Select labelId="label"
            id="select"
            style={{ paddingLeft: 10 + 'px', width: 170 + 'px' }}
            value={this.state.selectedMontor}
            onChange={this.selectedMontorChanged}>
            <MenuItem value='Alle'>Alle</MenuItem>
            {this.state.employeeArray}
          </Select>

          <p style={{ paddingLeft: 30 + 'px', paddingRight: 10 + 'px' }}>Velg dato:</p>
          <form noValidate>
            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ float: 'left' }}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                autoOk={true}
                id="date-picker-inline"
                value={this.state.selectedDate}
                onChange={this.selectedDateChanged}
              />
            </MuiPickersUtilsProvider>
          </form>
        </Row>

        <GoogleApiWrapper apidata={coordinates} />
      </div>
    )
  }
}

export default GoogleMaps;
