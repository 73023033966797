


export function testFunction(props) {
    //function stuff
    
    console.log('in testFunction')
    console.log(props)
    
     //Type Booking
     if (props.jobFromSource === '') {
        // isError = true;
        this.setState(prev => ({
            ...prev,
            error: true,
            errorMessage: { ...prev.errorMessage, jobFromSourceError: "Du må velge type booking" }
        }))
    }

    let i = 1;
    return i;

 }  