import React from "react";
import ReactTable from "react-table";
import Loader from "../components/Loader/Loader";
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Modal,
} from "reactstrap";


class WarehouseAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            loading: false,
            employeesNoWarehouseArray: [],
            warehouseArray: [],
            warehouseProductsArray: [],
            warehouseSelect: [],
            assignedEmployeeId: "",
            warehouseId: "",
            oldWarehouseId: "",
            newWarehouseId: "",
            error: false,
            modalNotice: false,
            modalEdit: false,
            selectedWarehouse: [],
            selectedRow: null,
            expanded: {},
        };
        this.transferWarehouseGoods = this.transferWarehouseGoods.bind(this);
        this.setWarehouseOwner = this.setWarehouseOwner.bind(this);
    }


    componentDidMount() {
        this.setState({ loading: true });


        //Load employeesNoWarehouseArray
        fetch(global.config.server.connection.url + "/api/Warehouse/EmployeesNoWarehouse", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    let availableEmployeesFromAPI = result.map(employee => { return { value: employee.id, label: employee.company + " - " + employee.firstName + " " + employee.lastName } })
                    availableEmployeesFromAPI.unshift({ value: "NULL", label: "No Owner" });
                    this.setState({
                        employeesNoWarehouseArray: availableEmployeesFromAPI
                    });
                },
            )

        //Load warehouseArray
        fetch(global.config.server.connection.url + "/api/Warehouse/GetWarehouses", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    let warehousesFromApi = result.map(warehouse => { return { value: warehouse.warehouseId, label: warehouse.warehouseId + " - " + warehouse.firstName + " " + warehouse.lastName } })

                    this.setState({
                        warehouseSelect: warehousesFromApi,
                        loading: false,
                        warehouseArray: result.map((prop, key) => {
                            return {
                                id: key,
                                firstName: prop["firstName"],
                                lastName: prop["lastName"],
                                company: prop["company"],
                                warehouseId: prop["warehouseId"],
                                productCount: prop["productCount"],
                                companyName: prop["companyName"],
                                actions: (
                                    <div className="actions-right">
                                        {/* button to edit warehouse */}
                                        <Button
                                            onClick={() => {
                                                let obj = this.state.warehouseArray.find(o => o.id === key);
                                                this.toggleModalEdit(obj);
                                            }}
                                            color="success"
                                            size="md"
                                            className="btn-icon btn-link edit"
                                        >
                                            <i className="fa fa-edit" />
                                        </Button>
                                        {/* button to transfer warehouse goods */}
                                        <Button
                                            onClick={() => {
                                                let obj = this.state.warehouseArray.find(o => o.id === key);
                                                this.toggleModalNotice(obj);
                                            }}
                                            color="warning"
                                            size="md"
                                            className="btn-icon btn-link edit"
                                        >
                                            <i className="fa fa-arrow-right" />
                                        </Button>
                                        {/* button to remove the data row and delete warehouse from DB */}
                                        <Button
                                            onClick={() => {
                                                var data = this.state.warehouseArray;
                                                data.find((o, i) => {
                                                    if (o.id === key) {
                                                        if (o.productCount > 0) {
                                                            this.errorAlert("Du kan ikke slette et lager med produkter. Du må først overføre produktene til et annet lager.")
                                                            return false;
                                                        }
                                                        else {
                                                            //data.splice(i, 1);
                                                            this.setState({
                                                                rowToBeDeleted: i
                                                            });
                                                            this.deleteWarehouse()
                                                            return true;
                                                        }
                                                    }
                                                    return false;
                                                });
                                                this.setState({ warehouseArray: data });
                                            }}
                                            color="danger"
                                            size="md"
                                            className="btn-icon btn-link remove"
                                        >
                                            <i className="fa fa-times" />
                                        </Button>{" "}
                                    </div>
                                )
                            }
                        })
                    });
                },
                (error) => {
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            )
    }

    successAlert = (message) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title={message}
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    Have a great day.
                </ReactBSAlert>
            )
        });
    };

    errorAlert = (message) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="OPS!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    {message}
                </ReactBSAlert>
            )
        });
    };

    confirmAlert = (message) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    showCancel
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Delete warehouse?"
                    onConfirm={() => this.onConfirmDelete()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="info"
                >
                    {message}
                </ReactBSAlert>
            )
        });
    };

    deleteWarehouse() {
        this.confirmAlert("Er du sikker på at du vil slette dette lageret?")
    }

    async onConfirmDelete() {

        var data = this.state.warehouseArray;
        data.splice(this.state.rowToBeDeleted, 1);
        this.setState({ warehouseArray: data });

        try {
            var response = await fetch(global.config.server.connection.url + "/api/Warehouse/DeleteWarehouse?warehouseId=" + this.state.warehouseId, {
                method: "DELETE",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
                },
            })
        } catch (ex) {
            this.errorAlert("Delete warehouse -> In catch exception")
            return;
        }

        if (response.ok) {
            this.successAlert("Lager er slettet")
            return;
        }

        if (!response.ok) {
            console.log(response)
            this.errorAlert("OPS - her skjedde det noe galt. Kontakt IT.")
            return;
        }

        this.setState({
            alert: null,
        });
    };


    async setWarehouseOwner() {

        this.setState({ loading: true });

        console.log(JSON.stringify({ userId: this.state.assignedEmployeeId, warehouseId: this.state.warehouseId }))

        try {
            var response = await fetch(global.config.server.connection.url + "/api/Warehouse/SetWarehouseForUser?userId=" + this.state.assignedEmployeeId + "&warehouseId=" + this.state.warehouseId, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
                },
            })
        } catch (ex) {
            this.setState({ loading: false });
            this.errorAlert("OPS - her skjedde det noe galt. Kontakt IT." + ex.message)
            return;
        }

        if (response.ok) {
            this.setState({
                loading: false,
                assignedEmployeeId: "",
                warehouseId: "",
                modalEdit: !this.state.modalEdit,
            });
            this.successAlert("Lager ble oppdatert")
            //console.log(this.state.warehouseArray)
            window.location.reload(); //HER MÅ DET GJØRES NOE FOR Å SLIPPE REFRESH AV SIDEN. MÅ OPPDATERE STATE.
            return;
        }

        if (!response.ok) {
            console.log(response)
            this.setState({
                loading: false,
                modalEdit: !this.state.modalEdit,
            });
            this.errorAlert("OPS - her skjedde det noe galt. Kontakt IT." + response)
            return;
        }

    }

    async transferWarehouseGoods() {

        console.log(JSON.stringify({ OldWarehouseId: this.state.oldWarehouseId, NewWarehouseId: this.state.newWarehouseId }))

        try {
            var response = await fetch(global.config.server.connection.url + "/api/Warehouse/TransferWarehouseGoods?oldWarehouseId=" + this.state.oldWarehouseId + "&newWarehouseId=" + this.state.newWarehouseId, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
                },
            })
        } catch (ex) {
            this.errorAlert("OPS - her skjedde det noe galt. Kontakt IT.")
            return;
        }

        if (response.ok) {
            this.setState({
                oldWarehouseId: "",
                newWarehouseId: "",
                modalNotice: !this.state.modalNotice,
            });
            this.successAlert("Alle produkter ble overført.")
            window.location.reload();
            return;
        }

        if (!response.ok) {
            console.log(response)
            this.setState({
                modalNotice: !this.state.modalNotice,
            });
            this.errorAlert("OPS - her skjedde det noe galt. Kontakt IT.")

            return;
        }
    }


    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    toggleModalNotice = (obj) => {
        this.setState({
            oldWarehouseId: obj.warehouseId,
            modalNotice: !this.state.modalNotice,
            selectedWarehouse: obj
        });
    };

    toggleModalEdit = (obj) => {
        this.setState({
            oldWarehouseId: obj.warehouseId,
            modalEdit: !this.state.modalEdit,
            selectedWarehouse: obj
        });

    };

    onSelectedWarehouseChange = (obj) => {
        this.setState({
            newWarehouseId: obj.value,
        });
    }

    onSelectedEmployeeChange = (obj) => {
        this.setState({
            assignedEmployeeId: obj.value,
        });
    }

    render() {

        const { error, loading } = this.state;


        if (loading) {
            return <Loader />
        }

        if (error) {
            return (
                <div className="content">
                    <h2>Ops - Error</h2>
                </div>
            )
        }

        return (
            <>
                {this.state.alert}

                <div className="content">

                    <Modal
                        isOpen={this.state.modalNotice}
                        toggle={this.toggleModalNotice}
                    >
                        <div className="modal-header">
                            <button
                                aria-hidden={true}
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={this.toggleModalNotice}
                            >
                                <i className="nc-icon nc-simple-remove" />
                            </button>
                            <h5 className="modal-title" id="myModalLabel">
                                Overføre produkter til annet lager
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="instruction">
                                <Row>
                                    <Col md="12">
                                        <h6>Warehouse Id: </h6> {this.state.selectedWarehouse.warehouseId} <br></br><br></br>
                                        <h6>Owner: </h6> {this.state.selectedWarehouse.firstName} {this.state.selectedWarehouse.lastName} <br></br><br></br>
                                        <h6>Company: </h6> {this.state.selectedWarehouse.companyName} <br></br><br></br>

                                        <h6>Overføre produkter til annet lager: </h6>

                                        <Select
                                            name="warehouse"
                                            className="react-select"
                                            placeholder="Select Warehouse"
                                            classNamePrefix="react-select"
                                            onChange={this.onSelectedWarehouseChange}
                                            options={this.state.warehouseSelect}
                                        />

                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <Button
                                className="btn-round"
                                color="info"
                                data-dismiss="modal"
                                type="button"
                                onClick={this.transferWarehouseGoods}
                            >
                                Let's do it
                            </Button>
                        </div>

                    </Modal>


                    <Modal
                        isOpen={this.state.modalEdit}
                        toggle={this.toggleModalEdit}
                    >
                        <div className="modal-header">
                            <button
                                aria-hidden={true}
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={this.toggleModalEdit}
                            >
                                <i className="nc-icon nc-simple-remove" />
                            </button>
                            <h5 className="modal-title" id="myModalLabel">
                                Velg eier av lager
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="instruction">
                                <Row>
                                    <Col md="12">
                                        <h6>Lager ID: </h6> {this.state.selectedWarehouse.warehouseId} <br></br><br></br>

                                        <h6>Velg eier av lager: </h6>

                                        <Select
                                            name="warehouse"
                                            className="react-select"
                                            placeholder="Velg eier"
                                            classNamePrefix="react-select"
                                            onChange={this.onSelectedEmployeeChange}
                                            options={this.state.employeesNoWarehouseArray}
                                        />

                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <br></br>
                                        <p>Bare brukere <b>som ikke allerede er tilknyttet</b> til et lager, og med <b>"gammel bruker ID" satt,</b> er tilgjengelige i listen. </p>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <Button
                                className="btn-round"
                                color="success"
                                data-dismiss="modal"
                                type="button"
                                onClick={this.setWarehouseOwner}
                            >
                                Jeg er klar
                            </Button>
                        </div>

                    </Modal>



                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Oversikt lager</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        expanded={this.state.expanded}
                                        data={this.state.warehouseArray}
                                        filterable
                                        defaultPageSize={20}
                                        showPaginationTop
                                        showPaginationBottom
                                        className="-striped -highlight info-pagination"
                                        columns={[
                                            {
                                                Header: "Lager ID",
                                                accessor: "warehouseId",
                                                filterable: false
                                            },
                                            {
                                                Header: "Antall produkter",
                                                accessor: "productCount",
                                                filterable: false
                                            },
                                            {
                                                Header: "Fornavn",
                                                accessor: "firstName",
                                                filterable: false
                                            },
                                            {
                                                Header: "Etternavn",
                                                accessor: "lastName",
                                                filterable: false
                                            },
                                            {
                                                Header: "Selskap",
                                                accessor: "companyName",
                                                filterable: false
                                            },
                                            {
                                                Header: "Valg",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false
                                            }
                                        ]}

                                        getTrProps={(state, rowInfo, column, instance, expanded) => {
                                            return {
                                                onClick: e => {
                                                    if (this.state.rowexpanded === rowInfo.viewIndex) {
                                                        //console.log('CLICKED ROW ALT 1')
                                                        //console.log(rowInfo.row.warehouseId)
                                                        this.setState({ warehouseId: rowInfo.row.warehouseId });
                                                        this.setState({ expanded: { [rowInfo.viewIndex]: false } });
                                                        this.setState({ rowexpanded: null });
                                                        this.setState({ inventoryDetailLevelArray: [] });
                                                    }
                                                    else {
                                                        //console.log('CLICKED ROW ALT 2')
                                                        //console.log(rowInfo.row.warehouseId)
                                                        this.setState({ warehouseId: rowInfo.row.warehouseId });
                                                        this.setState({ expanded: { [rowInfo.viewIndex]: true } });
                                                        this.setState({ rowexpanded: rowInfo.viewIndex });
                                                    }

                                                }
                                            };
                                        }}

                                        SubComponent={row => {
                                            fetch(global.config.server.connection.url + "/api/Warehouse/GetWarehouseProducts?warehouseId=" + this.state.warehouseId, {
                                                method: "GET",
                                                headers: {
                                                    'Accept': 'application/json',
                                                    'Content-Type': 'application/json',
                                                    'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
                                                },
                                            })
                                                .then(res => res.json())
                                                .then(
                                                    (result) => {
                                                        this.setState({
                                                            loading: false,
                                                            warehouseProductsArray: result.map((prop, key) => {
                                                                return {
                                                                    id: key,
                                                                    col1: prop["productId"],
                                                                    col2: prop["quantity"],
                                                                    col3: prop["description"]
                                                                }
                                                            })
                                                        })
                                                    })

                                            return (
                                                <div style={{ padding: "20px" }}>
                                                    <ReactTable
                                                        minRows={1}
                                                        data={this.state.warehouseProductsArray}
                                                        columns={[
                                                            {
                                                                Header: "Product ID",
                                                                accessor: "col1",
                                                                filterable: false,
                                                                width: 150,
                                                            },
                                                            {
                                                                Header: "Count",
                                                                accessor: "col2",
                                                                filterable: false,
                                                                width: 150,
                                                            },
                                                            {
                                                                Header: "Description",
                                                                accessor: "col3",
                                                                filterable: false,
                                                                headerStyle: { textAlign: 'left' }
                                                            }
                                                        ]}
                                                        showPagination={false}
                                                    />
                                                </div>
                                            );
                                        }}

                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default WarehouseAdmin;
