import React from "react";
import classnames from "classnames";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// reactstrap components
import { FormGroup, Row, Col } from "reactstrap";
import Loader from "components/Loader/Loader";



const userRights = [{ value: "Employee", label: "Default User Rights" }, { value: "Admin", label: "Administrator User Rights" }]

class Wizard extends React.Component {
  constructor(props) {
    super(props);

    this.onSelectedCompanyChange = this.onSelectedCompanyChange.bind(this)
    this.onSelectedDepartmentChange = this.onSelectedDepartmentChange.bind(this)
    this.onSelectedUserRightsChange = this.onSelectedUserRightsChange.bind(this)

    this.state = {
      loading: false,
      companyState: '',
      departmentState: '',
      company: "Assign to company",
      companyID: "",
      department: "Assign to department",
      departmentID: "",
      companies: [],
      departments: [],
      userRight: "Default User Rights",
      userRightValue: 'Employee'
    };
  }


  componentDidMount() {

    this.setState({ loading: true });

    fetch(global.config.server.connection.url + "/api/Company?groupID=1", {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      },
    })
      .then(res => res.json())
      .then(data => {
        let companiesFromApi = data.map(team => { return { value: team.companyId, label: team.companyName } })
        this.setState({
          companies: companiesFromApi,
          loading: false
        });
      },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            loading: false,
            error
          });
          console.log(error)
        }
      )
  }

  onSelectedCompanyChange(companySelected) {
    this.setState({
      loading: "true",
      company: companySelected.label,
      companyID: companySelected.value,
      companyState: "has-success",
      department: "Assign to department",
      departments: []
    });

    fetch(global.config.server.connection.url + "/api/Department?companyID=" + companySelected.value, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      },
    })
      .then(res => res.json())
      .then(data => {
        let departmentsFromApi = data.map(team => { return { value: team.departmentId, label: team.departmentName } })
        this.setState({
          departments: departmentsFromApi,
          loading: false
        });
      },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            loading: false,
            error
          });
          console.log(error)
        }
      )
  }

  onSelectedDepartmentChange(departmentSelected) {
    this.setState({
      department: departmentSelected.label,
      departmentID: departmentSelected.value,
      departmentState: "has-success",
    });
  }

  onSelectedUserRightsChange(userRight) {
    this.setState({
      userRight: userRight.label,
      userRightValue: userRight.value
    });
  }

  isValidated = () => {
    if (
      this.state.companyState === "has-success" &&
      this.state.departmentState === "has-success"
    ) {
      return true;
    } else {
      if (this.state.companyState !== "has-success") {
        this.setState({ companyState: "has-danger" });
      }
      if (this.state.departmentState !== "has-success") {
        this.setState({ departmentState: "has-danger" });
      }
      return false;
    }
  };

  render() {

    const { companies, departments, loading, company, department, userRight } = this.state;

    if (loading) {
      return <Loader />
    }

    return (
      <>
        <h5 className="info-text">Assign to company and department and set access level</h5>

        <Row className="justify-content-center">
          <Col lg="6">
            <FormGroup
              className={classnames(this.state.companyState, {
                "input-group-focus": this.state.companyFocus
              })}
            >
              <Select
                name="company"
                className="react-select"
                placeholder={company}
                classNamePrefix="react-select"
                value={company}
                onChange={this.onSelectedCompanyChange}
                options={companies}
              />
              {this.state.companyState === "has-danger" ? (
                <label className="error">Please select a company</label>
              ) : null}

            </FormGroup>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col lg="6">
            <FormGroup
              className={classnames(this.state.departmentState, {
                "input-group-focus": this.state.departmentFocus
              })}
            >
              <Select
                name="department"
                className="react-select"
                placeholder={department}
                classNamePrefix="react-select"
                value={department}
                onChange={this.onSelectedDepartmentChange}
                options={departments}
              />
              {this.state.departmentState === "has-danger" ? (
                <label className="error">Please select a department</label>
              ) : null}
            </FormGroup>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col lg="6">
            <FormGroup>
              <Select
                name="department"
                className="react-select"
                placeholder={userRight}
                classNamePrefix="react-select"
                value={userRight}
                onChange={this.onSelectedUserRightsChange}
                options={userRights}
              />
            </FormGroup>
          </Col>
        </Row>

      </>
    );
  }
}

export default Wizard;
