/**
 * Popup Component
 */
import React from 'react';
import ReactTable from "react-table";
import Loader from "components/Loader/Loader";
import './Popup.scss';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormLabel from '@material-ui/core/FormLabel';
import ReactBSAlert from "react-bootstrap-sweetalert";
import DateFnsUtils from '@date-io/date-fns';
import { Modal } from "reactstrap";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
} from '@material-ui/pickers';
import { FormHelperText } from '@material-ui/core';
import SjekklisteStandardarbeid from './Components/SjekklisteStandardarbeid'
import { testFunction } from './Components/ValidateJobData.js'



class Popup extends React.Component {

    /**
     * Constructor (initializes state and binds handlers)
     * @param {Object} props
     */
    constructor(props) {
        super();
        // create state with defaults overridden by eventRecord data
        this.state = {
            loading: false,
            alternativeAddressSelected: '',
            internalTime: false,
            name: '',
            jobDescription: '',
            customerId: '',
            customerName: '',
            contactName: '',
            companyType: '',
            companyInvoiceAdress: '',
            companyInvoiceCity: '',
            companyInvoicePostal: '',
            companyContactName: '',
            companyInvoiceContactName: '',
            companyName: '',
            companyOrgNr: '',
            campaignID: '',
            jobAdress: '',
            jobPostal: '',
            jobCity: '',
            jobFromSource: '',
            jobFromSourceSelected: '',
            eventType: '',
            jobType: '',
            infratekRapportNr: '',
            maalerNr: '',
            paymentType: '',
            genderType: '',
            ageType: '',
            jobContactName: '',
            searchFieldValue: '',
            buildYear: '',
            oppussingdate: '',
            oppussingjob: '',
            customerEmail: '',
            notifyNewCustomer: false,
            notifyBlacklistedCustomer: false,
            contactMobileNumber: '',
            alternativeJobContactMobileNumber: '',
            alternativeJobContactName: '',
            statusSikring: '',
            receiveSMS: false,
            internalTime: false,
            infratekAndRapportNrFieldsDisabled: true,
            isNewCustomer: false,
            customerArray: [],
            chkListVisible: 'block',
            bookingVisible: 'none',
            validJobData: '',
            marketingSource: '',
            marketingSourceArray: [],
            marketingSourceItems: [],
            standardarbeidRadioValue: '',
            standardarbeidSource: '',
            standardarbeidOKArray: [],
            standardarbeidNotOKArray: [],
            standardarbeidInternalArray: [],
            jobSourceArray: [],
            alert: null,
            modalNotice: false,
            error: false,
            errorMessage: {},
            ...props.eventRecord.data
        };

        // shortcuts to handlers
        this.dataChangedHandler = this.dataChangedHandler.bind(this);
        this.customerChangedHandler = this.customerChangedHandler.bind(this);
        this.saveClickHandlerAsync = this.saveClickHandlerAsync.bind(this);
        this.startDateChangeHandler = this.startDateChangeHandler.bind(this);
        this.endDateChangeHandler = this.endDateChangeHandler.bind(this);
        this.smsAndEmailCheckedChangedHandler = this.smsAndEmailCheckedChangedHandler.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.getMarketingSources = this.getMarketingSources.bind(this);
        this.getStandardarbeidSources = this.getStandardarbeidSources.bind(this);
        this.nextClickHandler = this.nextClickHandler.bind(this);
        this.standardarbeidRadioChange = this.standardarbeidRadioChange.bind(this);
        this.validateJobData = this.validateJobData.bind(this);
        this.jobFromSourceChangedHandler = this.jobFromSourceChangedHandler.bind(this);
        this.getJobTypes = this.getJobTypes.bind(this);
        this.jobFromSourceSelectedClick = this.jobFromSourceSelectedClick.bind(this);
        this.alternativeAddressClick = this.alternativeAddressClick.bind(this);
        this.alternativeAddressChanged = this.alternativeAddressChanged.bind(this);

    }

    componentDidMount() {
        //console.log(this.state)
        // console.log("THE PROPS ==>>")
        // console.log(this.props.eventRecord.data)

        if (this.props.eventRecord.data.jobType !== 'Infratek rapport') {
            this.setState({
                infratekAndRapportNrFieldsDisabled: true,
            })
        } else {
            this.setState({
                infratekAndRapportNrFieldsDisabled: false,
            })
        }

        this.getStandardarbeidSources();
        this.getMarketingSources();
        this.getJobTypes();
    }

    getStandardarbeidSources() {
        this.setState({
            loading: true,
        })

        fetch(global.config.server.connection.url + "/api/Booking/GetStandardarbeidSources", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        loading: false,
                        standardarbeidOKArray: result.filter(function (standardarbeid) {
                            return standardarbeid.group === 1;
                        }),
                        standardarbeidNotOKArray: result.filter(function (standardarbeid) {
                            return standardarbeid.group === 0;
                        }),
                        standardarbeidInternalArray: result.filter(function (standardarbeid) {
                            return standardarbeid.group === 2;
                        }),
                    });
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
            )
    }

    getMarketingSources() {
        fetch(global.config.server.connection.url + "/api/Booking/GetMaketingSources", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        makretingSourceArray: result,
                        marketingSourceItems: result.map(resource => (
                            <MenuItem key={resource.id} value={resource.name}>{resource.name}</MenuItem>
                        ))
                    });
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
            )
    }

    getJobTypes() {
        fetch(global.config.server.connection.url + "/api/Booking/GetJobTypes?companyID=", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        jobTypeItems: result.map(jobType => (
                            <MenuItem key={Math.random()} value={jobType.jobTypeName}>{jobType.jobTypeName.charAt(0).toUpperCase() + jobType.jobTypeName.substring(1, jobType.jobTypeName.length)}</MenuItem>
                        )).sort()
                    });
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
            )
    }

    alternativeAddressClick() {
        console.log("ALTERNAVITE ADDRESS CLICKED")
        console.log(this.props.eventRecord.resourceId)
        var companyID = 4 //elektris

        fetch(global.config.server.connection.url + "/api/Booking/GetAlternativeAddressesForCustomer?customerID=" + this.state.customerId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log("ALTERNATIVE ADDRESSES ==>> ")
                    // console.log(result)
                    this.setState({
                        alternativeAddressItems: result.map(alternativeAddress => (
                            <MenuItem key={Math.random()} value={alternativeAddress.jobAdress + "-" + alternativeAddress.jobPostal + "-" + alternativeAddress.jobCity}>
                                {alternativeAddress.jobAdress} {alternativeAddress.jobPostal} {alternativeAddress.jobCity}
                            </MenuItem>
                        )).sort()
                    });
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
            )
    }

    alternativeAddressChanged({ target }) {
        this.setState({
            jobAdress: target.value.substring(0, target.value.indexOf('-')),
            jobPostal: target.value.substring(target.value.indexOf("-") + 1, target.value.lastIndexOf('-')),
            jobCity: target.value.substring(target.value.lastIndexOf('-') + 1)
        })

    }

    setJobFromJob = (obj) => {
        console.log(obj)
        this.setState({
            jobAdress: obj.jobAdress,
            jobCity: obj.jobCity,
            jobPostal: obj.jobPostal,
            name: obj.jobTitle,
            genderType: obj.gender,
            jobDescription: obj.jobDescription,
            ageType: obj.ageId,
            marketingSource: obj.jobsourceID,
            jobType: obj.jobTypeId,
            jobFromJobId: obj.jobId,
            jobFromJobOfferId: obj.offerID,
            paymentType: obj.paymentType,
            modalNotice: !this.state.modalNotice,
        });
    };

    getCampaigns() {

        fetch(global.config.server.connection.url + "/api/Booking/GetCampaigns?comanyID=4", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    //console.log(result)
                    this.setState({
                        jobSourceArray: result.map(resource => (
                            <MenuItem key={resource.id} value={resource.id}>{resource.name}</MenuItem>
                        ))
                    });
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
            )
    }

    getOffers(kundeNo) { //47679
        console.log(kundeNo)
        if(kundeNo == null){
            kundeNo = 0;
        }
        fetch(global.config.server.connection.url + "/api/Booking/GetOffers?kundeNo=" +kundeNo, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        jobSourceArray: result.map(resource => (
                            <MenuItem key={resource.id} value={resource.id}>Id: {resource.id}, Kunde: {resource.eierNavn}</MenuItem>
                        ))
                    });
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
            )

    }

    jobFromSourceSelectedClick(index) {
        console.log(index)
        this.setState(prev => ({
            ...prev,
            errorMessage: { ...prev.errorMessage, jobFromSourceSelectedError: "" }
        }))

        //Tilbud -> uten at kunde er valgt. Må populere kundedata basert på valgt tilbud.
        if (this.state.jobFromSource === 'Tilbud' && this.state.customerArray.length === 0) {
            //Tilbud uten at kunde er valgt
            fetch(global.config.server.connection.url + "/api/Booking/GetCustomerFromSelectedOffer?offerId=" + index.target.value, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
                },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        companyName: result[0].companyName,
                        customerId: result[0].customerId,
                        kundeNo: result[0].kundeNo,
                        jobContactName: result[0].jobContactName,
                        contactMobileNumber: result[0].contactMobileNumber,
                        jobAdress: result[0].jobAdress,
                        jobPostal: result[0].jobPostal,
                        jobCity: result[0].jobCity,
                        companyInvoiceAdress: result[0].companyInvoiceAdress,
                        companyInvoiceCity: result[0].companyInvoiceCity,
                        companyInvoiceContactName: result[0].companyInvoiceContactName,
                        companyInvoicePostal: result[0].companyInvoicePostal,
                        alternativeJobContactMobileNumber: result[0].contactMobileNumber,
                        alternativeJobContactName: result[0].jobContactName,
                        companyOrgNr: result[0].companyOrgNr,
                        customerEmail: result[0].companyEmail,
                        companyType: result[0].companyType,
                        isNewCustomer: ((result[0].newCustomer === 0) ? false : true),
                        receiveSMS: ((result[0].receiveSMS === 0) ? false : true),
                    });
                    
                },
                (error) => {
                    this.setState({
                        error
                    });
                }
            )
        }

        //Service -> tilknytte jobb
        if (this.state.jobFromSource === 'Service' && index.target.value === "3") { 
            fetch(global.config.server.connection.url + "/api/Booking/GetJobForCustomer?customerID=" + this.state.customerId, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
                },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        loading: false,
                        customerPrevJobsArray: result.map((job, key) => {
                            return {
                                id: key,
                                jobId: job["jobId"],
                                jobStartDate: job["jobStartDate"].substring(0, 10),
                                jobTitle: job["jobTitle"],
                                employeeUserName: job["employeeUserName"],
                                ageId: job["ageId"],
                                companyID: job["companyID"],
                                gender: job["gender"],
                                jobAdress: job["jobAdress"],
                                jobCity: job["jobCity"],
                                jobPostal: job["jobPostal"],
                                jobDescription: job["jobDescription"],
                                jobTypeId: job["jobTypeId"],
                                jobsourceID: job["jobsourceID"],
                                offerID: job["offerID"],
                                //paymentType: job["paymentType"],

                                actions: (
                                    <div className="actions-right">
                                        {/* button to select job */}
                                        <Button
                                            onClick={() => {
                                                let obj = this.state.customerPrevJobsArray.find(o => o.id === key);
                                                this.setJobFromJob(obj);
                                            }}
                                            color="primary"
                                            className="btn-icon btn-link edit"
                                        >
                                            <i className="fa fa-check" style={{ color: 'green' }} />
                                        </Button>{" "}
                                    </div>
                                )
                            }
                        }),
                    })
                },
                (error) => {
                    this.setState({
                        error: true
                    });
                }
            )
            this.toggleModalNotice()
        }

        this.setState({
            jobFromSourceSelected: index.target.value,
            loading: false,
        })
    }
    
    jobFromSourceChangedHandler({ target }) {

        if (target.value === 'Service') {
            let serviceJobAlternatives = [
                {
                    "id": "2",
                    "name": "Ny jobb",
                },
                {
                    "id": "3",
                    "name": "Tilknytte jobb",
                },
            ]
            this.setState({
                jobSourceArray: serviceJobAlternatives.map(obj => (
                    <MenuItem key={obj.id} value={obj.id}>{obj.name}</MenuItem>
                )),
            });
        }

        if (target.value === 'Kampanje')
            this.getCampaigns()

        if (target.value === 'Tilbud')
            this.getOffers(this.state.kundeNo)

        if (target.value === 'Avvik')
            console.log("avvik er valgt")
            //TODO?

        this.setState(prevState => {
            return {
                ...prevState,
                error: false,
                [target.name]: target.value,
                errorMessage: { ...prevState.errorMessage, jobFromSourceError: "" }
            };
        });

    }

    dataChangedHandler({ target }) {
        console.log(target.name)
        
        if (target.name === 'eventType') { //Jobbtype
            this.setState(prevState => {
                return {
                    ...prevState,
                    error: false,
                    [target.name]: target.value,
                    errorMessage: { ...prevState.errorMessage, eventTypeError: "" }
                };
            });
        }

        if (target.name === 'jobType') { //Oppdragstype

            if (target.value !== 'Infratek rapport') {
                this.setState({
                    infratekAndRapportNrFieldsDisabled: true,
                })
            } else {
                this.setState({
                    infratekAndRapportNrFieldsDisabled: false,
                })
            }
            this.setState(prevState => {
                return {
                    ...prevState,
                    error: false,
                    [target.name]: target.value,
                    errorMessage: { ...prevState.errorMessage, jobTypeError: "" }
                };
            });
        }

        if (target.name === 'companyType') { //Kundetype
            this.setState(prevState => {
                return {
                    ...prevState,
                    error: false,
                    [target.name]: target.value,
                    errorMessage: { ...prevState.errorMessage, companyTypeError: "" }
                };
            });
        }

        if (target.name === 'jobPostal') {  ///if changed value is postal code -> check if postal code is valid
            this.validPostalCodeHandler(target.value)

            this.setState({
                jobPostal: target.value,
            })
        }

        if (target.name === 'jobAdress') { //JobbAdress
            console.log("IN JOB ADRESS")
            this.setState(prevState => {
                return {
                    ...prevState,
                    error: false,
                    [target.name]: target.value,
                    errorMessage: { ...prevState.errorMessage, eventTypeError: "HVA FAEN SKJER??" }
                };
            });
        }

        if (target.name === 'alternativeAddress') {
            if (target.value === 'Bruk fakturaadresse') {
                this.setState({
                    jobAdress: this.state.companyInvoiceAdress,
                    jobPostal: this.state.companyInvoicePostal,
                    jobCity: this.state.companyInvoiceCity
                })
            } else {
                this.setState({
                    [target.name]: target.value,
                    jobAdress: target.value.substring(0, target.value.indexOf('-')),
                    jobPostal: target.value.substring(target.value.indexOf("-") + 1, target.value.lastIndexOf('-')),
                    jobCity: target.value.substring(target.value.lastIndexOf('-') + 1)
                })
            }
        }

        this.setState(prevState => {
            return {
                ...prevState,
                [target.name]: target.value,
                errorMessage: { ...prevState.errorMessage, [target.name]: "" }
            };
        });
    }

    smsAndEmailCheckedChangedHandler({ target }) {
        this.setState({
            receiveSMS: target.checked,
        })

    }

    startDateChangeHandler(startDate) {
        this.setState(prevState => {
            return {
                ...prevState,
                startDate: startDate
            };
        });
    }

    endDateChangeHandler(endDate) {
        this.setState(prevState => {
            return {
                ...prevState,
                endDate: endDate
            };
        });
    }

    //Get/Set correct customer value
    customerChangedHandler(newInputValue) {
        if (this.state.searchFieldValue === newInputValue) {
            return
        }
        this.setState({
            searchFieldValue: newInputValue,
        });

        var strLenght = newInputValue.length;
        if (strLenght > 4) {

            fetch(global.config.server.connection.url + "/api/Booking/SearchCustomers?comanyID=4&searchValue=" + newInputValue, {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
                },
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.length === 0) {  //no results from search
                            try {
                                this.setState({
                                    customerArray: [],
                                    isNewCustomer: true,
                                    jobFromSourceSelected: "",
                                    jobFromSource: "",
                                    //blank other fields??
                                });
                            } catch (error) {
                                return;
                            }
                        } 
                        else //results from search
                        { 
                            if (result[0].newCustomer === 1) {
                                this.props.notifyNewCustomer();
                                this.validPostalCodeHandler(result[0].jobPostal)
                            }

                            try {
                                this.setState({
                                    customerArray: result,
                                    jobFromSourceSelected: "",
                                    jobFromSource: "",
                                    //used to set values directly here
                                });
                            } catch (error) {
                                console.log("ERROR")
                                return;
                            }
                        }
                    },
                    (error) => {
                        this.setState({
                            error
                        });
                    }
                )
        }
    }

    formatDate(date) {
        var year = new Date(date).getFullYear();
        var month = new Date(date).getMonth() + 1;
        if (month < 10)
            month = '0' + month

        var day = new Date(date).getDate();
        if (day < 10)
            day = '0' + day

        var hour = new Date(date).getHours();
        if (hour < 10)
            hour = '0' + hour

        var minutes = new Date(date).getMinutes();
        if (minutes < 10)
            minutes = '0' + minutes

        var formattedDate = year + "-" + month + "-" + day + ":" + hour + ":" + minutes
        return formattedDate;
    }

    //HANDLER FOR RADIO BUTTON CHANGE ON SJEKKLISTE
    standardarbeidRadioChange({ target }) {
        this.setState({
            standardarbeidRadioValue: target.id,
        });

        if (target.value === 'Ferie' || target.value === 'Fri' || target.value === 'Møte' || target.value === 'Syk med egenmelding' || target.value === 'Syk med sykemelding') {
            this.setState({
                internalTime: true,
            })
        } else {
            this.setState({
                internalTime: false,
            })
        }
    }

    //SHOW ERROR MESSAGE
    errorAlert = (message) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="BUG!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    {message}
                </ReactBSAlert>
            )
        });
    };

    //HIDE ERROR MESSAGE
    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    //VALID POSTAL CODE?
    validPostalCodeHandler(postCode) {
        var validPostalCodes = "1300,1301,1302,1303,1304,1305,1306,1307,1308,1309,1311,1312,1313,1314,1316,1317,1318,1319,1321,1322,1323,1324,1325,1326,1327,1328,1329,1330,1331,1332,1333,1334,1335,1336,1337,1338,1339,1340,1341,1342,1344,1346,1348,1349,1350,1351,1352,1353,1354,1356,1357,1358,1359,1360,1361,1362,1363,1364,1365,1366,1367,1368,1369,1371,1372,1373,1375,1376,1377,1378,1379,1380,1381,1383,1384,1385,1386,1387,1388,1389,1390,1391,1392,1393,1394,1395,1396,1397,1399,1371,1372,1373,1375,1376,1377,1378,1379,1380,1381,1383,1384,1385,1386,1387,1388,1389,1390,1391,1392,1393,1394,1395,1396,1397,1399,1400,1401,1402,1403,1404,1405,1406,1407,1408,1409,1410,1411,1412,1413,1414,1415,1416,1417,1418,1419,1420,1421,1422,1423,1424,1425,1429,1430,1431,1432,1433,1434,1435,1450,1451,1452,1453,1454,1455,1456,1457,1458,1459,1461,1462,1463,1464,1465,1466,1467,2000,2001,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022,2023,2024,2025,2026,2027,2028,2029"

        if (postCode.length === 4 && validPostalCodes.indexOf(postCode) === -1 && postCode > 1299) {
            this.props.notifyInvalidPostalCode()
            return;

        }
    }

    //NEXT CLICK ON SJEKKLISTE
    nextClickHandler() {
        if (this.state.standardarbeidRadioValue === '') {
            this.props.notifyMissingChecked()
            return;
        } else {
            this.props.hideKontrollSkjema()
        }
    }

    //VALIDATE JOB VALUES
    validateJobData() {
        testFunction(this.state)

        console.log("IN VALIDATE JOB DATA")
        
        let isError = false;

        //Type Booking
        if (this.state.jobFromSource === '') {
            isError = true;
            this.setState(prev => ({
                ...prev,
                error: true,
                errorMessage: { ...prev.errorMessage, jobFromSourceError: "Du må velge type booking" }
            }))
        }
        //Velg kilde
        // if (this.state.jobFromSourceSelected === '') {
        //     isError = true;
        //     this.setState(prev => ({
        //         ...prev,
        //         error: true,
        //         errorMessage: { ...prev.errorMessage, jobFromSourceSelectedError: "Du må velge en kilde" }
        //     }))
        // }
        //Fakturaadresse -> Navn
        // if (this.state.companyName === '') {
        //     isError = true;
        //     this.setState(prev => ({
        //         ...prev,
        //         error: true,
        //         errorMessage: { ...prev.errorMessage, companyName: "Kundenavn kan ikke være tomt" }
        //     }))
        // }
        // //Fakturaadresse -> Telefon
        // if (this.state.contactMobileNumber === '') {
        //     isError = true;
        //     this.setState(prev => ({
        //         ...prev,
        //         error: true,
        //         errorMessage: { ...prev.errorMessage, contactMobileNumber: "Kundens mobilnummer" }
        //     }))
        // }
        // //Fakturaadresse -> Telefon
        // if (this.state.companyInvoiceAdress === '') {
        //     isError = true;
        //     this.setState(prev => ({
        //         ...prev,
        //         error: true,
        //         errorMessage: { ...prev.errorMessage, companyInvoiceAdress: "Kundens adresse" }
        //     }))
        // }
        // if (this.state.companyInvoicePostal === '') {
        //     isError = true;
        //     this.setState(prev => ({
        //         ...prev,
        //         error: true,
        //         errorMessage: { ...prev.errorMessage, companyInvoicePostal: "Kundens postnummer" }
        //     }))
        // }
        // if (this.state.companyInvoiceCity === '') {
        //     isError = true;
        //     this.setState(prev => ({
        //         ...prev,
        //         error: true,
        //         errorMessage: { ...prev.errorMessage, companyInvoiceCity: "Kundens poststed" }
        //     }))
        // }
        // //anleggsadresse
        // if (this.state.jobAdress === '') {
        //     isError = true;
        //     this.setState(prev => ({
        //         ...prev,
        //         error: true,
        //         errorMessage: { ...prev.errorMessage, jobAdressError: "Skriv inn anleggsadresse" }
        //     }))
        // }
        // if (this.state.jobPostal === '') {
        //     isError = true;
        //     this.setState(prev => ({
        //         ...prev,
        //         error: true,
        //         errorMessage: { ...prev.errorMessage, jobPostal: "Skriv inn postnummer" }
        //     }))
        // }
        // if (this.state.jobCity === '') {
        //     isError = true;
        //     this.setState(prev => ({
        //         ...prev,
        //         error: true,
        //         errorMessage: { ...prev.errorMessage, jobCity: "Skriv inn poststed" }
        //     }))
        // }
        // if (this.state.alternativeJobContactName === '') {
        //     isError = true;
        //     this.setState(prev => ({
        //         ...prev,
        //         error: true,
        //         errorMessage: { ...prev.errorMessage, alternativeJobContactName: "Skriv inn kontaktperson" }
        //     }))
        // }
        // if (this.state.alternativeJobContactMobileNumber === '') {
        //     isError = true;
        //     this.setState(prev => ({
        //         ...prev,
        //         error: true,
        //         errorMessage: { ...prev.errorMessage, alternativeJobContactMobileNumber: "Skriv inn telefonnummer" }
        //     }))
        // }
        // if (this.state.name === '') {
        //     isError = true;
        //     this.setState(prev => ({
        //         ...prev,
        //         error: true,
        //         errorMessage: { ...prev.errorMessage, name: "Skriv inn tittel på jobben" }
        //     }))
        // }
        // if (this.state.eventType === '') {
        //     isError = true;
        //     this.setState(prev => ({
        //         ...prev,
        //         error: true,
        //         errorMessage: { ...prev.errorMessage, eventTypeError: "Velg jobbtype" }
        //     }))
        // }
        // if (this.state.jobType === '') {
        //     isError = true;
        //     this.setState(prev => ({
        //         ...prev,
        //         error: true,
        //         errorMessage: { ...prev.errorMessage, jobType: "Velg oppdragstype" }
        //     }))
        // }
        // if (this.state.companyType === '') {
        //     isError = true;
        //     this.setState(prev => ({
        //         ...prev,
        //         error: true,
        //         errorMessage: { ...prev.errorMessage, companyTypeError: "Velg kundetype" }
        //     }))
        // }


        // if (!isError) {
        //     this.setState(prevState => ({
        //         error: false,
        //         errorMessage: {}
        //     }));
        // }
        // if (!this.state.internalTime) {
        //     if (this.state.name === '' || this.state.jobDescription === '' || this.state.jobType === '' || this.state.companyName === '' ||
        //         this.state.customerEmail === '' || this.state.contactMobileNumber === '' || this.state.jobAdress === '' ||
        //         this.state.jobPostal === '' || this.state.jobCity === '' || this.state.genderType === '' || this.state.ageType === '' ||
        //         this.state.marketingSource === '') {
        //         this.props.notifyMissingValues()
        //         return;
        //     }

        //     if (!this.state.infratekAndRapportNrFieldsDisabled) {
        //         if (this.state.infratekRapportNr === '' || this.state.maalerNr === '') {
        //             this.props.notifyMissingValues()
        //             return;
        //         }
        //     }
        // }
        console.log("SAVING THE JOB")
        //this.saveClickHandlerAsync();
    }

    //SAVE JOB
    async saveClickHandlerAsync() {
        console.log("===>> SAVING JOB!!!")

        this.setState({
            loading: true,
        })

        const eventRecord = this.props.eventRecord;
        var startDate = this.formatDate(this.props.eventRecord.data.startDate)
        var stopDate = this.formatDate(this.props.eventRecord.data.endDate)


        if (this.state.internalTime) { //interntid skal bookes
            await fetch(global.config.server.connection.url + "/api/Booking/CreateInternalTimeAsync?employeeID=" + this.props.eventRecord.data.resourceId +
                "&startDateTime=" + startDate + "&stopDateTime=" + stopDate + "&jobTitle=" + this.state.name +
                "&jobTitle=" + this.state.name + "&jobDescription=" + this.state.jobDescription + "&standardarbeidID=" + this.state.standardarbeidRadioValue, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
                },
            })
                .then(res => {
                    if (!res.ok) {
                        return res.text().then(text => { throw new Error(text) })
                    }
                    else {
                        eventRecord.set({ ...this.state });
                        eventRecord.data.eventColor = '#A0A0A0';
                        eventRecord.data.iconCls = 'b-fa b-fa-user';

                        if (!eventRecord.eventStore) {
                            this.props.eventStore.add(eventRecord);
                        }
                        this.props.notifyJobCreated()
                        this.props.closePopup();
                        return
                    }
                })
                .catch(err => this.errorAlert(err.message))
        } 
        else {
            await fetch(global.config.server.connection.url + "/api/Booking/CreateBookingAsync?ageType=" + this.state.ageType + "&builtYear=" + this.state.buildYear +
                "&customerId=" + this.state.customerId + "&customerName=" + this.state.companyName + "&contactMobileNumber=" + this.state.contactMobileNumber +
                "&contactPhoneNumber=" + this.state.contactPhoneNumber + "&eventType=" + this.state.eventType + "&jobTitle=" + this.state.name + "&jobTYpe=" + this.state.jobType +
                "&paymentType=" + this.state.paymentType + "&genderType=" + this.state.genderType + "&jobContactName=" + this.state.jobContactName +
                "&marketingSource=" + this.state.marketingSource + "&renovationYear=" + this.state.oppussingdate + "&renovationWork=" + this.state.oppussingjob +
                "&smsAndEmailChecked=" + this.state.receiveSMS + "&infratekRapportNr=" + this.state.infratekRapportNr + "&maalerNr=" + this.state.maalerNr +
                "&startDateTime=" + startDate + "&stopDateTime=" + stopDate + "&employeeID=" + this.props.eventRecord.data.resourceId + "&jobDescription=" + this.state.jobDescription +
                "&jobAdress=" + this.state.jobAdress + "&jobPostal=" + this.state.jobPostal + "&jobCity=" + this.state.jobCity +
                "&isNewCustomer=" + this.state.isNewCustomer + "&statusSikring=" + this.state.statusSikring + "&customerEmail=" + this.state.customerEmail +
                "&standardarbeidID=" + this.state.standardarbeidRadioValue + "&companyInvoiceAdress=" + this.state.companyInvoiceAdress + "&companyInvoiceCity=" + this.state.companyInvoiceCity +
                "&companyInvoicePostal=" + this.state.companyInvoicePostal + "&alternativeJobContactName=" + this.state.alternativeJobContactName +
                "&alternativeJobContactMobileNumber=" + this.state.alternativeJobContactMobileNumber +
                "&companyType=" + this.state.companyType + "&companyOrgNr=" + this.state.companyOrgNr + "&companyContactName=" + this.state.companyInvoiceContactName + "&campaignID=" + this.state.campaignID, {

                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
                },
            })
                .then(async res => {
                    if (!res.ok) {
                        const text = await res.text();
                        throw new Error(text);
                    }
                    else {
                        eventRecord.set({ ...this.state });

                        switch (this.state.eventType) {
                            case 'Service':
                                eventRecord.data.eventColor = '#d0986b';
                                eventRecord.data.iconCls = 'b-fa b-fa-calendar';
                                break;
                            case 'Reklamasjon':
                                eventRecord.data.eventColor = '#EF8157';
                                eventRecord.data.iconCls = 'b-fa b-fa-bug';
                                break;
                            case 'Befaring':
                                eventRecord.data.eventColor = '#51BCDA';
                                eventRecord.data.iconCls = 'b-fa b-fa-search';
                                break;
                            case 'Interntid':
                                eventRecord.data.eventColor = '#A0A0A0';
                                eventRecord.data.iconCls = 'b-fa b-fa-user';
                                break;
                            default:
                        }

                        if (!eventRecord.eventStore) {
                            this.props.eventStore.add(eventRecord);
                        }

                        this.props.notifyJobCreated()
                        this.props.closePopup();
                        return
                    }
                })
                .catch(err => this.errorAlert(err.message))
        }

        this.setState({
            loading: false,
        })
    }

    toggleModalNotice = (obj) => {
        console.log(obj)
        this.setState({
            modalNotice: !this.state.modalNotice,
            selectedCampaign: obj
        });
    };


    /**
     * @return The markup to render
     */
    render() {

        if (this.state.loading) {
            return (
                <Loader />
            )
        }

        const { standardarbeidOKArray, standardarbeidNotOKArray, standardarbeidInternalArray, customerArray, receiveSMS, customerPrevJobsArray } = this.state;

        //sort arrays alphabetical before display in UI
        standardarbeidOKArray.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
            return 0;
        })

        standardarbeidNotOKArray.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
            return 0;
        })

        standardarbeidInternalArray.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
            return 0;
        })


        const resourceItems = this.props.resourceStore.map(resource => (
            <MenuItem key={resource.id} value={resource.id}>{resource.name}</MenuItem>
        ));

        const customerProps = {
            options: customerArray,
            getOptionLabel: (option) => option.companyName + " - TELEFON: " + option.contactMobileNumber + " -> ORGNR: " + option.companyOrgNr
        };

        return (
            <>
                {this.state.alert}

                <Modal
                    size="lg" style={{ maxWidth: '700px', width: '100%' }}
                    isOpen={this.state.modalNotice}
                    toggle={this.toggleModalNotice}
                >
                    <div className="modal-header">
                        <button
                            aria-hidden={true}
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.toggleModalNotice}
                        >
                            <i className="nc-icon nc-simple-remove" />
                        </button>

                        <h5 className="modal-title" id="myModalLabel">
                            Velg jobb du ønsker å tilknytte
                        </h5>
                    </div>

                    <div className="modal-body">
                        <ReactTable
                            noDataText='kunden har ingen tidligere jobber'
                            data={customerPrevJobsArray}
                            filterable
                            columns={[
                                {
                                    Header: "Job ID",
                                    accessor: "jobId",
                                    filterable: false,
                                    sortable: false,
                                },
                                {
                                    Header: "Dato",
                                    accessor: "jobStartDate",
                                    filterable: false,
                                    sortable: false,
                                },
                                {
                                    Header: "Tittel",
                                    accessor: "jobTitle",
                                    filterable: false,
                                    sortable: false,
                                },
                                {
                                    Header: "Montør",
                                    accessor: "employeeUserName",
                                    filterable: false,
                                    sortable: false,
                                },
                                {
                                    Header: "",
                                    accessor: "actions",
                                    sortable: false,
                                    filterable: false,
                                    width: 80,
                                }
                            ]}
                            defaultPageSize={8}
                            /*
                              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                            */
                            className="-striped -highlight none-pagination"
                        />
                    </div>
                </Modal>



                <div className='popup-mask'>
                    <SjekklisteStandardarbeid  
                        standardarbeidInternalArray={this.state.standardarbeidInternalArray} 
                        standardarbeidOKArray={this.state.standardarbeidOKArray}
                        standardarbeidNotOKArray={this.state.standardarbeidOKArray}
                        showKontrollSkjema={this.props.showKontrollSkjema}
                        standardarbeidRadioChange={this.standardarbeidRadioChange}
                        closePopup={this.props.closePopup}
                        nextClickHandler={this.nextClickHandler}
                    />

                    <div className='popup' style={{ overflow: 'scroll', display: this.props.showBookingSkjema }}>
                        <header style={{ backgroundColor: '#F4F3EF', textAlign: 'center' }}>
                            <b>Ny Booking</b>
                        </header>

                        <article>
                            <FormControl style={{ marginBottom: 10, width: '100%' }}>
                                <InputLabel>Montør</InputLabel>
                                <Select
                                    name="resourceId"
                                    onChange={this.dataChangedHandler}
                                    value={this.state.resourceId}
                                >
                                    {resourceItems}
                                </Select>
                            </FormControl>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    name="startDate"
                                    label="Start"
                                    ampm={false}
                                    format="yyyy-MM-dd HH:mm"
                                    style={{ width: '49%', marginRight: 5 }}
                                    value={this.state.startDate}
                                    onChange={this.startDateChangeHandler}
                                ></KeyboardDateTimePicker>
                                <KeyboardDateTimePicker
                                    name="endDate"
                                    label="End"
                                    ampm={false}
                                    format="yyyy-MM-dd HH:mm"
                                    style={{ width: '49%', marginLeft: 5 }}
                                    value={this.state.endDate}
                                    onChange={this.endDateChangeHandler}
                                ></KeyboardDateTimePicker>
                            </MuiPickersUtilsProvider>

                            <FormControl style={{ marginBottom: 10, marginTop: 20, width: '98%', marginLeft: 5, marginRight: 5 }}>
                                <Autocomplete
                                    {...customerProps}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    id="jobSearchField"
                                    //freeSolo
                                    inputValue={this.state.searchFieldValue}
                                    disabled={this.state.internalTime}
                                    onChange={(event, newValue) => {
                                        try {
                                            this.setState({
                                                companyName: newValue.companyName,
                                                customerId: newValue.customerId,
                                                kundeNo: newValue.kundeNo,
                                                jobContactName: newValue.jobContactName,
                                                contactMobileNumber: newValue.contactMobileNumber,
                                                // jobAdress: newValue.jobAdress,
                                                // jobPostal: newValue.jobPostal,
                                                // jobCity: newValue.jobCity,
                                                companyInvoiceAdress: newValue.companyInvoiceAdress,
                                                companyInvoiceCity: newValue.companyInvoiceCity,
                                                companyInvoiceContactName: newValue.companyInvoiceContactName,
                                                companyInvoicePostal: newValue.companyInvoicePostal,
                                                alternativeJobContactMobileNumber: newValue.contactMobileNumber,
                                                alternativeJobContactName: newValue.jobContactName,
                                                companyOrgNr: newValue.companyOrgNr,
                                                customerEmail: newValue.companyEmail,
                                                //companyType: newValue.companyType,
                                                isNewCustomer: ((newValue.newCustomer === 0) ? false : true),
                                                receiveSMS: ((newValue.receiveSMS === 0) ? false : true),
                                            });
                                            //this.customerChangedHandler(this.state.searchFieldValue) //blir kluss for Microsoft - 23500500 - sjekk linje 576 + orgnr: 996598756

                                            if (newValue.blacklist === 1) {
                                                this.props.notifyBlacklistedCustomer();
                                            }

                                        } catch (error) {
                                            return;
                                        }

                                    }}
                                    onInputChange={(event, newInputValue, reason) => {
                                        //console.log("Autocomplete -> onInputChange")
                                        if (reason === 'reset') {
                                            //console.log("REASON = reset") //when choosing from already searched customers
                                            this.customerChangedHandler(this.state.searchFieldValue)
                                            return
                                        } else {
                                            //console.log("REASON != reset") //when serching with new value
                                            this.customerChangedHandler(newInputValue)
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Søk på kunde" variant="outlined" autoFocus />}
                                />
                            </FormControl>


                            <FormControl style={{ marginBottom: 10, width: '39%', marginLeft: 5 }} error={this.state.error}>
                                <InputLabel style={{ color: 'gray' }}>Type Booking</InputLabel>
                                <Select
                                    name="jobFromSource"
                                    error={!!this.state.errorMessage.jobFromSourceError}
                                    required
                                    onChange={this.jobFromSourceChangedHandler}
                                    value={this.state.jobFromSource}
                                    disabled={this.state.internalTime}
                                >
                                    <MenuItem value="Service" >Service</MenuItem>
                                    <MenuItem value="Kampanje" >Kampanje</MenuItem>
                                    <MenuItem value="Tilbud">Tilbud</MenuItem>
                                    <MenuItem value="Avvik">Avvik</MenuItem>
                                </Select>
                                <FormHelperText>{this.state.errorMessage.jobFromSourceError}</FormHelperText>
                            </FormControl>

                            <FormControl style={{ marginBottom: 10, width: '59%', marginLeft: 5 }} error={this.state.error}>
                                <InputLabel style={{ color: 'gray' }}>Velg kilde</InputLabel>
                                <Select
                                    name="jobFromSourceSelected"
                                    error={!!this.state.errorMessage.jobFromSourceSelectedError}
                                    required
                                    onChange={this.jobFromSourceSelectedClick}
                                    value={this.state.jobFromSourceSelected}
                                    disabled={this.state.internalTime}
                                >
                                    {this.state.jobSourceArray}
                                </Select>
                                <FormHelperText>{this.state.errorMessage.jobFromSourceSelectedError}</FormHelperText>
                            </FormControl>



                            <br /><br />
                            <h6>Fakturaadresse:</h6>
                            <TextField
                                name="companyName"
                                error={!!this.state.errorMessage.companyName}
                                label="Kundens navn"
                                disabled={this.state.internalTime}
                                onChange={this.dataChangedHandler}
                                value={this.state.companyName}
                                style={{ marginBottom: 10, width: '40%', marginRight: 5 }}
                                helperText={this.state.errorMessage.companyName}
                            />
                            
                            <TextField
                                name="customerEmail"
                                label="Epost"
                                disabled={this.state.internalTime}
                                onChange={this.dataChangedHandler}
                                value={this.state.customerEmail}
                                style={{ marginBottom: 10, width: '40%', marginRight: 5}}
                            />
                            <TextField
                                name="contactMobileNumber"
                                error={!!this.state.errorMessage.contactMobileNumber}
                                label="Telefon"
                                disabled={this.state.internalTime}
                                onChange={this.dataChangedHandler}
                                value={this.state.contactMobileNumber}
                                style={{ marginBottom: 10, width: '18%', marginRight: 5 }}
                                helperText={this.state.errorMessage.contactMobileNumber}
                            />
                            <TextField
                                name="companyInvoiceAdress"
                                error={!!this.state.errorMessage.companyInvoiceAdress}
                                label="Adresse"
                                disabled={this.state.internalTime}
                                onChange={this.dataChangedHandler}
                                value={this.state.companyInvoiceAdress}
                                style={{ marginBottom: 10, width: '59%', marginRight: 5 }}
                                helperText={this.state.errorMessage.companyInvoiceAdress}
                            />
                            <TextField
                                name="companyInvoicePostal"
                                error={!!this.state.errorMessage.companyInvoicePostal}
                                label="Postnummer"
                                disabled={this.state.internalTime}
                                onChange={this.dataChangedHandler}
                                value={this.state.companyInvoicePostal}
                                style={{ marginBottom: 10, width: '19%', marginRight: 5 }}
                                helperText={this.state.errorMessage.companyInvoicePostal}
                            />
                            <TextField
                                name="companyInvoiceCity"
                                error={!!this.state.errorMessage.companyInvoiceCity}
                                label="Sted"
                                disabled={this.state.internalTime}
                                onChange={this.dataChangedHandler}
                                value={this.state.companyInvoiceCity}
                                style={{ marginBottom: 10, width: '19%', marginRight: 5 }}
                                helperText={this.state.errorMessage.companyInvoiceCity}
                            />
                            <TextField
                                name="companyInvoiceContactName"
                                label="Ved / (c/o):"
                                disabled={this.state.internalTime}
                                onChange={this.dataChangedHandler}
                                value={this.state.companyInvoiceContactName}
                                style={{ marginBottom: 10, width: '50%', marginRight: 5 }}
                            />
                            <TextField
                                name="companyOrgNr"
                                label="Organisasjonsnummer"
                                disabled={this.state.internalTime}
                                onChange={this.dataChangedHandler}
                                value={this.state.companyOrgNr}
                                style={{ marginBottom: 10, width: '30%', marginRight: 5 }}
                            />

                            <br /><br />

                            <h6>Anleggsadresse:</h6>
                            <FormControl style={{ marginBottom: 10, width: '99%', marginLeft: 5 }} error={this.state.error}>
                                <InputLabel style={{ color: 'gray' }}>Velg Anleggsadresse</InputLabel>
                                <Select
                                    name="alternativeAddress"
                                    error={!!this.state.errorMessage.alternativeAddress}
                                    onOpen={this.alternativeAddressClick}
                                    onChange={this.dataChangedHandler}
                                    value={this.state.alternativeAddressSelected}
                                >
                                    <MenuItem value="Bruk fakturaadresse">Bruk fakturaadresse</MenuItem>
                                    {this.state.alternativeAddressItems}

                                </Select>
                                <FormHelperText>{this.state.errorMessage.alternativeAddress}</FormHelperText>
                            </FormControl>

                            <TextField
                                name="jobAdress"
                                error={!!this.state.errorMessage.jobAdressError}
                                label="Anleggsadresse"
                                disabled={this.state.internalTime}
                                onChange={this.dataChangedHandler}
                                value={this.state.jobAdress}
                                style={{ marginBottom: 10, width: '59%', marginRight: 5 }}
                                helperText={this.state.errorMessage.jobAdressError}
                            />
                            <TextField
                                name="jobPostal"
                                error={!!this.state.errorMessage.jobPostal}
                                label="Postnummer"
                                disabled={this.state.internalTime}
                                onChange={this.dataChangedHandler}
                                value={this.state.jobPostal}
                                style={{ marginBottom: 10, width: '19%', marginRight: 5 }}
                                helperText={this.state.errorMessage.jobPostal}
                            />
                            <TextField
                                name="jobCity"
                                error={!!this.state.errorMessage.jobCity}
                                label="Sted"
                                disabled={this.state.internalTime}
                                onChange={this.dataChangedHandler}
                                value={this.state.jobCity}
                                style={{ marginBottom: 10, width: '19%', marginRight: 5 }}
                                helperText={this.state.errorMessage.jobCity}
                            />
                            <TextField
                                name="alternativeJobContactName"
                                error={!!this.state.errorMessage.alternativeJobContactName}
                                label="Kontaktperson"
                                disabled={this.state.internalTime}
                                onChange={this.dataChangedHandler}
                                value={this.state.alternativeJobContactName}
                                style={{ marginBottom: 10, width: '49%', marginRight: 5 }}
                                helperText={this.state.errorMessage.alternativeJobContactName}
                            />
                            <TextField
                                name="alternativeJobContactMobileNumber"
                                error={!!this.state.errorMessage.alternativeJobContactMobileNumber}
                                label="Telefon"
                                disabled={this.state.internalTime}
                                onChange={this.dataChangedHandler}
                                value={this.state.alternativeJobContactMobileNumber}
                                style={{ marginBottom: 10, width: '49%', marginRight: 5 }}
                                helperText={this.state.errorMessage.alternativeJobContactMobileNumber}
                            />

                            <br /><br />
                            <h6>Jobbdetaljer:</h6>

                            <TextField
                                name="name"
                                error={!!this.state.errorMessage.name}
                                label="Tittel"
                                onChange={this.dataChangedHandler}
                                value={this.state.name}
                                style={{ marginBottom: 10 + 'px', width: '98%', marginRight: 5 }}
                                helperText={this.state.errorMessage.name}
                            />

                            <TextField
                                name="jobDescription"
                                label="Beskrivelse"
                                onChange={this.dataChangedHandler}
                                value={this.state.jobDescription}
                                style={{ marginBottom: 10 + 'px', width: '98%', marginRight: 5 }}
                            />


                            <FormControl style={{ marginBottom: 10, width: '39%', marginLeft: 5 }} error={this.state.error}>
                                <InputLabel style={{ color: 'gray' }}>Jobbtype</InputLabel>
                                <Select
                                    name="eventType"
                                    error={!!this.state.errorMessage.eventTypeError}
                                    onChange={this.dataChangedHandler}
                                    value={this.state.eventType}
                                    disabled={this.state.internalTime}
                                >
                                    <MenuItem value="Jobb">Servicejobb</MenuItem>
                                    <MenuItem value="Befaring">Befaring</MenuItem>
                                    <MenuItem value="Reklamasjon">Reklamasjon</MenuItem>
                                    <MenuItem value="Vakt">Vakt</MenuItem>
                                </Select>
                                <FormHelperText>{this.state.errorMessage.eventTypeError}</FormHelperText>
                            </FormControl>
                            
                            

                            <FormControl style={{ marginBottom: 10, width: '39%', marginLeft: 5 }} error={this.state.error}>
                                <InputLabel style={{ color: 'gray' }}>Oppdragstype</InputLabel>
                                <Select
                                    name="jobType"
                                    error={!!this.state.errorMessage.jobType}
                                    disabled={this.state.internalTime}
                                    onChange={this.dataChangedHandler}
                                    //onOpen={this.getJobTypes}
                                    value={this.state.jobType}
                                >
                                    {this.state.jobTypeItems}
                                </Select>
                                <FormHelperText>{this.state.errorMessage.jobType}</FormHelperText>
                            </FormControl>

                            <FormControl style={{ marginBottom: 10, width: '19%', marginLeft: 5 }} error={this.state.error}>
                                <InputLabel style={{ color: 'gray' }}>Kundetype</InputLabel>
                                <Select
                                    name="companyType"
                                    error={!!this.state.errorMessage.companyTypeError}
                                    onChange={this.dataChangedHandler}
                                    value={this.state.companyType}
                                    disabled={this.state.internalTime}
                                >
                                    <MenuItem value="0" >Privat</MenuItem>
                                    <MenuItem value="1">Sameie/ Borettslag</MenuItem>
                                    <MenuItem value="2">Bedrift</MenuItem>
                                </Select>
                                <FormHelperText>{this.state.errorMessage.companyTypeError}</FormHelperText>
                            </FormControl>

                            <TextField
                                name="infratekRapportNr"
                                label="Infratek Rapport Nr."
                                disabled={this.state.infratekAndRapportNrFieldsDisabled}
                                onChange={this.dataChangedHandler}
                                value={this.state.infratekRapportNr}
                                style={{ marginBottom: 10, width: '49%', marginRight: 5 }}
                            />
                            <TextField
                                name="maalerNr"
                                label="Målernummer (pin kode)"
                                disabled={this.state.infratekAndRapportNrFieldsDisabled}
                                onChange={this.dataChangedHandler}
                                value={this.state.maalerNr}
                                style={{ marginBottom: 10, width: '49%', marginRight: 5 }}
                            />

                            <br /><br />
                            <h6>Øvrig Informasjon:</h6>

                            <FormControl style={{ marginBottom: 10, width: '20%', marginLeft: 5 }}>
                                <InputLabel>Betaling</InputLabel>
                                <Select
                                    name="paymentType"
                                    onChange={this.dataChangedHandler}
                                    value={this.state.paymentType}
                                    disabled={this.state.internalTime}
                                >
                                    <MenuItem value="1">Kort</MenuItem>
                                    <MenuItem value="2">Faktura</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl style={{ marginBottom: 10, width: '20%', marginLeft: 5 }}>
                                <InputLabel>Kjønn</InputLabel>
                                <Select
                                    name="genderType"
                                    onChange={this.dataChangedHandler}
                                    value={this.state.genderType}
                                    disabled={this.state.internalTime}
                                >
                                    <MenuItem value="1">Mann</MenuItem>
                                    <MenuItem value="2">Kvinne</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl style={{ marginBottom: 10, width: '20%', marginLeft: 5 }}>
                                <InputLabel>Alder</InputLabel>
                                <Select
                                    name="ageType"
                                    onChange={this.dataChangedHandler}
                                    value={this.state.ageType}
                                    disabled={this.state.internalTime}
                                >
                                    <MenuItem value="1">yngre enn 30</MenuItem>
                                    <MenuItem value="2">30-35</MenuItem>
                                    <MenuItem value="3">35-40</MenuItem>
                                    <MenuItem value="4">40-45</MenuItem>
                                    <MenuItem value="9">45-50</MenuItem>
                                    <MenuItem value="5">50-55</MenuItem>
                                    <MenuItem value="6">55-60</MenuItem>
                                    <MenuItem value="7">60-70</MenuItem>
                                    <MenuItem value="8">eldre enn 70</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl style={{ marginBottom: 10, width: '37%', marginLeft: 5 }}>
                                <InputLabel>Kilde</InputLabel>
                                <Select
                                    name="marketingSource"
                                    //onOpen={this.getMarketingSources}
                                    onChange={this.dataChangedHandler}
                                    value={this.state.marketingSource}
                                    disabled={this.state.internalTime}
                                >
                                    {this.state.marketingSourceItems}
                                </Select>
                            </FormControl>

                            {/* <FormControl style={{ marginBottom: 10, width: '32%', marginLeft: 5 }}>
                                <InputLabel>Byggeår</InputLabel>
                                <Select
                                    name="buildYear"
                                    onChange={this.dataChangedHandler}
                                    value={this.state.buildYear}
                                    disabled={this.state.internalTime}
                                >
                                    <MenuItem value="12">Booking har ikke tid</MenuItem>
                                    <MenuItem value="11">Kunden vil ikke svare</MenuItem>
                                    <MenuItem value="10">Husker ikke</MenuItem>
                                    <MenuItem value="1">1900-1940</MenuItem>
                                    <MenuItem value="2">1940-1950</MenuItem>
                                    <MenuItem value="3">1950-1960</MenuItem>
                                    <MenuItem value="4">1960-1970</MenuItem>
                                    <MenuItem value="5">1970-1980</MenuItem>
                                    <MenuItem value="6">1980-1990</MenuItem>
                                    <MenuItem value="7">1990-2000</MenuItem>
                                    <MenuItem value="8">2000-2010</MenuItem>
                                    <MenuItem value="9">2010-2020</MenuItem>
                                </Select>
                            </FormControl> */}

                            {/* <FormControl style={{ marginBottom: 10, width: '33%', marginLeft: 5 }}>
                                <InputLabel>Oppussing</InputLabel>
                                <Select
                                    name="oppussingdate"
                                    onChange={this.dataChangedHandler}
                                    value={this.state.oppussingdate}
                                    disabled={this.state.internalTime}
                                >
                                    <MenuItem value="12">Booking har ikke tid</MenuItem>
                                    <MenuItem value="11">Kunden vil ikke svare</MenuItem>
                                    <MenuItem value="10">Husker ikke</MenuItem>
                                    <MenuItem value="1">1 år siden</MenuItem>
                                    <MenuItem value="2">2 år siden</MenuItem>
                                    <MenuItem value="3">3 år siden</MenuItem>
                                    <MenuItem value="4">4 år siden</MenuItem>
                                    <MenuItem value="5">5-10 år siden</MenuItem>
                                    <MenuItem value="6">10-15 år siden</MenuItem>
                                    <MenuItem value="7">15-20 år siden</MenuItem>
                                    <MenuItem value="8">20-30 år siden</MenuItem>
                                    <MenuItem value="9">Mer enn 30 år siden</MenuItem>
                                </Select>
                            </FormControl> */}

                            {/* <FormControl style={{ marginBottom: 10, width: '49%', marginLeft: 5 }}>
                                <InputLabel>Utført</InputLabel>
                                <Select
                                    name="oppussingjob"
                                    onChange={this.dataChangedHandler}
                                    value={this.state.oppussingjob}
                                    disabled={this.state.internalTime}
                                >
                                    <MenuItem value="7">Booking har ikke tid</MenuItem>
                                    <MenuItem value="6">Kunden vil ikke svare</MenuItem>
                                    <MenuItem value="5">Husker ikke</MenuItem>
                                    <MenuItem value="2">Mindre elektrisk arbeid</MenuItem>
                                    <MenuItem value="3">Mellomstort elektrisk arbeid</MenuItem>
                                    <MenuItem value="4">Stort elektrisk arbeid</MenuItem>
                                    <MenuItem value="1">Sikringsskap</MenuItem>
                                </Select>
                            </FormControl> */}

                            {/* <FormControl style={{ marginBottom: 10, width: '49%', marginLeft: 5 }}>
                                <InputLabel>Status Sikringsskap</InputLabel>
                                <Select
                                    name="statusSikring"
                                    onChange={this.dataChangedHandler}
                                    value={this.state.statusSikring}
                                    disabled={this.state.internalTime}
                                >
                                    <MenuItem value="5">Booking har ikke tid</MenuItem>
                                    <MenuItem value="4">Kunden vil ikke svare</MenuItem>
                                    <MenuItem value="3">Husker ikke</MenuItem>
                                    <MenuItem value="2">Skrusikringer</MenuItem>
                                    <MenuItem value="1">Automatsikringer</MenuItem>
                                </Select>
                            </FormControl> */}

                            <FormControl style={{ marginBottom: 10, width: '99%', marginLeft: 5 }}>
                                <FormControlLabel
                                    control={<Checkbox checked={receiveSMS || false} onChange={this.smsAndEmailCheckedChangedHandler} name="receiveSMS" />}
                                    label="Kunden ønsker å motta SMS/Epost med informasjon om gode tilbud, etc."
                                    disabled={this.state.internalTime}
                                />
                            </FormControl>
                            
                        </article>



                        <footer>
                            <Button style={{ background: '#EF8157', color: '#fff' }} onClick={this.props.closePopup}>Avbryt</Button>
                            <Button style={{ background: '#6BD098', color: '#fff' }} onClick={this.validateJobData}>Lagre</Button>
                        </footer>
                    </div>
                </div >
            </>
        );
    }
} // eo function render

export default Popup;

// eof
