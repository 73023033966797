import React from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
import Loader from "../components/Loader/Loader";

// reactstrap components
import {
  //Badge,
  //Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  //Label,
  //FormGroup,
  //Input,
  //Table,
  Row,
  Col,
  //UncontrolledTooltip
} from "reactstrap";


const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const yAxes = [
  {
    ticks: {
      fontColor: "#9f9f9f",
      beginAtZero: false,
      maxTicksLimit: 8,
      //padding: 20
    },
    gridLines: {
      drawBorder: false,
      zeroLineColor: "transparent",
      color: "#F4F4F4"
    }
  }
]

const xAxes = [
  {
    barPercentage: 1.6,
    gridLines: {
      drawBorder: false,
      color: "rgba(255,255,255,0.1)",
      zeroLineColor: "transparent",
      display: false
    },
    ticks: {
      padding: 20,
      fontColor: "#9f9f9f"
    }
  }
]

// ##############################
// // // Charts 
// #############################

const allChartInvoicedYTD = {
  data: {
    labels: months,
    datasets: [
      {
        label: "Budsjett",
        borderColor: "#FBC658",
        pointRadius: 2,
        pointHoverRadius: 4,
        fill: false,
        borderWidth: 3,
        data: []
      },
      {
        label: "I Fjor",
        borderColor: "#6BD098",
        pointRadius: 2,
        pointHoverRadius: 4,
        fill: false,
        borderWidth: 3,
        data: []
      },
      {
        label: "I År",
        borderColor: "#151413",
        pointRadius: 2,
        pointHoverRadius: 4,
        fill: true,
        borderWidth: 3,
        data: []
      },
    ]
  },
  options: {
    legend: {
      display: true
    },

    tooltips: {
      enabled: true
    },

    scales: {
      yAxes,
      xAxes,
    }
  }
};

const allChartJobsYTD = {
  data: {
    labels: months,
    datasets: [
      {
        label: "Budsjett",
        borderColor: "#FBC658",
        pointRadius: 2,
        pointHoverRadius: 4,
        fill: false,
        borderWidth: 3,
        data: []
      },
      {
        label: "I Fjor",
        borderColor: "#6BD098",
        pointRadius: 2,
        pointHoverRadius: 4,
        fill: false,
        borderWidth: 3,
        data: []
      },
      {
        label: "I År",
        borderColor: "#151413",
        pointRadius: 2,
        pointHoverRadius: 4,
        fill: true,
        borderWidth: 3,
        data: []
      },
    ]
  },
  options: {
    legend: {
      display: true
    },

    tooltips: {
      enabled: true
    },

    scales: {
      yAxes,
      xAxes,
    }
  }
};

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      boligelektrikerenLastYearArray: [],
      boligelektrikerenThisYearArray: [],
      bookingsArray: "",
      boligelektrikerenJobsArray: "",
      complaints: "",
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
  
    const fetchLastYear = fetch(global.config.server.connection.url + "/api/Dashboards/BoligelektrikerenLastYear", {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      }
    }).then(res => res.json());
  
    const fetchThisYear = fetch(global.config.server.connection.url + "/api/Dashboards/BoligelektrikerenThisYear", {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      }
    }).then(res => res.json());
  
    const fetchJobs = fetch(global.config.server.connection.url + "/api/Dashboards/BoligelektrikerenJobs", {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      }
    }).then(res => res.json());
  
    Promise.all([fetchLastYear, fetchThisYear, fetchJobs])
      .then(
        ([lastYearResult, thisYearResult, jobsResult]) => {
          this.setState({
            boligelektrikerenLastYearArray: lastYearResult,
            boligelektrikerenThisYearArray: thisYearResult,
            boligelektrikerenJobsArray: jobsResult,
            loading: false
          });
        }
      )
      .catch((error) => {
        this.setState({
          loading: false,
          error
        });
      });
  }
  

  render() {
    const { loading, boligelektrikerenLastYearArray, boligelektrikerenThisYearArray, boligelektrikerenJobsArray, error } = this.state;

    if (loading) {
      return <Loader />
    }





    allChartInvoicedYTD.data.datasets[0].data = [8796000, 9284000, 8552000, 9650000, 9650000, 10260000, 9650000, 10504000, 10504000, 12090000, 10565000, 8186000];   //budget
    allChartInvoicedYTD.data.datasets[1].data = boligelektrikerenLastYearArray;
    allChartInvoicedYTD.data.datasets[2].data = boligelektrikerenThisYearArray;


    allChartJobsYTD.data.datasets[0].data = [680, 720, 660, 750, 750, 800, 750, 820, 820, 950, 825, 630];   //budget jobber

    allChartJobsYTD.data.datasets[1].data =
      [boligelektrikerenJobsArray[0][0],
      boligelektrikerenJobsArray[1][0],
      boligelektrikerenJobsArray[2][0],
      boligelektrikerenJobsArray[3][0],
      boligelektrikerenJobsArray[4][0],
      boligelektrikerenJobsArray[5][0],
      boligelektrikerenJobsArray[6][0],
      boligelektrikerenJobsArray[7][0],
      boligelektrikerenJobsArray[8][0],
      boligelektrikerenJobsArray[9][0],
      boligelektrikerenJobsArray[10][0],
      boligelektrikerenJobsArray[11][0]];
      
    allChartJobsYTD.data.datasets[2].data =
      [boligelektrikerenJobsArray[0][1],
      boligelektrikerenJobsArray[1][1],
      boligelektrikerenJobsArray[2][1],
      boligelektrikerenJobsArray[3][1],
      boligelektrikerenJobsArray[4][1],
      boligelektrikerenJobsArray[5][1],
      boligelektrikerenJobsArray[6][1],
      boligelektrikerenJobsArray[7][1],
      boligelektrikerenJobsArray[8][1],
      boligelektrikerenJobsArray[9][1],
      boligelektrikerenJobsArray[10][1],
      boligelektrikerenJobsArray[11][1]];

    return (
      <>

        <div className="content">



          {/*
        ##############################
         Charts - Invoiced Compared 
        ############################# 
        */}

          <Row>
            <Col lg="12" sm="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <div className="numbers pull-left">Fakturert - Sammenlignet</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h6 className="big-title">
                    fakturert ytd sammenlignet
                  </h6>
                  <Line
                    data={allChartInvoicedYTD.data}
                    options={allChartInvoicedYTD.options}
                    height={300}
                    width={826}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                </CardFooter>
              </Card>
            </Col>
          </Row>

          {/*##############################
         Charts - Jobs Compared 
        ############################# */}

          <Row>
            <Col lg="12" sm="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <div className="numbers pull-left">Jobber - Sammenlignet</div>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h6 className="big-title">
                    jobber ytd alle selskaper
                  </h6>
                  <Line
                    data={allChartJobsYTD.data}
                    options={allChartJobsYTD.options}
                    height={300}
                    width={826}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
