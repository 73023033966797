import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

class UserProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      isProfileLoaded: false,
      items: [],
      employeeProfile: null,
      employeeCollegaues: null
    };
  }

  componentDidMount() {
    console.log(sessionStorage.getItem("access_token"))

    // fetch("https://localhost:44347/api/Employee/EmployeeProfile", {
    fetch(global.config.server.connection.url + "/api/Employee/EmployeeProfile", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            employeeProfile: result,
            isProfileLoaded: true
          });
          //console.log(this.state.employeeProfile)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isProfileLoaded: true,
            error
          });
        }
      )
    // fetch("https://localhost:44347/api/Employee/EmployeeCollegaues", {
    fetch(global.config.server.connection.url + "/api/Employee/EmployeeCollegaues", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            employeeCollegaues: result
          });
          console.log(this.state.employeeCollegaues)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render() {

    const { error, isLoaded, isProfileLoaded, employeeCollegaues, employeeProfile } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded || !isProfileLoaded) {
      return (
        <div className="content">
          <Row>
            <Col md="4">
            </Col>
            <Col md="4">
              <p>Please wait...</p>
              <div className="lds-ripple"><div></div><div></div></div>
            </Col>
            <Col md="4">
            </Col>
          </Row>
        </div>
      )
    } else {

      return (
        <>
          <div className="content">
            <Row>
              <Col md="4">
                <Card className="card-user">
                  <div className="image">
                    <img
                      alt="..."
                      src={require("assets/img/bg/rokea-profile.jpg")}
                    />
                  </div>
                  <CardBody>
                    <div className="author">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="avatar border-gray"
                          src={"data:image/png;base64, " + employeeProfile.userImage}
                        />
                        <h5 className="title"> {employeeProfile.firstName} {employeeProfile.lastName}</h5>
                      </a>
                      <p className="description">@{employeeProfile.userName}</p>
                    </div>
                    {/* <p className="description text-center">
                      there are 10 types of people in this world,
                      those who understand binary and those who dont
                    </p> */}
                  </CardBody>
                  <CardFooter>
                    <hr />
                    {/* <div className="button-container">
                      <Row>
                        <Col className="ml-auto" lg="3" md="6" xs="6">
                          <h5>
                            12 <br />
                            <small>Jobs Done</small>
                          </h5>
                        </Col>
                        <Col className="ml-auto mr-auto" lg="4" md="6" xs="6">
                          <h5>
                            221.212,- <br />
                            <small>Turonover</small>
                          </h5>
                        </Col>
                      </Row>
                    </div> */}
                  </CardFooter>
                </Card>
              </Col>
              <Col md="8">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Profile</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col className="pr-1" md="5">
                          <FormGroup>
                            <label>Group</label>
                            <Input
                              defaultValue={employeeProfile.group}
                              disabled
                              placeholder="Group"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="px-1" md="3">
                          <FormGroup>
                            <label>Company</label>
                            <Input
                              defaultValue={employeeProfile.company}
                              disabled
                              placeholder="Company"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pl-1" md="4">
                          <FormGroup>
                            <label>Department</label>
                            <Input
                              defaultValue={employeeProfile.department}
                              disabled
                              placeholder="Department"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>Username</label>
                            <Input
                              defaultValue={employeeProfile.userName}
                              disabled
                              placeholder="Username"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label htmlFor="exampleInputEmail1">
                              Email address
                            </label>
                            <Input defaultValue={employeeProfile.email}
                              disabled
                              placeholder="Email"
                              type="email" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-1" md="6">
                          <FormGroup>
                            <label>First Name</label>
                            <Input
                              defaultValue={employeeProfile.userName}
                              disabled
                              placeholder="Company"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pl-1" md="6">
                          <FormGroup>
                            <label>Last Name</label>
                            <Input
                              defaultValue={employeeProfile.lastName}
                              disabled
                              placeholder="Last Name"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Address</label>
                            <Input
                              defaultValue="Markveien 42a"
                              placeholder="Home Address"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row> */}
                      {/* <Row>
                        <Col className="pr-1" md="4">
                          <FormGroup>
                            <label>City</label>
                            <Input
                              defaultValue="Oslo"
                              placeholder="City"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="px-1" md="4">
                          <FormGroup>
                            <label>Country</label>
                            <Input
                              defaultValue="Norge"
                              placeholder="Country"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col className="pl-1" md="4">
                          <FormGroup>
                            <label>Postal Code</label>
                            <Input placeholder="ZIP Code" type="number" />
                          </FormGroup>
                        </Col>
                      </Row> */}
                      {/* <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>About Me</label>
                            <Input
                              className="textarea"
                              type="textarea"
                              cols="80"
                              rows="4"
                              defaultValue="Oh so, your weak rhyme You doubt I'll bother,
                            reading into it"
                            />
                          </FormGroup>
                        </Col>
                      </Row> */}
                    </Form>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Team Members</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <ul className="list-unstyled team-members">
                      {employeeCollegaues.map(collegaue => (
                        <li key={collegaue.firstName}>
                          <Row>
                            <Col md="2" xs="2">
                              <div className="avatar">
                                <img
                                  alt="..."
                                  className="img-circle img-no-padding img-responsive"
                                  src={"data:image/png;base64, " + collegaue.userImage}
                                />

                              </div>
                            </Col>
                            <Col md="7" xs="7">
                              {collegaue.firstName} {collegaue.lastName} <br />
                              <span className="text-muted">
                                <small>{collegaue.company}  {collegaue.department}</small>
                              </span>
                            </Col>
                            <Col className="text-right" md="3" xs="3">
                              <Button
                                className="btn-round btn-icon"
                                color="success"
                                outline
                                size="sm"
                              >
                                <i className="fa fa-envelope" />
                              </Button>
                            </Col>
                          </Row>
                        </li>
                      ))}
                    </ul>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
}

export default UserProfile;
