import React from "react";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import Loader from "../components/Loader/Loader";
import chartCustomerSurvey from '../Dashboards/Charts/CustomerSurvey';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import CustomerCampaignAnswers from "../components/Custom/CustomerCampaignAnswers";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
} from "reactstrap";

const current = new Date();
const thisMonth = current.toLocaleString('no-nb', { month: 'long' });
current.setMonth(current.getMonth() - 1);
const previousMonth = current.toLocaleString('no-nb', { month: 'long' });
current.setMonth(current.getMonth() - 1);
const prevPrevMonth = current.toLocaleString('no-nb', { month: 'long' });

const chartCustomerSurveyThreeMonths = {
    data: {
        labels: [
            prevPrevMonth,
            previousMonth,
            thisMonth,
        ],
        datasets: [
            {
                label: "Boligelektrikeren",
                borderColor: "#fcc468",
                pointRadius: 3,
                pointHoverRadius: 3,
                fill: false,
                borderWidth: 3,
                data: [],
                backgroundColor: "#fcc468",
                hoverBorderColor: "#fcc468",
            },
        ]
    },
    options: {
        legend: {
            display: true
        },

        tooltips: {
            enabled: true
        },

        scales: {
            yAxes: [
                {
                    ticks: {
                        fontColor: "#9f9f9f",
                        beginAtZero: false,
                        maxTicksLimit: 8,
                        //padding: 20
                    },
                    gridLines: {
                        drawBorder: false,
                        zeroLineColor: "transparent",
                        color: "rgba(255,255,255,0.05)"
                    }
                }
            ],

            xAxes: [
                {
                    barPercentage: 1.6,
                    gridLines: {
                        drawBorder: false,
                        color: "rgba(255,255,255,0.1)",
                        zeroLineColor: "transparent",
                        display: false
                    },
                    ticks: {
                        padding: 20,
                        fontColor: "#9f9f9f"
                    }
                }
            ]
        }
    }
};

const chartCustomerSurveyThreeMonthsPrTeam = {
    data: {
        labels: [
            prevPrevMonth,
            previousMonth,
            thisMonth,
        ],
        datasets: [
            {
                label: "Team Andreas",
                borderColor: "#fcc468",
                pointRadius: 3,
                pointHoverRadius: 3,
                fill: false,
                borderWidth: 3,
                data: [],
                backgroundColor: "#fcc468",
                hoverBorderColor: "#fcc468",
            },
            {
                label: "Team Anders",
                borderColor: "#4cbdd7",
                pointRadius: 3,
                pointHoverRadius: 3,
                fill: false,
                borderWidth: 3,
                data: [],
                backgroundColor: "#4cbdd7",
                hoverBorderColor: "#4cbdd7",
            },
            {
                label: "Team Daniel",
                borderColor: "#EF8157",
                pointRadius: 3,
                pointHoverRadius: 3,
                fill: false,
                borderWidth: 3,
                data: [],
                backgroundColor: "#EF8157",
                hoverBorderColor: "#EF8157",
            },
        ]
    },
    options: {
        legend: {
            display: true
        },

        tooltips: {
            enabled: true
        },

        scales: {
            yAxes: [
                {
                    ticks: {
                        fontColor: "#9f9f9f",
                        beginAtZero: false,
                        maxTicksLimit: 8,
                        //padding: 20
                    },
                    gridLines: {
                        drawBorder: false,
                        zeroLineColor: "transparent",
                        color: "rgba(255,255,255,0.05)"
                    }
                }
            ],

            xAxes: [
                {
                    barPercentage: 1.6,
                    gridLines: {
                        drawBorder: false,
                        color: "rgba(255,255,255,0.1)",
                        zeroLineColor: "transparent",
                        display: false
                    },
                    ticks: {
                        padding: 20,
                        fontColor: "#9f9f9f"
                    }
                }
            ]
        }
    }
};
class CustomerCampaigns extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSurveyAnswersReady: false,
            isSurveyAnswersThreeMonthsReady: true,
            isLastWeekReady: false,
            isLastMonthReady: false,
            isLastThreeMonthsReady: false,
            isTopListReady: false,
            isBottomListReady: false,
            isAllTimesReady: false,

            bottomList: null,
            weekTopList: null,
            lastThreeMonths: null,
            lastWeekCount: null,
            lastMonthCount: null,
            allTimesCount: null,
            surveyResultsArray: [],
            surveyLastYearResultsArray: [],
            selectedPeriod: 1,
            selectedPeriodPrTeam: 1,
        };

        this.selectedPeriodChange = this.selectedPeriodChange.bind(this)

    }

    async componentDidMount() {
        var today = new Date();
        fetch(global.config.server.connection.url + "/api/CustomerSurvey/GetCustomerSurveyAnswers?fromDate=" + new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).toISOString(), {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isSurveyAnswersReady: true,
                        surveyResultsArray: result.map((prop, key) => {
                            return {
                                id: key,
                                question: prop["question"],
                                answer: prop["answer"]
                            }
                        })
                    });
                },
            )

        // fetch(global.config.server.connection.url + "/api/CustomerSurvey/GetCustomerSurveyAnswersPrTeam?fromDate=" + new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).toISOString(), {
        //     method: "GET",
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
        //     },
        // })
        //     .then(res => res.json())
        //     .then(
        //         (result) => {
        //             this.setState({
        //                 isSurveyAnswersReady: true,
        //                 surveyResultsArrayPrTeam: result.map((prop, key) => {
        //                     return {
        //                         id: key,
        //                         companyName: prop["companyName"],
        //                         nextProp: prop["cq"]
        //                     }
        //                 })
        //             });
        //         },
        //     )


        fetch(global.config.server.connection.url + "/api/CustomerSurvey/LastWeekCount", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLastWeekReady: true,
                        lastWeekCount: result
                    });
                },
            )


        fetch(global.config.server.connection.url + "/api/CustomerSurvey/LastMonthCount", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLastMonthReady: true,
                        lastMonthCount: result
                    });
                },
            )

        fetch(global.config.server.connection.url + "/api/CustomerSurvey/AllTimesCount", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isAllTimesReady: true,
                        allTimesCount: result
                    });
                },
            )

        await fetch(global.config.server.connection.url + "/api/CustomerSurvey/TopList?days=7", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        weekTopList: result,
                        isTopListReady: true,
                    });
                },
            )

        await fetch(global.config.server.connection.url + "/api/CustomerSurvey/BottomList?days=7", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        weekBottomList: result,
                        isBottomListReady: true,
                    });
                },
            )



        await fetch(global.config.server.connection.url + "/api/CustomerSurvey/lastThreeMonthsPrTeam", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    var elektrisTemp = []
                    var fixelTemp = []
                    var elmesterenTemp = []

                    elektrisTemp.push(result.filter(customerSurvey => customerSurvey.team.trim() === '1' && customerSurvey.MonthName === prevPrevMonth).reduce((a, v) => a = a + v.AvgAnswer, 0))
                    elektrisTemp.push(result.filter(customerSurvey => customerSurvey.team.trim() === '1' && customerSurvey.MonthName === previousMonth).reduce((a, v) => a = a + v.AvgAnswer, 0))
                    elektrisTemp.push(result.filter(customerSurvey => customerSurvey.team.trim() === '1' && customerSurvey.MonthName === thisMonth).reduce((a, v) => a = a + v.AvgAnswer, 0))

                    fixelTemp.push(result.filter(customerSurvey => customerSurvey.team.trim() === '2' && customerSurvey.MonthName === prevPrevMonth).reduce((a, v) => a = a + v.AvgAnswer, 0))
                    fixelTemp.push(result.filter(customerSurvey => customerSurvey.team.trim() === '2' && customerSurvey.MonthName === previousMonth).reduce((a, v) => a = a + v.AvgAnswer, 0))
                    fixelTemp.push(result.filter(customerSurvey => customerSurvey.team.trim() === '2' && customerSurvey.MonthName === thisMonth).reduce((a, v) => a = a + v.AvgAnswer, 0))

                    elmesterenTemp.push(result.filter(customerSurvey => customerSurvey.team.trim() === '3' && customerSurvey.MonthName === prevPrevMonth).reduce((a, v) => a = a + v.AvgAnswer, 0))
                    elmesterenTemp.push(result.filter(customerSurvey => customerSurvey.team.trim() === '3' && customerSurvey.MonthName === previousMonth).reduce((a, v) => a = a + v.AvgAnswer, 0))
                    elmesterenTemp.push(result.filter(customerSurvey => customerSurvey.team.trim() === '3' && customerSurvey.MonthName === thisMonth).reduce((a, v) => a = a + v.AvgAnswer, 0))


                    this.setState({
                        elektrisThreeMonthsArray: elektrisTemp,
                        fixelThreeMonthsArray: fixelTemp,
                        elmesterenThreeMonthsArray: elmesterenTemp,
                        isLastThreeMonthsReady: true,
                        lastThreeMonthsPrTeamArray: result.map((prop, key) => {
                            return {
                                id: key,
                                MonthName: prop["MonthName"],
                                QuestionId: prop["QuestionId"],
                                AvgAnswer: prop["AvgAnswer"]
                            }
                        })
                    });
                },
            )

        await fetch(global.config.server.connection.url + "/api/CustomerSurvey/lastThreeMonths", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    var boligelektrikerenTemp = []
                    boligelektrikerenTemp.push(result.filter(customerSurvey => customerSurvey.MonthName === prevPrevMonth).reduce((a, v) => a = a + v.AvgAnswer, 0))
                    boligelektrikerenTemp.push(result.filter(customerSurvey => customerSurvey.MonthName === previousMonth).reduce((a, v) => a = a + v.AvgAnswer, 0))
                    boligelektrikerenTemp.push(result.filter(customerSurvey => customerSurvey.MonthName === thisMonth).reduce((a, v) => a = a + v.AvgAnswer, 0))

                    this.setState({
                        lastThreeMonthsArray: boligelektrikerenTemp,
                        isLastThreeMonthsPrTeamReady: true,
                        lastThreeMonthsPrTeam: result.map((prop, key) => {
                            return {
                                id: key,
                                MonthName: prop["MonthName"],
                                QuestionId: prop["QuestionId"],
                                AvgAnswer: prop["AvgAnswer"]
                            }
                        })
                    });
                },
            )

    }

    selectedPeriodChange = (event) => {
        var today = new Date();
        var anotherDate;

        if (event.target.value === 1) //last week
            anotherDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).toISOString()
        if (event.target.value === 2) //last month
            anotherDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30).toISOString()
        if (event.target.value === 3) //last three months
            anotherDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 90).toISOString()
        if (event.target.value === 4) //all times
            anotherDate = new Date(today.getFullYear() - 10, today.getMonth(), today.getDate()).toISOString()

        fetch(global.config.server.connection.url + "/api/CustomerSurvey/GetCustomerSurveyAnswers?fromDate=" + anotherDate, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isSurveyAnswersReady: true,
                        selectedPeriod: event.target.value,
                        surveyResultsArray: result.map((prop, key) => {
                            return {
                                id: key,
                                question: prop["question"],
                                answer: prop["answer"]
                            }
                        })
                    });
                },
            )
    };


    render() {
        const { isLastWeekReady, isLastMonthReady, isSurveyAnswersThreeMonthsReady, isLastThreeMonthsReady, isLastThreeMonthsPrTeamReady, isTopListReady, isBottomListReady } = this.state;

        if (!isSurveyAnswersThreeMonthsReady || !isLastWeekReady || !isLastMonthReady || !isLastThreeMonthsReady || !isLastThreeMonthsPrTeamReady || !isTopListReady || !isBottomListReady) {
            return <Loader />
        }

        //Anbefale
        chartCustomerSurvey.data.datasets[0].data = [this.state.surveyResultsArray[0].answer]
        //Effektivitet
        chartCustomerSurvey.data.datasets[1].data = [this.state.surveyResultsArray[1].answer]
        //Gjenbruk
        chartCustomerSurvey.data.datasets[2].data = [this.state.surveyResultsArray[2].answer]
        //Kvalitet
        chartCustomerSurvey.data.datasets[3].data = [this.state.surveyResultsArray[3].answer]
        //Påliteligheht
        chartCustomerSurvey.data.datasets[4].data = [this.state.surveyResultsArray[4].answer]

        chartCustomerSurvey.data.datasets[5].data = [this.state.surveyResultsArray[5].answer]

        chartCustomerSurvey.data.datasets[6].data = [this.state.surveyResultsArray[6].answer]

      
        chartCustomerSurveyThreeMonths.data.datasets[0].data = [this.state.lastThreeMonthsArray[0], this.state.lastThreeMonthsArray[1], this.state.lastThreeMonthsArray[2]]


        chartCustomerSurveyThreeMonthsPrTeam.data.datasets[0].data = [this.state.elektrisThreeMonthsArray[0], this.state.elektrisThreeMonthsArray[1], this.state.elektrisThreeMonthsArray[2]]
        chartCustomerSurveyThreeMonthsPrTeam.data.datasets[1].data = [this.state.fixelThreeMonthsArray[0], this.state.fixelThreeMonthsArray[1], this.state.fixelThreeMonthsArray[2]]
        chartCustomerSurveyThreeMonthsPrTeam.data.datasets[2].data = [this.state.elmesterenThreeMonthsArray[0], this.state.elmesterenThreeMonthsArray[1], this.state.elmesterenThreeMonthsArray[2]]


        const aa1JobLink = "http://intranet-aa1-alb-1434599581.eu-west-1.elb.amazonaws.com/booking/htdocs/adm/index.php?action=editjob&jobID=";
        const aa1CustomerLink = "http://intranet-aa1-alb-1434599581.eu-west-1.elb.amazonaws.com/booking/htdocs/adm/index.php?action=editcompanypopup&companyId="
        const elektrisJobLink = "http://intranet-elektris-alb-1863918207.eu-west-1.elb.amazonaws.com/booking/htdocs/adm/index.php?action=editjob&jobID=";
        const elektrisCustomerLink = "http://intranet-elektris-alb-1863918207.eu-west-1.elb.amazonaws.com/booking/htdocs/adm/index.php?action=editcompanypopup&companyId="

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="4">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-chart-bar-32 text-warning" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Siste 7 dager</p>
                                                <CardTitle tag="p">
                                                    {this.state.lastWeekCount}
                                                </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-calendar" />Besvarelser siste 7 dager
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col md="4">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-chart-bar-32 text-warning" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Siste 30 dager</p>
                                                <CardTitle tag="p">
                                                    {this.state.lastMonthCount}
                                                </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-calendar" />Besvarelser siste 30 dager
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-chart-bar-32 text-warning" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Siden oppstart</p>
                                                <CardTitle tag="p">
                                                    {this.state.allTimesCount}
                                                </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <hr />
                                    <div className="stats">
                                        <i className="fa fa-calendar" />Totalt antall besvarelser
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <CustomerCampaignAnswers />
                        </Col>
                    </Row>

                   

                </div>
            </>
        );
    }
}

export default CustomerCampaigns;
