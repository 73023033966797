import React from "react";
import StatsBar from "../../components/StatsBar/StatsBar";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Row,
    Col
} from "reactstrap";

class Welcome extends React.Component {

    render() {
        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <center>
                                    <CardTitle tag="h4">Velkommen Rokea Intranett</CardTitle>
                                </center>
                            </CardHeader>

                            <CardBody>
                                <b>Boligelektrikeren</b>
                                <StatsBar company={"boligelektrikeren"} />
                                <br></br>
                                <b>Boligrørleggeren</b>
                                <StatsBar company={"boligrorleggeren"} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )

    }
}


export default Welcome;
