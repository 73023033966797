import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { Row } from "reactstrap";


class GoogleHeatMap extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lat: 59.82855,
      lng: 10.758097,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    };
    this.map = React.createRef();
    this.onMarkerClick = this.onMarkerClick.bind(this);
  }


  async componentDidMount() {


    this.setState({
      markerArray: this.props.apidata.map(marker => (

        // <MarkerWithLabel
        //   labelStyle={{
        //     textAlign: "center",
        //     width: 200 + "px",
        //     backgroundColor: "#7ff0c4",
        //     fontSize: "14px",
        //     padding: 8 + "px"
        //   }}
        //   labelClass="map-label"
        //   labelAnchor={{ x: 220 / 2 + 8, y: 80 }}
        //   key={Math.random()}
        //   position={{ lat: parseInt(marker.lat), lng: parseInt(marker.lng) }}
        // >
        //   <span>Mattis</span>
        // </MarkerWithLabel>

        <Marker
          key={Math.random()}
          title={marker.jobTitle}
          name={marker.lat}
          jobTitle={marker.jobTitle}
          jobDescription={marker.jobDescription}
          jobStart={marker.jobStart.substr(0, 5)}
          companyName={marker.companyName}
          contactMobileNumber={marker.contactMobileNumber}
          employeeUserName={marker.employeeUserName}
          position={{ lat: marker.lat, lng: marker.lng }}
          onClick={this.onMarkerClick}
        />

      )),
    });
  }

  componentDidUpdate(prevProps) {

    if (prevProps.apidata !== this.props.apidata) {
      this.setState({
        markerArray: this.props.apidata.map(marker => (
          <Marker
            key={Math.random()}
            title={marker.jobTitle}
            name={marker.lat}
            jobTitle={marker.jobTitle}
            jobDescription={marker.jobDescription}
            jobStart={marker.jobStart.substr(0, 5)}
            companyName={marker.companyName}
            contactMobileNumber={marker.contactMobileNumber}
            employeeUserName={marker.employeeUserName}
            position={{ lat: marker.lat, lng: marker.lng }}
            onClick={this.onMarkerClick}
          />
        )),
      });
    }
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  render() {

    const mystyle = {
      color: "black",
      backgroundColor: "white",
      padding: "14px",
    };

    return (
      <>
        <div className="content">
          <Row>
            <Map
              ref={(ref) => {
                this.map = ref;
              }}
              google={this.props.google}
              className={"map"}
              zoom={10}
              center={{
                lat: this.state.lat,
                lng: this.state.lng
              }}>

              {this.state.markerArray}

              <InfoWindow
                marker={this.state.activeMarker}
                visible={this.state.showingInfoWindow}>
                <div style={mystyle}>
                  <h6>{this.state.selectedPlace.jobTitle}</h6><br></br>
                  <p><b>Tid:</b> {this.state.selectedPlace.jobStart}</p>
                  <p><b>Beskrivelse:</b> {this.state.selectedPlace.jobDescription}</p>
                  <p><b>Kunde:</b> {this.state.selectedPlace.companyName}</p>
                  <p><b>Telefon:</b> {this.state.selectedPlace.contactMobileNumber}</p>
                  <p><b>Montør:</b> {this.state.selectedPlace.employeeUserName}</p>
                </div>
              </InfoWindow>

            </Map>
          </Row>
        </div>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDt2sNgcWjSO3UkUo0I73BD6017Y2RCX9g',
  libraries: ["visualization"]
})(GoogleHeatMap)