import React from "react";
import Loader from "components/Loader/Loader";
import ReactBSAlert from "react-bootstrap-sweetalert";

//Scheduler stuff
import BryntumScheduler from './BryntumScheduler';
import 'bryntum-scheduler/scheduler.stockholm.css';
import { LocaleManager, LocaleHelper } from 'bryntum-scheduler/scheduler.umd';
import parentLocale from 'bryntum-scheduler/locales/scheduler.locale.En';


//import { Scheduler, DateHelper, DomClassList, EventModel } from 'bryntum-scheduler';
//import { EventResize } from "bryntum-scheduler/scheduler.umd.js";

class Booking extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loadingJobs: false,
      employeeArray: [],
      selectedEmployeesArray: [],
      jobsArray: [],
      eventsArray: [],
      eventsVersion: 1,
      resourcesVersion: 1,
      selectedCompany: "Alle",
      startDay: "",
      endDay: "",
      selectedDate: "",
      alert: null,
    };
  }

  componentDidMount() {

    this.setState({
      loading: true,
      loadingJobs: true,
    });

    //Set initial start and end day/ time
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.setState({
      startDay: new Date(yyyy, mm - 1, dd, 7),
      endDay: new Date(yyyy, mm - 1, dd, 20),
    });

    var date = new Date();
    dd = String(date.getDate()).padStart(2, '0');
    mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    yyyy = date.getFullYear();
    date = yyyy + '/' + mm + '/' + dd;

    const locale = LocaleHelper.mergeLocales(parentLocale, {
      // your localization strings here
      DateHelper: {
        locale: 'da',
      }
    });
    // "da" - is unified name for Danish localization
    LocaleManager.registerLocale('da', { desc: 'Danish locale', locale: locale });
    // and later when you need:
    LocaleManager.applyLocale('da');

    //rename text on Edit Event context menu
    LocaleManager.locale.EventEdit['Edit event'] = 'Endre booking';

      this.loadEmployees();
      this.loadJobs(date);
  }

  async loadEmployees(){
     await fetch(global.config.server.connection.url + "/api/Employee/AmazonEmployeesBooking", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      },
    })
      .then(res => res.json())
      .then(
        (result) => {          
          this.setState({
            loading: false,
            employeeArray: result,
            selectedEmployeesArray: result,
            fixelArray: result.filter(employee => employee.team === 1),
            elmesterenArray: result.filter(employee => employee.team === 2),
            elfiksernArray: result.filter(employee => employee.company === 3),
          });
        },
        (error) => {
          this.setState({
            loading: false,
            error
          });
        }
      )
  }

  async loadJobs(date){
    await fetch(global.config.server.connection.url + "/api/Booking/GetBookings?startDateTime=" + date, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            loadingJobs: false,
            jobsArray: result,
            selectedDate: date,
          });
        },
        (error) => {
          this.setState({
            loading: false,
            error
          });
        }
      )
  }

  updateJobs = (event) => {
    fetch(global.config.server.connection.url + "/api/Booking/GetBookings?startDateTime=" + this.state.selectedDate, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            loadingJobs: false,
            jobsArray: result,
            eventsVersion: Math.random(),
          });
          this.refs.scheduler.schedulerEngine.restartInitialAnimation("zoom-in")
          this.props.notifyJobUpdated()
        },
        (error) => {
          this.setState({
            loading: false,
            error
          });
        }
      )
  }

  companyChange = (event) => {
    if (event === "Alle") {
      this.setState({
        selectedEmployeesArray: this.state.employeeArray,
        eventsVersion: Math.random(),
        resourcesVersion: Math.random(),
        selectedCompany: "Alle selskap"
      });
    } else {
      var team;
      if (event === 'Team 1') { team = 1 }
      if (event === 'Team 2') { team = 2 }
      if (event === 'Team 3') { team = 3 }

      this.setState({
        selectedEmployeesArray: this.state.employeeArray.filter(employee => employee.team === team),
        eventsVersion: Math.random(),
        resourcesVersion: Math.random(),
        selectedCompany: event
      });
    }
    //this.refs.scheduler.schedulerEngine.restartInitialAnimation("zoom-in")
  }

  dateChange = (event) => {

    var date = new Date(this.state.selectedDate)

    if (event === "prev") {
      date = new Date(this.state.selectedDate)
      date.setDate(date.getDate() - 1);

      this.setDateText(date)

      var dd = String(date.getDate()).padStart(2, '0');
      var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = date.getFullYear();
      date = yyyy + '/' + mm + '/' + dd;

      this.setState({
        selectedDate: date
      })
    } else if (event === "next") {
      date = new Date(this.state.selectedDate)
      date.setDate(date.getDate() + 1);

      this.setDateText(date)

      dd = String(date.getDate()).padStart(2, '0');
      mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      yyyy = date.getFullYear();
      date = yyyy + '/' + mm + '/' + dd;

      this.setState({
        selectedDate: date
      })
    } else {
      this.setDateText(event)
      dd = String(event.getDate()).padStart(2, '0');
      mm = String(event.getMonth() + 1).padStart(2, '0'); //January is 0!
      yyyy = event.getFullYear();
      date = yyyy + '/' + mm + '/' + dd;
    }

    fetch(global.config.server.connection.url + "/api/Booking/GetBookings?startDateTime=" + date, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            loadingJobs: false,
            jobsArray: result,
            eventsVersion: Math.random(),
            resourcesVersion: Math.random(),
            startDay: new Date(yyyy, mm - 1, dd, 7),
            endDay: new Date(yyyy, mm - 1, dd, 24),
            selectedDate: date,
          });

          this.refs.scheduler.schedulerEngine.restartInitialAnimation("zoom-in")

        },
        (error) => {
          this.setState({
            loading: false,
            error
          });
        }
      )
  }

  setDateText(date) {
    var weekday = new Array(7);
    weekday[0] = "Søndag";
    weekday[1] = "Mandag";
    weekday[2] = "Tirsdag";
    weekday[3] = "Onsdag";
    weekday[4] = "Torsdag";
    weekday[5] = "Fredag";
    weekday[6] = "Lørdag";

    var selectedWeekday = weekday[date.getDay()]
    var selectedMonth = date.getMonth() + 1
    let element = document.getElementById("b-widget-2");
    if (element) element.innerHTML = "<div style='margin-left: 20px'><h6 style='color:rgb(239, 129, 87)'>VALGT DATO: " + selectedWeekday + " " + date.getDate() + "." + selectedMonth + "</h6></div>"
  }

  async onConfirmDeleteBooking(jobId) {
    
    this.setState({
      loading: true
    })

    try {
      
      //var response = await fetch("https://localhost:44347/api/Booking/DeleteBooking?jobId=" + jobId, {
      var response = await fetch(global.config.server.connection.url + "/api/Booking/DeleteBooking?jobId=" + jobId, {
        method: "DELETE",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
        },
      })
    } catch (ex) {
      this.setState({
        loading: false
      })
      this.errorAlert("Au da! Her er det rusk i maskineriet. Kontakt IT dersom problemet vedvarer")
      return;
    }

    if (response.ok) {
      this.updateJobs();

      this.setState({
        loading: false
      })
      
      this.successAlert("Jobben er slettet")
        
      return;
    }

    if (!response.ok) {
      this.setState({
        loading: false
      })
      this.errorAlert("Dette gikk desverre galt. " + response.message)
      return;
    }

    this.setState({
      alert: null,
      loading: false
    });
  };

  confirmAlert = (message, obj) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="Slette jobb?"
          onConfirm={() => this.onConfirmDeleteBooking(obj.jobId)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="info"
        >
          {message}
          <br></br><br></br>
          <span style={{ fontSize: '.9em', fontWeight: 'bold' }}>Detaljer:</span>
          <br></br>
          <span style={{ fontSize: '.9em'}}><i>JobbId: {obj.jobId}</i></span>
          <br></br>
          <span style={{ fontSize: '.9em'}}><i>Tittel: {obj.name}</i></span>
          <br></br>
          <span style={{ fontSize: '.9em'}}><i>Beskrivelse: {obj.jobDescription}</i></span>
        </ReactBSAlert>
      )
    });
  };

  successAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={message}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
        </ReactBSAlert>
      )
    });
  };

  errorAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="OPS!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {message}
        </ReactBSAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  render = () => {
    
    const { loading, loadingJobs, selectedEmployeesArray, eventsVersion, jobsArray, resourcesVersion, selectedDate } = this.state;

    if (loading || loadingJobs) {
      return (
        <div className="content">
          <Loader />
        </div>
      )
    }
    //href="${data.invoiceUrl}${data.invoiceID}" target="new" 
    //<p onClick=${this.toggleModalNotice.bind(this)()}} style = "color:white; float:left; margin-right:4px; font-size:15px;" aria-hidden="true" > ${ data.icon }</p >

    const schedulerConfig = {
      eventBodyTemplate: data => `
      <div style="float:left">
        <div class="b-sch-event-footer">${data.headerText}</div>
        <div class="b-sch-event-header">
          <a href="${data.invoiceUrl}${data.invoiceID}" target="new" style="color:white; float:left; margin-right:4px; font-size:15px;" aria-hidden="true">${data.icon}</a>
        </div>
        <div class="b-sch-event-header"><p style="font-size:10px">${data.footerText}</p></div>
        <div class="b-sch-event-header"><p style="font-size:13px">${data.totalSum}</p></div>
      </div>
      `
      ,

      eventRenderer({ eventRecord, resourceRecord, renderData }) {

        //set correct invoice url. 
        var url;
        if (eventRecord.resourceId.indexOf("Elektris") > 0)
          url = "http://intranet-elektris-alb-1863918207.eu-west-1.elb.amazonaws.com/intranet/index.php?option=com_fixel&view=fgrunnlag&id="
        else
          url = "http://intranet-aa1-alb-1434599581.eu-west-1.elb.amazonaws.com/intranet/index.php?option=com_fixel&view=fgrunnlag&id="

        if (eventRecord.eventType === 'Jobb') {
          if (eventRecord.jobStatus === 1) { //jobben er ikke startet
            return {
              icon: '' || '',
              headerText: eventRecord.name || '',
              footerText: 'Ikke Startet' || '',
              invoiceUrl: '' || '',
              totalSum: '' || ''
            }
          } else if (eventRecord.jobStatus === 2) { //jobbben er startet
            if (eventRecord.fgrStatus === 0) //fakturagrunnlag ikke startet
            {
              return {
                icon: '' || '',
                headerText: eventRecord.name || '',
                footerText: 'Startet' || '',
                invoiceUrl: '' || '',
                totalSum: '' || ''
              }
            }
            if (eventRecord.fgrStatus === 1) //fakturagrunnlag startet
            {
              return {
                icon: '$' || '',
                headerText: eventRecord.name || '',
                footerText: 'Startet' || '',
                invoiceID: eventRecord.orderNo || '',
                invoiceUrl: url || '',
                totalSum: '' || ''
              }
            }
            if (eventRecord.fgrStatus === 2) //fakturagrunnlag lagret
            {
              return {
                icon: '$$' || '',
                headerText: eventRecord.name || '',
                footerText: 'Startet' || '',
                invoiceID: eventRecord.orderNo || '',
                invoiceUrl: url || '',
                totalSum: eventRecord.fakturaVS || ''
              }
            }
            if (eventRecord.fgrStatus === 3) //fakturagrunnlag er sendt Mamut
            {
              return {
                icon: '$$$' || '',
                headerText: eventRecord.name || '',
                footerText: 'Startet' || '',
                invoiceID: eventRecord.orderNo || '',
                invoiceUrl: url || '',
                totalSum: eventRecord.fakturaVS || ''
              }
            }

          } else if (eventRecord.jobStatus === 3) { //jobbben er ferdig
            if (eventRecord.fgrStatus === 0) //fakturagrunnlag ikke startet
            {
              return {
                icon: '' || '',
                headerText: eventRecord.name || '',
                footerText: 'Ferdig' || '',
                invoiceUrl: '' || '',
                totalSum: '' || ''
              }
            }
            if (eventRecord.fgrStatus === 1) //fakturagrunnlag startet
            {
              return {
                icon: '$' || '',
                headerText: eventRecord.name || '',
                footerText: 'Ferdig' || '',
              }
            }
            if (eventRecord.fgrStatus === 2) //fakturagrunnlag lagret
            {
              return {
                icon: '$$' || '',
                headerText: eventRecord.name || '',
                footerText: 'Ferdig' || '',
                invoiceID: eventRecord.orderNo || '',
                invoiceUrl: url || '',
                totalSum: eventRecord.fakturaVS || ''
              }
            }
            if (eventRecord.fgrStatus === 3) //fakturagrunnlag er sendt Mamut
            {
              return {
                icon: '$$$' || '',
                headerText: eventRecord.name || '',
                footerText: 'Ferdig' || '',
                invoiceID: eventRecord.orderNo || '',
                invoiceUrl: url || '',
                totalSum: eventRecord.fakturaVS || ''
              }
            }

          }
          else if (eventRecord.jobStatus === 4) {
            return {
              headerText: eventRecord.name || '',
              footerText: 'Fakturagrunnlag mottatt' || '',
            }
          }

          else if (eventRecord.jobStatus === 7) { //jobben er fakturert og ferdig
            return {
              icon: '$$$' || '',
              headerText: eventRecord.name || '',
              footerText: 'Ferdig' || '',
              invoiceID: eventRecord.orderNo || '',
              invoiceUrl: url || '',
              totalSum: eventRecord.fakturaVS || ''
            }
          }
          else {
            return {
              icon: '' || '',
              headerText: eventRecord.name || '',
              footerText: 'Ikke startet' || '',
              invoiceID: '' || '',
              invoiceUrl: '' || '',
              totalSum: '' || ''
            }
          }
        }
        else if (eventRecord.eventType === 'Reklamasjon') //jobben er reklamasjon
        {
          return {
            icon: '' || '',
            headerText: 'Reklamasjon' || '',
            footerText: eventRecord.name || '',
            totalSum: '' || ''
          };
        }
        else if (eventRecord.eventType === 'Befaring') //jobben er befaring
        {
          return {
            icon: '' || '',
            headerText: 'Befaring' || '',
            footerText: eventRecord.name || '',
            totalSum: '' || ''
          };
        }
        else { //jobben er interntid
          return {
            icon: '' || '',
            headerText: 'Interntid' || '',
            footerText: eventRecord.name || '',
            totalSum: '' || ''
          };
        }
      }
    }

    return (

      <>

      {this.state.alert}

      <div className="content">

        <BryntumScheduler ref='scheduler'{...schedulerConfig}
          eventsVersion={eventsVersion}
          resourcesVersion={resourcesVersion}

          resources={selectedEmployeesArray}
          events={jobsArray} //eventsArray
          autoHeight={true}   // Make grid grow to fit rows
          barMargin={2}       // Initial row height
          startDate={this.state.startDay}
          endDate={this.state.endDay}
          rowHeight={80}
          useInitialAnimation='zoom-in'
          eventDragFeature = {false}

          //eventEditFeature = {false}
          //eventMenuFeature = {{ items : { editEvent : true, deleteEvent: false }}}
          eventMenuFeature={{ items : {
            addEvent : false,
            deleteEvent: false,
            test : {icon:'nc-icon nc-simple-remove text-danger', text : 'Slett booking', onItem : (source) => this.confirmAlert("Er du sikker på at du vil slette jobb booket på kunde " +source.eventRecord.data.companyName, source.eventRecord.data)}
        }}}

          eventTooltipFeature={{
            //disabled: true,
            anchorToTarget: true,
            //showOn: 'hover',

            tools: [
              {
                // cls: 'b-fa b-fa-trash',
                // handler: function () {
                //   this.hide();
                // }
              },
            ],

            header: {
              titleAlign: 'start',
              title: 'Informasjon'
            },

            onBeforeShow({ source: tooltip }) {
              //console.log("onbeforeshow")

              //   const widgets = tooltip.widgetMap,
              //   return false;

            },

            template: data => `
              <dl>
                <dd>
                  ${data.eventRecord.resourceImage ? `<img style='width:50px' src="data:image/png;base64, ${data.eventRecord.resourceImage}"/>` : ''}
                  <b>${data.eventRecord.resource.name}</b>
                </dd>

                <dt>Jobbtype:</dt>
                  ${data.eventRecord.name ? ` ${data.eventRecord.jobType === null ? `${data.eventRecord.eventType}` : `${data.eventRecord.jobType}`} 

                    <br><br>

                    <dt>Tittel:</dt>
                    <dd>
                      <div><p>${data.eventRecord.name}</p></div>

                      <dd>
                      <dt>Beskrivelse:</dt>
                      <div>${data.eventRecord.jobDescription}</div>
                      </dd>

                      <br>

                      ${data.eventRecord.eventType === 'Interntid' ? `` : `
                        <dt>Kunde:</dt>
                        <div><i class="b-fa b-fa-link" style="float:left; margin-right:5px"></i></div>
                        <div><a target="new" href="http://intranet-elektris-alb-1863918207.eu-west-1.elb.amazonaws.com/booking/htdocs/adm/index.php?action=editcompanypopup&companyId=  ${data.eventRecord.companyID}">${data.eventRecord.alternativeJobContactName}</a></div>
                        <div><i class="b-fa b-fa-link" style="float:left; margin-right:5px"></i></div>
                        <div><a href="https://maps.google.com/?q=1200 ${data.eventRecord.jobAdress}, ${data.eventRecord.jobCity}" target="top">${data.eventRecord.jobAdress}, ${data.eventRecord.jobPostal} - ${data.eventRecord.jobCity}</a></div>
                        <div><i class="b-fa b-fa-phone" style="float:left; margin-right:5px"></i></div>
                        <div> <a href="#" onClick="ITXCall('${data.eventRecord.alternativeJobContactMobileNumber}','${data.eventRecord.resourceId}')">${data.eventRecord.alternativeJobContactMobileNumber}</a></div>
                      `}` : ''}
                </dd>

                <br>

                ${data.eventRecord.eventType === 'Interntid' ? `` : `
                      <dt>Status:</dt>
                      <div>${data.eventRecord.jobStatus === 1 ? `Ikke startet` : ''}
                      ${data.eventRecord.jobStatus === 2 ? `Startet` : ''}
                      ${data.eventRecord.jobStatus === 3 ? `Ferdig` : ''}
                      ${data.eventRecord.jobStatus === 7 ? `Ferdig` : ''}</div>
                      </dd>
                      <br>
                      <dt>Fakturert (eks.mva):</dt>
                      NOK ${data.eventRecord.fakturaVS} ,-
                      <br><br>
                  `}

                <dd>
                  <div>Booket av: ${data.eventRecord.jobBookerName}</div>
                </dd>

              </dl>

          ${data.startClockHtml}
          ${data.endClockHtml}
          `,

          }}

          // columns in scheduler
          columns={[
            {
              field: 'userImage',
              renderer: ({ record }) => ` <div class="b-resource-info"><img style="width: 40px; padding-right: 10px" class="b-resource-image" draggable="false" src="data:image/png;base64, ${record.userImage}" /> <dl>${record.employeeUserName}<dt><dd> ${record.events.length} events</dd></dl></div> `,
              htmlEncode: false,

              text: 'Montør',
              width: 230,
              sortable: false
            },
          ]}


          // header row in scheduler
          tbar={[
            {
              type: 'combo',
              label: 'Velg Team:',
              inputWidth: '8em',
              items: ['Alle', 'Team 1', 'Team 2', 'Team 3'],
              editable: false,
              value: this.state.selectedCompany,
              listeners: {
                change: ({ value }) => { this.companyChange(value) }
              }
            },
            {
              icon: 'nc-icon nc-refresh-69 text-success',
              tooltip: 'oppdater jobber',
              listeners: {
                click: ({ value }) => { this.updateJobs() }
              }
            },
            '->',
            {
              type: 'datefield',
              label: 'Velg dato',
              inputWidth: '8em',
              value: new Date(selectedDate),
              listeners: {
                change: ({ value }) => { this.dateChange(value) }
              }
            },
            {
              type: 'button',
              icon: 'b-fa-angle-left text-success',
              tooltip: 'se forrige dag',
              listeners: {
                click: ({ event }) => { this.dateChange('prev') }
              }
            },
            {
              type: 'button',
              ref: 'todayButton',
              text: 'I dag',
              tooltip: 'se jobber i dag',
              listeners: {
                click: () => { this.dateChange(new Date()) }
              }
            },
            {
              type: 'button',
              icon: 'b-fa-angle-right text-success',
              tooltip: 'se neste dag',
              listeners: {
                click: ({ event }) => { this.dateChange('next') }
              }
            },
          ]}

          // LISTENERS IN SCHEDULER
          listeners={{

            eventClick: ({ source, eventElement }) => {
              console.log("EVENT CLICK")
              // source.features.eventTooltip.tooltip.disabled = true;
              // const tooltip = source.features.eventTooltip.tooltip;
              // tooltip.activeTarget = eventElement;
              // tooltip.showBy(eventElement);
            },

            beforeEventEdit: source => {
              // console.log("IN EVENT EDIT CLICK --->> GOTTA ENSURE NEW EVENTS DO NOT SHOW KONTROLLSKJEMA")
              // console.log(source)
              if (source.eventRecord.jobId === undefined) {
                this.props.displayKontrollSkjema() //show kontrollskjema if new booking
              }
              else {
                this.props.hideKontrollSkjema() //hide kontrollskjema if click on existing booking
              }

              source.eventRecord.resourceId = source.resourceRecord.id;
              this.props.showEditor(source.eventRecord);
              return false;
            },

            beforeEventAdd({ source, eventRecord, resourceRecords }) {
              console.log('BEFORE EVENT ADD');
            },

            afterEventSave: function (data) {
              console.log('AFTER EVENT SAVE')
            },

            beforeEventDelete: source => {
              this.confirmAlert("Er du sikker på at du vil slette jobb booket på kunde " +source.eventRecords[0].data.companyName, source.eventRecords[0].data)
              return false 
            },

            // beforeEventDelete({ source, eventRecord }) {
            //   console.log('BEFORE EVENT DELETE');
            // },

            afterEventDrop({ source }) {
              console.log("AFTER EVENT DROP");
            },
            beforeEventDropFinalize({ source }) {
              console.log("BEFORE EVENT DROP FINALIZE") //show confirmation popup
            },

            // beforeeventresizefinalize: function (data) {
            //   // Save reference to context to be able to finalize drop operation after user clicks yes/no button.
            //   console.log("BEFORE EVENT REZISE")
            //   console.log(data)
            //   this.setState({
            //     barMargin: 10
            //   })
            // }.bind(this)
          }}

        />
      </div>
      </>
    )
  }
}

export default Booking;
