import React from "react";
import Loader from "../Loader/Loader";
import ReactTable from "react-table";
import ReactDatetime from "react-datetime";
import LoadingOverlay from 'react-loading-overlay';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    Row,
    Col,
} from "reactstrap";


const o_date = new Intl.DateTimeFormat();
const f_date = (m_ca, m_it) => Object({ ...m_ca, [m_it.type]: m_it.value });
const m_date = o_date.formatToParts().reduce(f_date, {});

if (m_date.month.length < 2) {
    m_date.month = "0" + m_date.month
}
if (m_date.day.length < 2) {
    m_date.dat = "0" + m_date.day
}

class NewVsOldCustomers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            fromDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            isLoadingNewVsReturningCustomer: true,
            isLoading: true,
            loadingcustomers: false,
            customersSubArray: [],
            customersArray: [],
            customers: "",
            openedCollapses: [],
            spinnerActive: false,
            expanded: {}
        };

        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter(item => item !== collapse)
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses
            });
        }
    };

    fetchData() {

        this.setState({
            spinnerActive: true,
        })

        //new vs. returning customer
        fetch(global.config.server.connection.url + "/api/CustomerSurvey/NewVsReturningCustomers?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log("THE RESULT =>>>")
                    console.log(result)
                    this.setState({
                        isLoadingNewVsReturningCustomer: false,
                        spinnerActive: false,
                        newCustomers: result.customers.filter(item => item.answer !== 0),
                        returningCustomers: result.customers.filter(item => item.answer !== 1),
                        revenueReturningCustomers: result.revenueReturningCustomers,
                        revenueNewCustomers: result.revenueNewCustomers,
                        customersArray: result.customers.map((prop, key) => {
                            return {
                                id: key,
                                customerId: prop["customerId"],
                                jobId: prop["jobId"],
                                answer: prop["answer"],
                            }
                        })
                    });
                },
            )


            //new customers sources and revenue pr. source
        fetch(global.config.server.connection.url + "/api/CustomerSurvey/NewCustomerSources?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoading: false,
                        spinnerActive: false,
                        sourcesArray: result.map((prop, key) => {
                            return {
                                id: key,
                                source: prop["source"],
                                count: prop["count"],
                            }
                        })
                    });
                },
            )
    }





    changeFromDate = (event) => {
        this.setState({ ...this.state, fromDate: event.format("DD-MM-YYYY") })
    }

    changeToDate = (event) => {
        this.setState({ ...this.state, toDate: event.format("DD-MM-YYYY") })
    }


    render() {

        const { isLoading, spinnerActive, isLoadingNewVsReturningCustomer } = this.state;

        if (isLoading || isLoadingNewVsReturningCustomer) {
            return <Loader />
        }

        return (
            <>
                <div className="content">

                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Kundetilfredshet</CardTitle>
                                    <Row>
                                        <Col md="2">
                                            <Label>Fra dato:</Label>
                                            <ReactDatetime
                                                value={this.state.fromDate}
                                                closeOnSelect={true}
                                                dateFormat='DD-MM-YY'
                                                timeFormat={false}
                                                onChange={this.changeFromDate}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Velg fra-dato",
                                                }}


                                            />
                                        </Col>
                                        <Col md="2">
                                            <Label>Til dato:</Label>
                                            <ReactDatetime
                                                value={this.state.toDate}
                                                closeOnSelect={true}
                                                dateFormat='DD-MM-YY'
                                                timeFormat={false}
                                                onChange={this.changeToDate}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Velg til-dato"
                                                }}
                                            />
                                        </Col>
                                        <Col md="4">
                                            <Button color="default" size="sm" style={{position:'absolute', bottom:-10+'px'}} onClick={e => this.fetchData()}>
                                                Oppdater
                                            </Button>
                                        </Col>
                                    </Row>

                                    <br></br>
                                    <Row>
                                        <Col lg="6" md="6" sm="6">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <Col md="4" xs="5">
                                                            <div className="icon-big text-center">
                                                                <i className="nc-icon nc-chart-bar-32 text-success" />
                                                            </div>
                                                        </Col>
                                                        <Col md="8" xs="7">
                                                            <div className="numbers">
                                                                <p className="card-category">Nye kunder</p>
                                                                <CardTitle tag="p">
                                                                    {this.state.newCustomers.length}
                                                                </CardTitle>
                                                                <p />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                                <CardFooter>
                                                    <hr />
                                                    <div className="stats">
                                                        <i className="fa fa-pie-chart" /> Andel av totalt antall kunder: <b>{(this.state.newCustomers.length*100/(this.state.newCustomers.length + this.state.returningCustomers.length)).toFixed(1)} %</b>
                                                        <br></br>
                                                        <i className="fa fa-pie-chart" /> Andel omsetning: <b> {(this.state.revenueNewCustomers * 100 / (this.state.revenueNewCustomers + this.state.revenueReturningCustomers)).toFixed(1)} %</b>
                                                        <br></br>
                                                        <i className="fa fa-money" /> Omsetning: <b>{this.state.revenueNewCustomers.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")} ,-</b>
                                                        <br></br>
                                                        <i className="fa fa-money" /> Snitt pr. jobb: <b>{(this.state.revenueNewCustomers.toString() / this.state.newCustomers.length).toFixed(1)} ,-</b>

                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                        <Col lg="6" md="6" sm="6">
                                            <Card className="card-stats">
                                                <CardBody>
                                                    <Row>
                                                        <Col md="4" xs="5">
                                                            <div className="icon-big text-center">
                                                                <i className="nc-icon nc-chart-bar-32 text-info" />
                                                            </div>
                                                        </Col>
                                                        <Col md="8" xs="7">
                                                            <div className="numbers">
                                                                <p className="card-category">Eksisterende kunder</p>
                                                                <CardTitle tag="p">
                                                                    {this.state.returningCustomers.length}
                                                                </CardTitle>
                                                                <p />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                                <CardFooter>
                                                    <hr />
                                                    <div className="stats">
                                                        <i className="fa fa-pie-chart" /> Andel av totalt antall kunder: <b>{(this.state.returningCustomers.length*100/(this.state.newCustomers.length + this.state.returningCustomers.length)).toFixed(1)} %</b>
                                                        <br></br>
                                                        <i className="fa fa-pie-chart" /> Andel omsetning: <b> {(this.state.revenueReturningCustomers * 100 / (this.state.revenueNewCustomers + this.state.revenueReturningCustomers)).toFixed(1)} %</b>
                                                        <br></br>
                                                        <i className="fa fa-money" /> Omsetning: <b>{this.state.revenueReturningCustomers.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")} ,-</b>
                                                        <br></br>
                                                        <i className="fa fa-money" /> Snitt pr. jobb: <b>{(this.state.revenueReturningCustomers.toString() / this.state.returningCustomers.length).toFixed(1)} ,-</b>
                                                    </div>
                                                </CardFooter>
                                            </Card>
                                        </Col>
                                        
                                        
                                    </Row>

                                </CardHeader>

                                <LoadingOverlay
                                    active={spinnerActive}
                                    spinner
                                    styles={{
                                        spinner: (base) => ({
                                            ...base,
                                            width: '150px',
                                            '& svg circle': {
                                                stroke: 'rgba(107, 208, 152, 1)'
                                            }
                                        })
                                    }}
                                    text='Loading your content...'
                                >
                                </LoadingOverlay>

                                <CardBody>
                                    <p className="card-category">Jobbkilde for Nye Kunder</p>
                                    <ReactTable
                                        expanded={this.state.expanded}
                                        data={this.state.sourcesArray}
                                        defaultPageSize={5}
                                        showPaginationTop
                                        showPaginationBottom
                                        previousText="Forrige"
                                        nextText="Neste"
                                        className="-striped"
                                        columns={[
                                            {
                                                Header: "Kilde",
                                                accessor: "source",
                                                filterable: false
                                            },
                                            {
                                                Header: "Antall",
                                                accessor: "count",
                                                filterable: false,
                                                headerStyle: { textAlign: 'left' }
                                            },
                                        ]}
                                        getTrProps={(state, rowInfo, column, instance, expanded) => {
                                            return {
                                                onClick: e => {
                                                    if (this.state.rowexpanded === rowInfo.viewIndex) {
                                                        this.setState({ expanded: { [rowInfo.viewIndex]: false } });
                                                        this.setState({ rowexpanded: null });
                                                    }
                                                    else {
                                                        fetch(global.config.server.connection.url + "/api/Reports/GetJobsDonesPrCompanyDetails?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate + "&selectedCompany=" + rowInfo.original.companyName, {
                                                            method: "POST",
                                                            headers: {
                                                                'Accept': 'application/json',
                                                                'Content-Type': 'application/json',
                                                                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
                                                            },
                                                        })
                                                            .then(res => res.json())
                                                            .then(
                                                                (result) => {
                                                                    this.setState({
                                                                        spinnerActive: false,
                                                                        customersSubArray: result.map((prop, key) => {
                                                                            return {
                                                                                id: key,
                                                                                jobId: prop["jobId"],
                                                                                companyName: prop["companyName"],
                                                                                source: prop["source"],
                                                                                jobType: prop["jobType"],
                                                                                date: prop["date"],
                                                                                time: prop["time"],
                                                                            }
                                                                        })
                                                                    });
                                                                },
                                                            )

                                                        this.setState({ spinnerActive: true });
                                                        this.setState({ selectedUserName: rowInfo.row.employeeName });
                                                        this.setState({ expanded: { [rowInfo.viewIndex]: true } });
                                                        this.setState({ rowexpanded: rowInfo.viewIndex });
                                                    }
                                                }
                                            };
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </div>
            </>
        );
    }
}

export default NewVsOldCustomers;
