import React from "react";
import Buttons from "views/components/Buttons.jsx";
import Calendar from "views/Calendar.jsx";
import Charts from "views/Charts.jsx";
import CustomerServiceKPI from "CustomerCenter/CustomerServiceKPI.jsx";
import CustomerServiceKPI_rorlegger from "CustomerCenter/CustomerServiceKPI_rorlegger.jsx";
import BookingsPrCompany from "Reports/BookingsPrCompany.jsx";
import JobsDonePrCompany from "Reports/JobsDonePrCompany.jsx";
import DailyCheckOut from "Reports/DailyCheckOut.jsx";
import MyKPIs from "CustomerCenter/MyKPIs.jsx";
import CustomerFollowUp from "CustomerCenter/CustomerFollowUp.jsx";
import Dashboard from "Dashboards/Dashboard.jsx";
import CustomerSurvey from "Dashboards/CustomerSurvey.jsx";
import CustomerCampaigns from "Dashboards/CustomerCampaigns.jsx";
import CustomerSubscriptions from "Dashboards/CustomerSubscriptions.jsx";
import ExtendedForms from "views/forms/ExtendedForms.jsx";
import ExtendedTables from "views/tables/ExtendedTables.jsx";
import FullScreenMap from "views/maps/FullScreenMap.jsx";
import GridSystem from "views/components/GridSystem.jsx";
import Icons from "views/components/Icons.jsx";
import LockScreen from "views/pages/LockScreen.jsx";
import Login from "views/pages/Login.jsx";
import Notifications from "views/components/Notifications.jsx";
import Panels from "views/components/Panels.jsx";
import ReactTables from "views/tables/ReactTables.jsx";
import RegisterEmployee from "views/pages/RegisterEmployee.jsx";
import RegularForms from "views/forms/RegularForms.jsx";
import RegularTables from "views/tables/RegularTables.jsx";
import SweetAlert from "views/components/SweetAlert.jsx";
import Timeline from "views/pages/Timeline.jsx";
import Typography from "views/components/Typography.jsx";
import UserProfile from "views/pages/UserProfile.jsx";
import ValidationForms from "views/forms/ValidationForms.jsx";
import VectorMap from "views/maps/VectorMap.jsx";
import Widgets from "views/Widgets.jsx";
import Wizard from "views/forms/Wizard.jsx";
import WizardCustomer from "views/forms/WizardCustomer.jsx";
import ResetPwd from "./views/pages/ResetPwd";
import ChangePwd from "views/pages/ChangePwd.jsx";
import Welcome from "views/pages/Welcome.jsx";
import GoogleMaps from "Booking/GoogleMaps.jsx"
import ViewEmployees from "Employee/ViewEmployees.jsx";
// import InventoryDashboard from "Inventory/InventoryDashboard.jsx";
// import InventoryByEmployee from "Inventory/InventoryByEmployee.jsx";
// import InventoryByJobNr from "Inventory/InventoryByJobNr.jsx";
// import InventoryRegister from "Inventory/InventoryRegister.jsx";
// import InventoryErrors from "Inventory/InventoryErrors.jsx";
//import InventoryForWarehouse from "Inventory/InventoryForWarehouse.jsx";
//import MyWarehouse from "Inventory/MyWarehouse.jsx";
import WarehouseAdmin from "Warehouse/WarehouseAdmin.jsx";
import WarehouseRegister from "Warehouse/WarehouseRegister.jsx";
import BookingWrapperRokea from "Booking/BookingWrapper.jsx"
import Campaign from "Marketing/Campaign.jsx";
import OfferReminder from "./CRM/OfferReminder";


const routes = [
  {
    path: "/welcome",
    name: "Welcome",
    mini: "KO",
    component: Welcome,
    layout: "/admin",
    invisible: true,
    adminView: true,
    customerServiceView: true,
    employeeView: true,
  },
  {
    collapse: true,
    name: "Boligelektrikeren",
    icon: "nc-icon nc-delivery-fast",
    state: "boligelektrikerenCollapse",
    adminView: true,
    customerServiceView: true,
    employeeView: true,
    views: [
      {
        path: "/bookingRokea",
        name: "Booking",
        icon: "nc-icon nc-delivery-fast",
        component: BookingWrapperRokea,
        layout: "/admin",
        adminView: true,
        customerServiceView: true,
        employeeView: true,
      },
      {
        path: "/heatmapAll",
        name: "Jobber i kart",
        icon: "nc-icon nc-map-big",
        component: GoogleMaps,
        layout: "/admin",
        adminView: true,
        customerServiceView: true,
        employeeView: true,
      },
      {
        collapse: true,
        name: "Dashboards",
        icon: "nc-icon nc-chart-bar-32",
        state: "dashboardCollapse",
        adminView: true,
        customerServiceView: true,
        employeeView: true,
        views: [
          {
            path: "/dashboards",
            name: "Salg",
            mini: "BOE",
            component: Dashboard,
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
            employeeView: true,
          },
        ]
      },
      {
        collapse: true,
        name: "CRM",
        icon: "nc-icon nc-chart-bar-32",
        state: "CRMCollapse",
        adminView: true,
        customerServiceView: true,
        employeeView: true,
        views: [
          {
            path: "/customersurvey",
            name: "Tilfredshet",
            mini: "CS",
            component: CustomerSurvey,
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
          },
          {
            path: "/customercampaign",
            name: "Kampanjeønsker",
            mini: "CC",
            component: CustomerCampaigns,
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
          },
          {
            path: "/customersubscriptions",
            name: "Abonnementsønsker",
            mini: "CS",
            component: CustomerSubscriptions,
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
          }, {
            path: "/customerofferreminder",
            name: "Tilbud/ Rabatt",
            mini: "CS",
            component: OfferReminder,
            layout: "/admin",
            adminView: true,
            customerServiceView: false,
          },
          ''
        ]
      },
      {
        collapse: true,
        name: "Kundesenter",
        icon: "nc-icon nc-tie-bow",
        state: "customerCenterCollapse",
        adminView: true,
        customerServiceView: true,
        employeeView: false,
        views: [
          {
            path: "/mykpis",
            name: "Mine KPI'er",
            mini: "MK",
            component: (props) => <MyKPIs {...props} company="boligelektrikeren" />,
            layout: "/admin",
            adminView: false,
            customerServiceView: true,
          },
          {
            path: "/customerservice",
            name: "Oversikt bookinger",
            mini: "KU",
            component: (props) => <CustomerServiceKPI {...props} company="boligelektrikeren" />,
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
          },
          {
            path: "/customerfollowup",
            name: "Kundeoppfølging",
            mini: "KO",
            component: CustomerFollowUp,
            layout: "/admin",
            adminView: false,
            customerServiceView: false,
            employeeView: true,
          },
        ]
      },
      {
        collapse: true,
        name: "Rapporter",
        icon: "nc-icon nc-single-copy-04",
        state: "reportsCollapse_elektriker",
        adminView: true,
        customerServiceView: true,
        employeeView: true,
        views: [
          {
            path: "/bookingsprcompanyelectrician",
            name: "Bookinger pr. selskap",
            mini: "BS",
            component: BookingsPrCompany,
            company: "boligelektrikeren",
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
            employeeView: true,
          },
          {
            path: "/dailycheckoutelectrician",
            name: "Daglig sjekk-ut",
            mini: "DS",
            component: DailyCheckOut,
            company: "boligelektrikeren",
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
            employeeView: true,
          },
          {
            path: "/jobsdoneprcompanyelectrician",
            name: "Jobber utført pr. selskap",
            mini: "JS",
            component: JobsDonePrCompany,
            company: "boligelektrikeren",
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
            employeeView: true,
          },
        ]
      },
      {
        collapse: true,
        name: "Marked",
        icon: "nc-icon nc-planet",
        state: "markedCollapse",
        adminView: true,
        customerServiceView: true,
        views: [
          {
            path: "/campaign",
            name: "Kampanjer",
            mini: "Kam",
            component: Campaign,
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
          },
        ]
      },
      {
        collapse: true,
        name: "Lager",
        icon: "nc-icon nc-bag-16",
        state: "warehouseCollapse",
        adminView: true,
        customerServiceView: true,
        views: [
          {
            path: "/warehouseadmin",
            name: "Oversikt",
            mini: "LO",
            component: WarehouseAdmin,
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
          },
          {
            path: "/warehouseregister",
            name: "Nytt lager",
            mini: "NL",
            component: WarehouseRegister,
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
          },
        ]
      },
      {
        collapse: true,
        name: "Ansatte",
        icon: "nc-icon nc-badge",
        state: "employeeCollapse",
        adminView: true,
        customerServiceView: true,
        views: [
          {
            path: "/viewEmployees",
            name: "Oversikt",
            mini: "Reg",
            component: ViewEmployees,
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
          },
          {
            path: "/registerEmployee",
            name: "Ny Ansatt",
            mini: "Reg",
            component: Wizard,
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
          },
        ]
      },
    ]
  },
  {
    collapse: true,
    name: "Boligrørleggeren",
    icon: "nc-icon nc-delivery-fast",
    state: "boligrorleggerenCollapse",
    adminView: true,
    customerServiceView: true,
    employeeView: true,
    views: [
      {
        collapse: true,
        name: "Kundesenter",
        icon: "nc-icon nc-tie-bow",
        state: "BRcustomerCenterCollapse",
        adminView: true,
        customerServiceView: true,
        employeeView: false,
        views: [
          {
            path: "/mykpisbr",
            name: "Mine KPI'er",
            mini: "MK",
            component: (props) => <MyKPIs {...props} company="boligrorleggeren" />,
            layout: "/admin",
            adminView: false,
            customerServiceView: true,
          },
          {
            path: "/customerservicebr",
            name: "Oversikt bookinger",
            mini: "OB",
            component: CustomerServiceKPI_rorlegger,
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
          },
        ]
      },
      {
        collapse: true,
        name: "Rapporter",
        icon: "nc-icon nc-single-copy-04",
        state: "reportsCollapse_rorlegger",
        adminView: true,
        customerServiceView: true,
        employeeView: true,
        views: [
          {
            path: "/bookingsprcompanyplumber",
            name: "Bookinger pr. selskap",
            mini: "BS",
            component: BookingsPrCompany,
            company: "boligrorleggeren",
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
            employeeView: true,
          },
          {
            path: "/dailyCheckOutplumber",
            name: "Daglig sjekk-ut",
            mini: "DS",
            component: DailyCheckOut,
            company: "boligrorleggeren",
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
            employeeView: true,
          },
          {
            path: "/jobsdoneprcompanyplumber",
            name: "Jobber utført pr. selskap",
            mini: "JS",
            component: JobsDonePrCompany,
            company: "boligrorleggeren",
            layout: "/admin",
            adminView: true,
            customerServiceView: true,
            employeeView: true,
          },
          {
            path: "/charts",
            name: "Charts",
            icon: "nc-icon nc-chart-bar-32",
            component: Charts,
            invisible: true,
            layout: "/admin",
            adminView: true,
            employeeView: false,
          },
        ]
      },
      {
        collapse: true,
        name: "Pages",
        icon: "nc-icon nc-book-bookmark",
        state: "pagesCollapse",
        invisible: true,
        adminView: true,
        employeeView: false,
        views: [
          {
            path: "/timeline",
            name: "Timeline",
            mini: "T",
            component: Timeline,
            layout: "/admin",
            adminView: true,
            employeeView: false,
          },
          {
            path: "/login",
            name: "Login",
            mini: "L",
            component: Login,
            layout: "/auth",
            adminView: true,
            employeeView: false,
          },
          {
            path: "/resetpwd",
            name: "ResetPwd",
            mini: "R",
            component: ResetPwd,
            layout: "/auth",
            adminView: true,
            employeeView: false,
          },
          {
            path: "/registerr",
            name: "Registerr",
            mini: "R",
            component: RegisterEmployee,
            layout: "/auth",
            adminView: true,
            employeeView: false,
          },
          {
            path: "/lock-screen",
            name: "LockScreen",
            mini: "LS",
            component: LockScreen,
            layout: "/auth",
            adminView: true,
            employeeView: false,
          },
          {
            path: "/user-profile",
            name: "UserProfile",
            mini: "UP",
            component: UserProfile,
            layout: "/admin",
            adminView: true,
            employeeView: false,
          },
          {
            path: "/changePwd",
            name: "ChangePwd",
            mini: "UP",
            component: ChangePwd,
            layout: "/admin",
            adminView: true,
            employeeView: false,
          },
        ]
      },
    ]
  }







  // {
  //   collapse: true,
  //   name: "Inventory",
  //   icon: "nc-icon nc-box",
  //   state: "inventoryCollapse",
  //   invisible: true,
  //   adminView: true,
  //   customerServiceView: true,
  //   views: [
  //     {
  //       path: "/inventorydashboard",
  //       name: "Dashboard",
  //       mini: "DI",
  //       component: InventoryDashboard,
  //       layout: "/admin",
  //       adminView: true,
  //       customerServiceView: true,
  //     },
  //     {
  //       path: "/mywarehouse",
  //       name: "My Warehouse",
  //       mini: "MW",
  //       component: MyWarehouse,
  //       layout: "/admin",
  //       adminView: true,
  //       customerServiceView: true,
  //     },
  //     {
  //       path: "/myinventory",
  //       name: "My Inventory",
  //       mini: "MI",
  //       component: InventoryForWarehouse,
  //       layout: "/admin",
  //       adminView: true,
  //       customerServiceView: true,
  //     },
  //     {
  //       path: "/inventoryRegister",
  //       name: "Register",
  //       mini: "RI",
  //       component: InventoryRegister,
  //       layout: "/admin",
  //       adminView: true,
  //       customerServiceView: true,
  //     },
  //     {
  //       path: "/inventoryByEmployee",
  //       name: "By Employee",
  //       mini: "MYI",
  //       component: InventoryByEmployee,
  //       layout: "/admin",
  //       adminView: true,
  //       customerServiceView: true,
  //     },
  //     {
  //       path: "/inventoryByJobNr",
  //       name: "By Job Nr.",
  //       mini: "MYI",
  //       component: InventoryByJobNr,
  //       layout: "/admin",
  //       adminView: true,
  //       customerServiceView: true,
  //     },
  //     {
  //       path: "/InventoryErrors",
  //       name: "Errors",
  //       mini: "ERI",
  //       component: InventoryErrors,
  //       layout: "/admin",
  //       adminView: true,
  //       customerServiceView: true,
  //     }
  //   ]
  // },

  // {
  //   collapse: true,
  //   name: "Customer",
  //   icon: "nc-icon nc-single-02",
  //   state: "customerCollapse",
  //   invisible: true,
  //   adminView: true,
  //   customerServiceView: true,
  //   views: [
  //     {
  //       path: "/registercustomer",
  //       name: "View Customers",
  //       mini: "RC",
  //       component: WizardCustomer,
  //       layout: "/admin",
  //       adminView: true,
  //       employeeView: true,
  //     },
  //     {
  //       path: "/registercustomer",
  //       name: "Register Customer",
  //       mini: "RC",
  //       component: WizardCustomer,
  //       layout: "/admin",
  //       adminView: true,
  //       employeeView: false,
  //     },
  //   ]
  // },


  // {
  //   collapse: true,
  //   name: "Components",
  //   icon: "nc-icon nc-layout-11",
  //   state: "componentsCollapse",
  //   invisible: true,
  //   adminView: true,
  //   employeeView: true,
  //   views: [
  //     {
  //       path: "/buttons",
  //       name: "Buttons",
  //       mini: "B",
  //       component: Buttons,
  //       layout: "/admin",
  //       adminView: true,
  //       employeeView: false,
  //     },
  //     {
  //       path: "/grid-system",
  //       name: "Grid System",
  //       mini: "GS",
  //       component: GridSystem,
  //       layout: "/admin",
  //       adminView: true,
  //       employeeView: false,
  //     },
  //     {
  //       path: "/panels",
  //       name: "Panels",
  //       mini: "P",
  //       component: Panels,
  //       layout: "/admin",
  //       adminView: true,
  //       employeeView: false,
  //     },
  //     {
  //       path: "/sweet-alert",
  //       name: "Sweet Alert",
  //       mini: "SA",
  //       component: SweetAlert,
  //       layout: "/admin",
  //       adminView: true,
  //       employeeView: false,
  //     },
  //     {
  //       path: "/notifications",
  //       name: "Notifications",
  //       mini: "N",
  //       component: Notifications,
  //       layout: "/admin",
  //       adminView: true,
  //       employeeView: false,
  //     },
  //     {
  //       path: "/icons",
  //       name: "Icons",
  //       mini: "I",
  //       component: Icons,
  //       adminView: true,
  //       employeeView: true,
  //       layout: "/admin",
  //     },
  //     {
  //       path: "/typography",
  //       name: "Typography",
  //       mini: "T",
  //       component: Typography,
  //       layout: "/admin",
  //       adminView: true,
  //       employeeView: false,
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Forms",
  //   icon: "nc-icon nc-ruler-pencil",
  //   state: "formsCollapse",
  //   invisible: true,
  //   adminView: true,
  //   employeeView: true,
  //   views: [
  //     {
  //       path: "/regular-forms",
  //       name: "Regular Forms",
  //       mini: "RF",
  //       component: RegularForms,
  //       layout: "/admin",
  //       adminView: true,
  //       employeeView: true,
  //     },
  //     {
  //       path: "/extended-forms",
  //       name: "Extended Forms",
  //       mini: "EF",
  //       component: ExtendedForms,
  //       layout: "/admin",
  //       adminView: true,
  //       employeeView: true,
  //     },
  //     {
  //       path: "/validation-forms",
  //       name: "Validation Forms",
  //       mini: "VF",
  //       component: ValidationForms,
  //       layout: "/admin",
  //       adminView: true,
  //       employeeView: true,
  //     },
  //     {
  //       path: "/wizard",
  //       name: "Wizard",
  //       mini: "W",
  //       component: Wizard,
  //       layout: "/admin",
  //       adminView: true,
  //       employeeView: true,
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Tables",
  //   icon: "nc-icon nc-single-copy-04",
  //   state: "tablesCollapse",
  //   invisible: true,
  //   adminView: true,
  //   employeeView: true,
  //   views: [
  //     {
  //       path: "/regular-tables",
  //       name: "Regular Tables",
  //       mini: "RT",
  //       component: RegularTables,
  //       layout: "/admin",
  //       adminView: true,
  //       employeeView: true,
  //     },
  //     {
  //       path: "/extended-tables",
  //       name: "Extended Tables",
  //       mini: "ET",
  //       component: ExtendedTables,
  //       layout: "/admin",
  //       adminView: true,
  //       employeeView: true,
  //     },
  //     {
  //       path: "/react-tables",
  //       name: "React Tables",
  //       mini: "RT",
  //       component: ReactTables,
  //       layout: "/admin",
  //       adminView: true,
  //       employeeView: true,
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   state: "mapsCollapse",
  //   invisible: true,
  //   views: [
  //     {
  //       path: "/google-maps",
  //       name: "Google Maps",
  //       mini: "GM",
  //       component: GoogleMaps,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/full-screen-map",
  //       name: "Full Screen Map",
  //       mini: "FSM",
  //       component: FullScreenMap,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/vector-map",
  //       name: "Vector Map",
  //       mini: "VM",
  //       component: VectorMap,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   icon: "nc-icon nc-box",
  //   component: Widgets,
  //   invisible: true,
  //   layout: "/admin",
  //   adminView: true,
  //   employeeView: false,
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: Charts,
  //   invisible: true,
  //   layout: "/admin",
  //   adminView: true,
  //   employeeView: false,
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   icon: "nc-icon nc-calendar-60",
  //   component: Calendar,
  //   invisible: true,
  //   layout: "/admin"
  // }
];

export default routes;
