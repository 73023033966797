import React from "react";
import { Redirect } from "react-router-dom";
import Loader from "components/Loader/Loader";
import NotificationAlert from "react-notification-alert";
import 'config';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";


class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      userPwd: "",
      error: null,
      loading: false,
      isLoaded: false,
      access_token: null
    };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  notify = text => {
    var type = "danger";
    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          <div>
            <b>That did not work</b> <br />
            {text}
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 4
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  async handleClick() {

    if (!(this.state.userName && this.state.userPwd)) {
      console.log("==> Need to type user and pwd");
      return;
    }

    this.setState({ loading: true });

    try {
      //var response = await fetch("https://localhost:44347/token", {
      var response = await fetch(global.config.server.connection.url + "/token", {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'grant_type=password&username=' + this.state.userName + '&password=' + this.state.userPwd
      })
    } catch (ex) {
      console.log(ex);
      return;
    }

    if (!response.ok) {
      console.log("RESPONSE NOT OK")
      let result = await response.json();
      this.setState({
        loading: false,
        isLoaded: false
      });
      this.notify(result.error_description)
      return;
    }

    let result = await response.json();
    this.setState({
      loading: false,
      isLoaded: true,
      access_token: result
    });
    return;
  }

  handleChange({ target }) {
    this.setState({
      [target.name]: target.value
    });
  }

  keyPressed(event) {
    if (event.key === "Enter") {
      document.getElementById('submitButton').click();
    }
  }


  componentDidMount() {
    document.body.classList.toggle("login-page");

    console.log("==>LOGIN -> PROPS:")
    console.log(this.props)

  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  render() {
    const { error, loading } = this.state;

    if (this.state.isLoaded) {
      sessionStorage.setItem("access_token", JSON.stringify(this.state.access_token.access_token));
      return <Redirect to="/admin/welcome" />
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (loading) {
      return (
        <div className="content">
          <Loader />
        </div>
      )

    } else {

      return (

        <div className="login-page">

          <NotificationAlert ref="notificationAlert" />

          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Form action="" className="form" method="">
                  <Card className="card-login">
                    <CardHeader>
                      <CardHeader>
                        <h3 className="header text-center">Login</h3>
                      </CardHeader>
                    </CardHeader>
                    <CardBody>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="User Name"
                          type="text"
                          name="userName"
                          value={this.state.userName}
                          onChange={this.handleChange} />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          name="userPwd"
                          value={this.state.userPwd}
                          onChange={this.handleChange}
                          onKeyPress={this.keyPressed}
                          type="password"
                          autoComplete="off"
                        />
                      </InputGroup>
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        id='submitButton'
                        className="btn-round mb-3"
                        color="warning"
                        href="#pablo"
                        onClick={this.handleClick}
                      >
                        {this.state.isToggleOn ? 'Get Started' : 'Log in'}
                        {/* {this.state.isToggleOn ? 'Get Started' : 'Log off'} opprinnelig stod den slik */}
                      </Button>
                      <br />
                      <a href="/auth/resetpwd"><p className="header text-center">Reset password</p></a>

                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
          <div
            className="full-page-background"
            style={{
              backgroundImage: `url(${require("assets/img/bg/electrician.jpg")})`
            }}
          />
        </div>
      );
    }
  }
}

export default Login;
