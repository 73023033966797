import React from "react";
import Loader from "../components/Loader/Loader";
import classnames from "classnames";
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Input,
    Row,
    Col
} from "reactstrap";

class WarehouseRegister extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            loading: false,
            warehouseState: "",
            warehouseId: "error"
        };
    }

    successAlert = () => {
        console.log("successalert")
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Warehouse registered!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    Have a great day.
                </ReactBSAlert>
            )
        });
    };

    errorAlert = () => {
        console.log("erroralert")
        this.setState({
            alert: (
                <ReactBSAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" }}
                    title="OPS!"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    Something went wrong, pleasy try again.
                </ReactBSAlert>
            )
        });
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    verifyWarehouseId = (event) => {

        var letter = /^[a-zA-Z]+$/;
        var number = /^[0-9]+$/;

        if (event.target.value.length === 7 && event.target.value.substring(0, 2).match(letter) && event.target.value.substring(2, 7).match(number)) {
            this.setState({
                warehouseState: "has-success",
                warehouseId: event.target.value
            });

        }
        else {
            this.setState({
                warehouseState: "has-danger",
                warehouseId: "error"
            });

        }
    };

    async registerWarehouse() {

        if (this.state.warehouseId !== "error") {
            try {
                var response = await fetch(global.config.server.connection.url + "/api/Warehouse/RegisterWarehouse?warehouseId=" + this.state.warehouseId, {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
                    },
                })
            } catch (ex) {
                this.errorAlert()
                return;
            }

            if (response.ok) {
                this.successAlert()
                document.getElementById('txtCarNumber').value = ""
                console.log("RESPONSE OK")
                return;
            }

            if (!response.ok) {
                this.errorAlert()
                console.log("RESPONSE NOT OK")
                return;
            }
        }
    }

    render() {

        const { loading } = this.state;

        if (loading) {
            return <Loader />
        }

        return (
            <>
                {this.state.alert}

                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Register new warehouse</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Label sm="2">Warehouse ID</Label>
                                        <Col sm="6">
                                            <FormGroup
                                                className={classnames(this.state.warehouseState)}
                                            >
                                                <Input
                                                    id="txtCarNumber"
                                                    placeholder="car registration number"
                                                    type="text"
                                                    onChange={e => this.verifyWarehouseId(e)}
                                                />
                                                {this.state.warehouseState === "has-danger" ? (
                                                    <label className="error">A valid car registration number is required</label>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>

                                <CardFooter>
                                    <Button className="btn-round" color="info" onClick={e => this.registerWarehouse()}>
                                        Register
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default WarehouseRegister;
