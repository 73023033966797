import React from "react";
// react plugin used to create charts
import Loader from "../components/Loader/Loader";
import ReactTable from "react-table";
import ReactDatetime from "react-datetime";
import LoadingOverlay from 'react-loading-overlay';
import StatsBar from "../components/StatsBar/StatsBar";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Collapse,
    FormGroup,
    Input,
    Label,
    Row,
    Col,
} from "reactstrap";


const o_date = new Intl.DateTimeFormat();
const f_date = (m_ca, m_it) => Object({ ...m_ca, [m_it.type]: m_it.value });
const m_date = o_date.formatToParts().reduce(f_date, {});

if (m_date.month.length < 2) {
    m_date.month = "0" + m_date.month
}
if (m_date.day.length < 2) {
    m_date.day = "0" + m_date.day
}

class CustomerServiceKPI extends React.Component {
    constructor(props) {
        console.log("THE PROPS: ", props)
        super(props);
        this.state = {
            toDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            fromDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            loadingBookings: false,
            bookingsSubArray: [],
            jobTypeRadioArray: [],
            bookingsArray: [],
            reklamasjon: 0,
            tilbud: 0,
            tilbudAkseptert: 0,
            selectedCompany: 0,
            bookings: "",
            complaints: "",
            openedCollapses: [],
            spinnerActive: false,
            selectedUserName: "",
            expanded: {}
        };

        this.refreshClick = this.refreshClick.bind(this);
    }

    velgAlleClicked(event) {
        var radios = document.getElementsByName("chk");
        var i = 0;
        for (i = 0; i < radios.length; i++) {
            this.state.jobTypeRadioArray.push(radios[i].id)
            radios[i].checked = true;
        }
        return null;
    }

    fjernAlleClicked(event) {
        var radios = document.getElementsByName("chk");
        var i = 0;
        for (i = 0; i < radios.length; i++) {
            this.state.jobTypeRadioArray = [];
            radios[i].checked = false;
        }
        return null;
    }

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter(item => item !== collapse)
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses
            });
        }
    };

    radioChanged(event) {

        if (event.target.id === "alle") {
            if (event.target.checked) {
                this.setState({
                    selectedCompany: 0,
                });
                document.getElementById("elektris").checked = false;
                document.getElementById("elfiksern").checked = false;
                document.getElementById("elmesteren").checked = false;
                document.getElementById("fixel").checked = false;
            }
        }

        if (event.target.id === "elektris") {
            if (event.target.checked) {
                this.setState({
                    selectedCompany: 1,
                });
                document.getElementById("alle").checked = false;
                document.getElementById("elfiksern").checked = false;
                document.getElementById("elmesteren").checked = false;
                document.getElementById("fixel").checked = false;
            }
        }
        if (event.target.id === "elfiksern") {
            if (event.target.checked) {
                this.setState({
                    selectedCompany: 6,
                });
                document.getElementById("elektris").checked = false;
                document.getElementById("alle").checked = false;
                document.getElementById("elmesteren").checked = false;
                document.getElementById("fixel").checked = false;
            }
        }
        if (event.target.id === "elmesteren") {
            if (event.target.checked) {
                this.setState({
                    selectedCompany: 3,
                });
            }
            document.getElementById("elektris").checked = false;
            document.getElementById("elfiksern").checked = false;
            document.getElementById("alle").checked = false;
            document.getElementById("fixel").checked = false;
        }

        if (event.target.id === "fixel") {
            if (event.target.checked) {
                this.setState({
                    selectedCompany: 2,
                });
            }
            document.getElementById("elektris").checked = false;
            document.getElementById("elfiksern").checked = false;
            document.getElementById("elmesteren").checked = false;
            document.getElementById("alle").checked = false;
        }

        if (event.target.id === "reklamasjon") {
            if (event.target.checked) {
                this.setState({
                    reklamasjon: 1,
                    tilbud: 0,
                    tilbudAkseptert: 0,
                });
                var radio = document.getElementById("tilbud");
                radio.checked = false;
                radio = document.getElementById("tilbudAkseptert");
                radio.checked = false;
            } else {
                this.setState({
                    reklamasjon: 0,
                });
            }
        } else if (event.target.id === "tilbud") {
            if (event.target.checked) {
                this.setState({
                    reklamasjon: 0,
                    tilbud: 1,
                    tilbudAkseptert: 0,
                });
                radio = document.getElementById("reklamasjon");
                radio.checked = false;
                radio = document.getElementById("tilbudAkseptert");
                radio.checked = false;
            } else {
                this.setState({
                    tilbud: 0,
                });
            }

        } else if (event.target.id === "tilbudAkseptert") {
            if (event.target.checked) {
                this.setState({
                    reklamasjon: 0,
                    tilbud: 0,
                    tilbudAkseptert: 1,
                });
                radio = document.getElementById("reklamasjon");
                radio.checked = false;
                radio = document.getElementById("tilbud");
                radio.checked = false;
            } else {
                this.setState({
                    tilbudAkseptert: 0,
                });
            }

        }
        else if (event.target.id !== "alle" && event.target.id !== "elektris" && event.target.id !== "elfiksern" && event.target.id !== "elmesteren" && event.target.id !== "fixel") {
            if (event.target.checked) {
                this.state.jobTypeRadioArray.push(event.target.id)
            }
            else {
                for (var i = 0; i < this.state.jobTypeRadioArray.length; i++) {
                    if (this.state.jobTypeRadioArray[i] === event.target.id) {
                        this.state.jobTypeRadioArray.splice(i, 1);
                    }
                }
            }
        }
    }


    refreshClick() {

        this.setState({
            spinnerActive: true,
        })
        
        fetch(global.config.server.connection.url + "/api/CustomerService/GetBookingsByEmployee?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate + "&jobTypes=" + this.state.jobTypeRadioArray + "&reklamasjon=" + this.state.reklamasjon + "&tilbud=" + this.state.tilbud + "&tilbudAkseptert=" + this.state.tilbudAkseptert + "&selectedCompany=" + this.state.selectedCompany, {
            //fetch(global.config.server.connection.url + "/api/CustomerService/GetBookingsByEmployee?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate + "&jobTypes=" + this.state.jobTypeRadioArray + "&reklamasjon=" + this.state.reklamasjon + "&tilbud=" + this.state.tilbud + "&tilbudAkseptert=" + this.state.tilbudAkseptert, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    this.setState({
                        loadingBookings: false,
                        spinnerActive: false,
                        bookingsArray: result.map((prop, key) => {
                            return {
                                id: key,
                                employeeImage: <div className="avatar" style={{ width: '50px', height: '50px', marginBottom: '0px' }}><img alt="user" src={"data:image/png;base64, " + prop["employeeImage"]} /></div>,
                                employeeID: prop["employeeID"],
                                employeeName: prop["employeeName"],
                                totalBookings: prop["totalBookings"],
                            }
                        })
                    });
                },
            )
    }



    componentDidMount() {
        fetch(global.config.server.connection.url + "/api/CustomerService/GetBookingsByEmployee?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate + "&jobTypes=" + this.state.jobTypeRadioArray + "&reklamasjon=" + this.state.reklamasjon + "&tilbud=" + this.state.tilbud + "&tilbudAkseptert=" + this.state.tilbudAkseptert + "&selectedCompany=" + this.state.selectedCompany, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        loadingBookings: false,
                        spinnerActive: false,
                        bookingsArray: result.map((prop, key) => {
                            return {
                                id: key,
                                employeeImage: <div className="avatar" style={{ width: '50px', height: '50px', marginBottom: '0px' }}><img alt="user" src={"data:image/png;base64, " + prop["employeeImage"]} /></div>,
                                employeeID: prop["employeeID"],
                                employeeName: prop["employeeName"],
                                totalBookings: prop["totalBookings"],
                            }
                        })
                    });
                },
            )
    }

    changeFromDate = (event) => {
        this.setState({ ...this.state, fromDate: event.format("DD-MM-YYYY") })
    }

    changeToDate = (event) => {
        this.setState({ ...this.state, toDate: event.format("DD-MM-YYYY") })
    }


    render() {

        const { loadingBookingsToday, loadingComplaints, loadingBookings, spinnerActive } = this.state;

        if (loadingBookingsToday || loadingComplaints || loadingBookings) {
            return <Loader />
        }

        return (
            <>
                <div className="content">

                <StatsBar company={"boligelektrikeren"} />

                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Oversikt bookinger</CardTitle>
                                    <Row>
                                        <Col md="2">
                                            <Label>Fra dato:</Label>
                                            <ReactDatetime
                                                value={this.state.fromDate}
                                                closeOnSelect={true}
                                                dateFormat='DD-MM-YY'
                                                timeFormat={false}
                                                onChange={this.changeFromDate}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Velg fra-dato",
                                                }}


                                            />
                                        </Col>
                                        <Col md="2">
                                            <Label>Til dato:</Label>
                                            <ReactDatetime
                                                value={this.state.toDate}
                                                closeOnSelect={true}
                                                dateFormat='DD-MM-YY'
                                                timeFormat={false}
                                                onChange={this.changeToDate}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Velg til-dato"
                                                }}
                                            />
                                        </Col>
                                        <Col md="8">
                                            <Card className="card-plain">
                                                <CardHeader role="tab">

                                                    <Row>
                                                        <Col md="2">
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input type="checkbox" id="alle" onChange={this.radioChanged.bind(this)} />
                                                                    <span className="form-check-sign" /> <span style={{ color: '#000', fontWeight: 'bold' }}>Alle</span>
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="2">
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input type="checkbox" id="elektris" onChange={this.radioChanged.bind(this)} />
                                                                    <span className="form-check-sign" /> <span style={{ color: '#000', fontWeight: 'bold' }}>Elektris</span>
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="2">
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input type="checkbox" id="elfiksern" onChange={this.radioChanged.bind(this)} />
                                                                    <span className="form-check-sign" /> <span style={{ color: '#000', fontWeight: 'bold' }}>Elfiksern</span>
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="2">
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input type="checkbox" id="elmesteren" onChange={this.radioChanged.bind(this)} />
                                                                    <span className="form-check-sign" /> <span style={{ color: '#000', fontWeight: 'bold' }}>Elmesteren</span>
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="2">
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input type="checkbox" id="fixel" onChange={this.radioChanged.bind(this)} />
                                                                    <span className="form-check-sign" /> <span style={{ color: '#000', fontWeight: 'bold' }}>Fixel</span>
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <a
                                                        aria-expanded={this.state.openedCollapses.includes(
                                                            "collapseOne"
                                                        )}
                                                        href="#"
                                                        data-parent="#accordion"
                                                        data-toggle="collapse"
                                                        onClick={() => this.collapsesToggle("collapseOne")}
                                                    >
                                                        <b style={{ color: '#6BD098' }}>Spesifisere oppdragstype{" "}</b>
                                                        <i className="nc-icon nc-minimal-down" />
                                                    </a>
                                                </CardHeader>
                                                <Collapse
                                                    role="tabpanel"
                                                    isOpen={this.state.openedCollapses.includes(
                                                        "collapseOne"
                                                    )}
                                                >
                                                    <CardBody>
                                                        <Row style={{ marginBottom: 15 + 'px' }}>
                                                            <Col md="3">
                                                                <Label style={{ textDecoration: 'underline' }} onClick={this.velgAlleClicked.bind(this)} >Velg alle</Label>
                                                            </Col>
                                                            <Col md="3">
                                                                <Label style={{ textDecoration: 'underline' }} onClick={this.fjernAlleClicked.bind(this)}>Fjern valg</Label>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="reklamasjon" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> <span style={{ color: '#000', fontWeight: 'bold' }}>Reklamasjon</span>
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="tilbud" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> <span style={{ color: '#000', fontWeight: 'bold' }}>Tilbud</span>
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="tilbudAkseptert" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> <span style={{ color: '#000', fontWeight: 'bold' }}>Aksepterte Tilbud</span>
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Befaring" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Befaring
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Jordfeil" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Jordfeil
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Sikringen går" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Sikringen går
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Andre type feil ( Kunden vet ikke )" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Andre typer feil
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Reparasjoner ( Kunden vet )" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Reparasjoner
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Lampe jobber" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Lampejobber
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Ny sikring" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Ny sikring
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Oppgradering ekstra punkter" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Ekstra punkter
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Andre type oppgraderinger" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Annen oppgradering
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Ute arbeid i høyde" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Ute i høyde
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Ute arbeid standard" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Ute standard
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Riving" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Riving
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Vakt" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Vakt
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="sikringskap" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Sikringsskap
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="utskifting av defekte deler" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Defekte deler
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="brente deler" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Brente deler
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="tilsynsrapport" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Tilsynsrapport
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="termostat" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Termostat
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="strømløst" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Strømløst
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="nye kurser" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Nye kurser
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="varmekabel" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Varmekabel
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Infratek rapport" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Infratek rapport
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Ferdigstille" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Ferdigstille
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Smarthjem" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Smarthjem
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Tilstandsrapport" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Tilstandsrapport
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Tesla" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Tesla
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md="4">
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input type="checkbox" id="Data og tele" name="chk" onChange={this.radioChanged.bind(this)} />
                                                                        <span className="form-check-sign" /> Data og tele
                                                                    </Label>
                                                                </FormGroup>
                                                            </Col>

                                                        </Row>

                                                    </CardBody>
                                                </Collapse>
                                            </Card>

                                        </Col>

                                    </Row>

                                    <Row style={{ marginTop: 10 + 'px' }}>
                                        <Col md="12">
                                            <Button className="btn-round" color="success" onClick={e => this.refreshClick()}>
                                                Oppdater
                                            </Button>
                                        </Col>
                                    </Row>

                                </CardHeader>

                                <LoadingOverlay
                                    active={spinnerActive}
                                    spinner
                                    styles={{
                                        spinner: (base) => ({
                                            ...base,
                                            width: '150px',
                                            '& svg circle': {
                                                stroke: 'rgba(107, 208, 152, 1)'
                                            }
                                        })
                                    }}
                                    text='Loading your content...'
                                >
                                </LoadingOverlay>

                                <CardBody>
                                    <ReactTable
                                        expanded={this.state.expanded}
                                        data={this.state.bookingsArray}
                                        defaultPageSize={5}
                                        showPaginationTop
                                        showPaginationBottom
                                        previousText="Forrige"
                                        nextText="Neste"
                                        className="-striped -highlight success-pagination"
                                        columns={[
                                            {
                                                accessor: "employeeID",
                                                visible: false,
                                                show: false,
                                            },
                                            {
                                                accessor: "employeeImage",
                                                sortable: false,
                                                filterable: false,
                                                width: 60,
                                            },
                                            {
                                                Header: "Ansatt",
                                                accessor: "employeeName",
                                                filterable: false
                                            },
                                            {
                                                Header: "Antall Bookinger",
                                                accessor: "totalBookings",
                                                filterable: false,
                                                sortable: false,
                                                headerStyle: { textAlign: 'left' },
                                                footer: () => {
                                                    let ageSum = 22
                                                    return 'Total: ' + ageSum
                                                }
                                            },
                                        ]}
                                        getTrProps={(state, rowInfo, column, instance, expanded) => {
                                            return {
                                                onClick: e => {
                                                    if (this.state.rowexpanded === rowInfo.viewIndex) {
                                                        this.setState({ expanded: { [rowInfo.viewIndex]: false } });
                                                        this.setState({ rowexpanded: null });
                                                    }
                                                    else {
                                                        fetch(global.config.server.connection.url + "/api/CustomerService/GetBookingsByEmployeeDetail?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate + "&jobTypes=" + this.state.jobTypeRadioArray + "&reklamasjon=" + this.state.reklamasjon + "&userName=" + rowInfo.row.employeeName + "&tilbud=" + this.state.tilbud + "&tilbudAkseptert=" + this.state.tilbudAkseptert + "&selectedCompany=" + this.state.selectedCompany, {
                                                            method: "POST",
                                                            headers: {
                                                                'Accept': 'application/json',
                                                                'Content-Type': 'application/json',
                                                                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
                                                            },
                                                        })
                                                            .then(res => res.json())
                                                            .then(
                                                                (result) => {
                                                                    console.log(result)
                                                                    this.setState({
                                                                        spinnerActive: false,
                                                                        bookingsSubArray: result.map((prop, key) => {
                                                                            return {
                                                                                id: key,
                                                                                jobTypeId: prop["jobTypeId"],
                                                                                jobTypeName: prop["jobTypeName"],
                                                                                jobCount: prop["jobCount"],
                                                                            }
                                                                        })
                                                                    });
                                                                },
                                                            )

                                                        this.setState({ spinnerActive: true });
                                                        this.setState({ selectedUserName: rowInfo.row.employeeName });
                                                        this.setState({ expanded: { [rowInfo.viewIndex]: true } });
                                                        this.setState({ rowexpanded: rowInfo.viewIndex });
                                                    }
                                                }
                                            };
                                        }}

                                        //localhost:44347
                                        SubComponent={row => {

                                            return (
                                                <div style={{ padding: "20px" }}>
                                                    <ReactTable
                                                        minRows={1}
                                                        data={this.state.bookingsSubArray}
                                                        columns={[
                                                            {
                                                                Header: "Type jobb",
                                                                accessor: "jobTypeName",
                                                                filterable: false,
                                                            },
                                                            {
                                                                Header: "Antall",
                                                                accessor: "jobCount",
                                                                filterable: false,
                                                                headerStyle: { textAlign: 'left' }
                                                            }
                                                        ]}
                                                        showPagination={false}
                                                    />
                                                </div>
                                            );
                                        }}

                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </div>
            </>
        );
    }
}

export default CustomerServiceKPI;
