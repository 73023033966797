import React from "react";
import ReactTable from "react-table";
import Loader from "../components/Loader/Loader";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  FormGroup,
  Input,
} from "reactstrap";
import Label from "reactstrap/lib/Label";


class CustomerFollowUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: null,
      loading: false,
      jobsArray: [],
      error: false,
      modalNotice: false,
      selectedCustomer: [],
      selectedTime: '0',
    };

    this.updateEmployee = this.updateEmployee.bind(this);
  }

  componentDidMount() {
    this.loadJobsArray(0);
  }

  loadJobsArray(interval) {
    //Interval = 0 -> two weeks ago. Inverval = 1 -> 1 year ago
    this.setState({ loading: true });

    fetch(global.config.server.connection.url + "/api/CustomerService/MyCustomers?oldUserId=" + sessionStorage.oldUserId + "&interval=" + interval, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({
            loading: false,
            jobsArray: result.map((prop, key) => {
              return {
                id: key,
                jobId: prop["jobId"],
                customerId: prop["customerId"],
                companyName: prop["companyName"],
                companyInvoiceadress: prop["companyInvoiceadress"],
                contactMobileNumber: prop["contactMobileNumber"],
                fgrNr: prop["fgrNr"],
                totalSum: prop["totalSum"],
                phoneColumn: (
                  <div>
                    <a href="javascript:ITXCall('92228805','185Elektris')">
                      <Label style={{ color: 'black', fontSize: 14 + 'px', cursor: 'pointer' }}
                        onClick={() => {
                          let obj = this.state.jobsArray.find(o => o.id === key);
                          this.toggleModalNotice(obj);
                        }}
                      >
                        <i className="fa fa-phone" style={{ color: '#6BD098', paddingRight: 8 + 'px' }} />
                        {prop["contactMobileNumber"]}
                      </Label>{" "}
                    </a>
                  </div>
                ),
              }
            })
          });
        },
        (error) => {
          this.setState({
            loading: false,
            error: true
          });
        }
      )
  }

  async updateEmployee() {
    console.log(JSON.stringify({ FirstName: this.state.selectedCustomer.firstName, LastName: this.state.selectedCustomer.lastName, Email: this.state.selectedCustomer.email, Phone: this.state.selectedCustomer.phone, OldUserId: this.state.selectedCustomer.oldUserId, UserId: this.state.selectedCustomer.userId }))

    try {
      var response = await fetch(global.config.server.connection.url + "/api/Employee/UpdateEmployee", {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
        },
        body: JSON.stringify({ FirstName: this.state.selectedCustomer.firstName, LastName: this.state.selectedCustomer.lastName, Email: this.state.selectedCustomer.email, Phone: this.state.selectedCustomer.phone, OldUserId: this.state.selectedCustomer.oldUserId, UserId: this.state.selectedCustomer.userId })
      })
    }
    catch (ex) {
      this.errorAlert("Something went wrong " + ex.message)
      return;
    }

    if (response.ok) {
      this.setState({
        modalNotice: !this.state.modalNotice,
      });
      this.successAlert("Employee successfully updated")
      return;
    }

    if (!response.ok) {
      this.setState({
        modalNotice: !this.state.modalNotice,
      });
      this.errorAlert("What the hell happened")
      return;
    }
  }

  setStatusDoneClick(jobId) {
    console.log(jobId)

    let obj = this.state.jobsArray.find(object => object.jobId === jobId);
    this.confirmAlert("Er du sikker på at kundeoppfølgingen for " + obj.companyName + " er utført?", obj)
  }

  async onStatusDoneConfirmed(obj) {
    console.log(obj)
    obj.completed = true;
    this.successAlert("Status er endret")
    return false

    // try {
    //   var response = await fetch(global.config.server.connection.url + "/api/Account/Delete?userId=" + obj.userId, {
    //     method: "DELETE",
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json',
    //       'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
    //     },
    //   })
    // } catch (ex) {
    //   this.errorAlert("That did not work. Contact IT if the problem persists.")
    //   return;
    // }

    // if (response.ok) {
    //   var data = this.state.jobsArray
    //   data.splice(this.state.rowToBeDeleted, 1);
    //   this.setState({ jobsArray: data });
    //   this.successAlert("Employee deleted succesfully")
    //   return;
    // }

    // if (!response.ok) {
    //   console.log(response)
    //   this.errorAlert("That did not work. " + response.message)
    //   return;
    // }

    // this.setState({
    //   alert: null,
    // });
  };

  handleChange = (event) => {

    let employee = this.state.selectedCustomer;


    switch (event.target.id) {
      case 'firstName':
        {
          employee.firstName = event.target.value
          this.setState({
            selectedCustomer: employee
          });
          return
        }
      case 'lastName':
        {
          employee.lastName = event.target.value
          this.setState({
            selectedCustomer: employee
          });
          return
        }
      case 'email':
        {
          employee.email = event.target.value
          this.setState({
            selectedCustomer: employee
          });
          return
        }
      case 'phone':
        {
          employee.phone = event.target.value
          this.setState({
            selectedCustomer: employee
          });
          return
        }
      case 'oldUserId':
        {
          employee.oldUserId = event.target.value
          this.setState({
            selectedCustomer: employee
          });
          return
        }
      default:
        return
    }
  };

  selectedTimeChanged = (event) => {

    this.setState({
      selectedTime: event.target.value
    });

    this.loadJobsArray(event.target.value)
  }

  successAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={message}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          Bra jobba!
        </ReactBSAlert>
      )
    });
  };

  errorAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="OPS!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {message}
        </ReactBSAlert>
      )
    });
  };

  confirmAlert = (message, obj) => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          showCancel
          style={{ display: "block" }}
          title="Ferdigstille kundeoppfølging?"
          onConfirm={() => this.onStatusDoneConfirmed(obj)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
        >
          {message}
        </ReactBSAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  toggleModalNotice = (obj) => {
    console.log(obj)
    this.setState({
      modalNotice: !this.state.modalNotice,
      selectedCustomer: obj
    });
  };

  render() {

    const { error, loading } = this.state;

    if (loading) {
      return <Loader />
    }

    if (error) {
      return (
        <div className="content">
          <h2>Ops - Error</h2>
        </div>
      )
    }

    return (
      <>
        {this.state.alert}

        <div className="content">

          <Modal
            isOpen={this.state.modalNotice}
            toggle={this.toggleModalNotice}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.toggleModalNotice}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <h5 className="modal-title" id="myModalLabel">
                Kundeoppfølging
              </h5>
            </div>
            <div className="modal-body">
              <div className="instruction">
                <Row>
                  <Col md="12">
                    <strong>Kunde: </strong>{this.state.selectedCustomer.companyName}
                    <br></br><br></br>

                    <strong>Kommentar: </strong>
                    <FormGroup>
                      <Input id="comment" defaultValue={this.state.selectedCustomer.firstName} type="textarea" onChange={this.handleChange} />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <Button
                className="btn-round"
                color="info"
                data-dismiss="modal"
                type="button"
                onClick={this.updateEmployee}
              >
                Lagre
              </Button>
            </div>
          </Modal>

          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Mine kunder</CardTitle>

                  <p style={{ paddingRight: 10 + 'px', float: 'left' }}>Valgt tid:</p>
                  <Select labelId="label"
                    id="select"
                    style={{ width: 170 + 'px' }}
                    value={this.state.selectedTime}
                    onChange={this.selectedTimeChanged}
                  >
                    <MenuItem value='0'>To uker siden</MenuItem>
                    <MenuItem value="1" >Ett år siden</MenuItem>
                  </Select>

                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.jobsArray}
                    previousText="Forrige"
                    nextText="Neste"
                    filterable
                    columns={[
                      {
                        Header: "Jobb ID",
                        accessor: "jobId",
                        filterable: false,
                        sortable: false,
                        width: 100,
                      },
                      {
                        Header: "Kunde ID",
                        accessor: "customerId",
                        filterable: false,
                        sortable: false,
                        width: 100,
                      },
                      {
                        Header: "Kundenavn",
                        accessor: "companyName",
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: "Adresse",
                        accessor: "companyInvoiceadress",
                        sortable: false,
                        filterable: false
                      },
                      {
                        Header: "Telefon",
                        accessor: "phoneColumn",
                        sortable: false,
                        filterable: false,
                        width: 100,
                      },
                      {
                        Header: "Fgr.Nr.",
                        accessor: "fgrNr",
                        sortable: false,
                        filterable: false,
                        width: 100,
                      },
                      {
                        Header: "Total Sum",
                        accessor: "totalSum",
                        sortable: false,
                        filterable: false,
                        width: 110,
                      },
                      {
                        id: '1',
                        Header: "Utført",
                        accessor: a => (!a.completed ?
                          <Button
                            onClick={() => {
                              this.setStatusDoneClick(a.jobId);
                            }}
                            color="success"
                            size="large"
                            className="btn-icon btn-link remove"

                          >
                            <i className="fa fa-check" />
                          </Button>
                          :
                          <p style={{ fontSize: 12 + 'px', color: "#6BD098", fontWeight: 'bold', textAlign: 'center' }}>Avsluttet</p>),

                        sortable: false,
                        filterable: false,
                        width: 100,
                      },
                    ]}
                    defaultPageSize={5}
                    showPaginationTop
                    showPaginationBottom
                    className="-striped -highlight info-pagination"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default CustomerFollowUp;
