import React from "react";
// react plugin used to create charts
import ReactDatetime from "react-datetime";
import { Pie } from "react-chartjs-2";
import 'chart.piecelabel.js';
import LoadingOverlay from 'react-loading-overlay';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    Table,
    Row,
    Col,
} from "reactstrap";

const pieChart = {
    data: {
        labels: ['Jobber', 'Befaringer', 'Tilbud'],
        datasets: [
            {
                pointRadius: 0,
                pointHoverRadius: 0,
                backgroundColor: ["#6BD098", "#51BCDA", "#fcc468"],
                borderWidth: 0,
                data: []
            }
        ]
    },
    options: {
        cutoutPercentage: 40,
        legend: { display: true, position: "bottom" },
        pieceLabel: {
            render: 'values'
        },
        tooltips: {
            backgroundColor: "#5a6e7f",
        },
    }
};


const o_date = new Intl.DateTimeFormat();
const f_date = (m_ca, m_it) => Object({ ...m_ca, [m_it.type]: m_it.value });
const m_date = o_date.formatToParts().reduce(f_date, {});

if (m_date.month.length < 2) {
    m_date.month = "0" + m_date.month
}
if (m_date.day.length < 2) {
    m_date.day = "0" + m_date.day
}

class MyKPIs extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            company: this.props.company,
            toDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            fromDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            loading: false,
            antallJobber: 0,
            antallBefaringer: 0,
            antallTilbud: 0,
            antallTotalt: 0,
            antallJobberBonusFaktor: 0,
            antallTilbudBonusFaktor: 0,
            antallBefaringerBonusFaktor: 0,
            userImage: "",
            userName: "",
        };

        

        this.fetchData = this.fetchData.bind(this);
    }

    fetchData() {
        this.setState({
            loading: true,
        })

        fetch(global.config.server.connection.url + "/api/CustomerService/GetKPIByEmployee?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate +"&company=" +this.state.company, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        antallJobber: result.antallJobber,
                        antallBefaringer: result.antallBefaringer,
                        antallTilbud: result.antallTilbud,
                        antallTotalt: result.antallTotalt,
                        userImage: result.userImage,
                        antallJobberBonusFaktor: result.antallJobberBonusFaktor,
                        antallTilbudBonusFaktor: result.antallTilbudBonusFaktor,
                        antallBefaringerBonusFaktor: result.antallBefaringerBonusFaktor,
                        loading: false,
                    });
                },
            )
    }


    changeFromDate = (event) => {
        this.setState({ ...this.state, fromDate: event.format("DD-MM-YYYY") })
    }

    changeToDate = (event) => {
        this.setState({ ...this.state, toDate: event.format("DD-MM-YYYY") })
    }

    componentDidMount() {
        this.fetchData()
    }


    render() {

        
        const { loading } = this.state;

        pieChart.data.datasets[0].data = [this.state.antallJobber, this.state.antallBefaringer, this.state.antallTilbud]


        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card className="card-testimonial">

                                <CardHeader>
                                    <Row>
                                        <Col md="4">
                                            <CardTitle tag="h4">Oversikt mine KPI'er</CardTitle>
                                            <div className="card-avatar">
                                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                                    <img
                                                        alt="..."
                                                        className="img"
                                                        src={"data:image/png;base64, " + this.state.userImage}
                                                    />
                                                </a>
                                            </div>
                                        </Col>

                                        <Col md="4">
                                            <Pie
                                                data={pieChart.data}
                                                options={pieChart.options}
                                                width={456}
                                                height={300}
                                            />
                                        </Col>
                                    </Row>

                                </CardHeader>

                                <CardBody>
                                    <Row>
                                        <Col md="4">

                                        </Col>
                                        <Col md="2">
                                            <Label>Fra dato:</Label>
                                            <ReactDatetime
                                                value={this.state.fromDate}
                                                closeOnSelect={true}
                                                dateFormat='DD-MM-YY'
                                                timeFormat={false}
                                                onChange={this.changeFromDate}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Velg fra-dato",
                                                }}
                                            />
                                        </Col>
                                        <Col md="2">
                                            <Label>Til dato:</Label>
                                            <ReactDatetime
                                                value={this.state.toDate}
                                                closeOnSelect={true}
                                                dateFormat='DD-MM-YY'
                                                timeFormat={false}
                                                onChange={this.changeToDate}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Velg til-dato"
                                                }}
                                            />
                                        </Col>
                                        <Col md="4">

                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop: 10 + 'px' }}>
                                        <Col md="12">
                                            <Button className="btn-round" color="danger" onClick={e => this.fetchData()}>
                                                Oppdater
                                            </Button>
                                        </Col>
                                    </Row>

                                    <LoadingOverlay
                                        active={loading}
                                        spinner
                                        styles={{
                                            spinner: (base) => ({
                                                ...base,
                                                width: '150px',
                                                '& svg circle': {
                                                    stroke: 'rgba(239, 129, 87, 1)'
                                                }
                                            }),
                                            content: (base) => ({
                                                ...base,
                                                color: 'red',
                                            })
                                        }}
                                        text='vent litt...'
                                    >
                                    </LoadingOverlay>

                                    <Table className="table-shopping" responsive>
                                        <thead>
                                            <tr>
                                                <th className="text-center" />
                                                <th></th>
                                                <th />
                                                <th />
                                                <th className="text-right">Antall jobber</th>
                                                <th className="text-right">Opptjent</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>
                                                    <i className='fab fa-creative-commons-nc fa-3x'>
                                                        <i className="nc-icon nc-chart-bar-32 text-success" />
                                                    </i>
                                                </td>
                                                <td className="td-name">
                                                    <h5 className="text-success" style={{ marginBottom: 0 + 'px', fontSize: 1 + 'em' }}>Antall jobber booket</h5>
                                                    <small>Alle jobber, unntatt befaringer</small>
                                                </td>
                                                <td />
                                                <td />
                                                <td className="td-number">
                                                    <small>{this.state.antallJobber}</small>
                                                </td>

                                                <td className="td-number">
                                                    <small>NOK {this.state.antallJobberBonusFaktor},-</small>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <i className='fab fa-creative-commons-nc fa-3x'>
                                                        <i className="nc-icon nc-chart-bar-32 text-info" />
                                                    </i>
                                                </td>
                                                <td className="td-name">
                                                    <h5 className="text-info" style={{ marginBottom: 0 + 'px', fontSize: 1 + 'em' }}>Antall befaringer booket</h5>
                                                    <small>Alle befaringer du har booket i perioden</small>
                                                </td>
                                                <td />
                                                <td />
                                                <td className="td-number">
                                                    <small>{this.state.antallBefaringer}</small>
                                                </td>

                                                <td className="td-number">
                                                    <small>NOK {this.state.antallBefaringerBonusFaktor},-</small>

                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <i className='fab fa-creative-commons-nc fa-3x'>
                                                        <i className="nc-icon nc-chart-bar-32 text-warning" />
                                                    </i>
                                                </td>
                                                <td className="td-name">
                                                    <h5 className="text-warning" style={{ marginBottom: 0 + 'px', fontSize: 1 + 'em' }}> Antall tilbud</h5>
                                                    <small>Alle tilbud du har booket i perioden</small>
                                                </td>
                                                <td />
                                                <td />
                                                <td className="td-number">
                                                    <small>{this.state.antallTilbud}</small>
                                                </td>

                                                <td className="td-number">
                                                    <small>NOK {this.state.antallTilbudBonusFaktor},-</small>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colSpan="3" />
                                                <td className="td-total">Totalt:</td>
                                                <td className="td-number">
                                                    <small style={{ fontWeight: 'bold' }}>{this.state.antallTotalt}</small>
                                                </td>
                                                <td className="td-total">
                                                    <small style={{ fontWeight: 'bold' }}>NOK {(this.state.antallJobberBonusFaktor) + this.state.antallBefaringerBonusFaktor + this.state.antallTilbudBonusFaktor},-</small>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default MyKPIs;
