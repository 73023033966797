import React from "react";
import ReactTable from "react-table";
import Loader from "../components/Loader/Loader";
import ReactBSAlert from "react-bootstrap-sweetalert";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  FormGroup,
  Input,
} from "reactstrap";


class ViewEmployees extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: null,
      loading: false,
      employeeArray: [],
      error: false,
      modalNotice: false,
      selectedEmployee: [],
    };

    this.updateEmployee = this.updateEmployee.bind(this);
  }


  componentDidMount() {
    this.setState({ loading: true });

    //Load employeeArray
    fetch(global.config.server.connection.url + "/api/Employee/Employees", {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            loading: false,
            employeeArray: result.map((prop, key) => {
              return {
                id: key,
                userId: prop["id"],
                userImageRaw: "data:image/png;base64, " + prop["userImage"],
                userImage: <div className="avatar" style={{ width: '50px', height: '50px', marginBottom: '0px' }}><img alt="user" src={"data:image/png;base64, " + prop["userImage"]} /></div>,
                firstName: prop["firstName"],
                lastName: prop["lastName"],
                company: prop["company"],
                warehouseId: prop["warehouseId"],
                email: prop["email"],
                userName: prop["userName"],
                phone: prop["phoneNumber"],
                role: prop["roleId"],
                oldUserId: prop["oldUserId"],
                team: prop["team"],
                actions: (
                  // we've added some custom button actions
                  <div className="actions-right">
                    {/* button to edit employee */}
                    <Button
                      onClick={() => {
                        let obj = this.state.employeeArray.find(o => o.id === key);
                        this.toggleModalNotice(obj);
                      }}
                      color="success"
                      size="md"
                      className="btn-icon btn-link edit"
                    >
                      <i className="fa fa-edit" />
                    </Button>{" "}
                    {/* button to delete employee */}
                    <Button
                      onClick={() => {
                        var data = this.state.employeeArray;
                        data.find((o, i) => {
                          if (o.id === key) {
                            //data.splice(i, 1);
                            this.setState({
                              rowToBeDeleted: i
                            });
                            this.deleteEmployee(o);
                            return true;
                          }
                          return false;
                        });
                        this.setState({ employeeArray: data });
                      }}
                      color="danger"
                      size="md"
                      className="btn-icon btn-link remove"
                    >
                      <i className="fa fa-times" />
                    </Button>{" "}
                  </div>
                )
              }
            })
          });
        },
        (error) => {
          this.setState({
            loading: false,
            error: true
          });
        }
      )
  }


  async updateEmployee() {
    console.log(JSON.stringify({ FirstName: this.state.selectedEmployee.firstName, LastName: this.state.selectedEmployee.lastName, Email: this.state.selectedEmployee.email, Phone: this.state.selectedEmployee.phone, OldUserId: this.state.selectedEmployee.oldUserId, UserId: this.state.selectedEmployee.userId }))

    try {
      var response = await fetch(global.config.server.connection.url + "/api/Employee/UpdateEmployee", {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
        },
        body: JSON.stringify({ FirstName: this.state.selectedEmployee.firstName, LastName: this.state.selectedEmployee.lastName, Email: this.state.selectedEmployee.email, Phone: this.state.selectedEmployee.phone, OldUserId: this.state.selectedEmployee.oldUserId, UserId: this.state.selectedEmployee.userId })
      })
    }
    catch (ex) {
      this.errorAlert("Something went wrong " + ex.message)
      return;
    }

    if (response.ok) {
      this.setState({
        modalNotice: !this.state.modalNotice,
      });
      this.successAlert("Employee successfully updated")
      return;
    }

    if (!response.ok) {
      this.setState({
        modalNotice: !this.state.modalNotice,
      });
      this.errorAlert("What the hell happened")
      return;
    }
  }

  deleteEmployee(obj) {
    this.confirmAlert("Er du sikker på at du vil slette " + obj.firstName + " " + obj.lastName + " from employees?", obj)
  }

  async onConfirmDelete(obj) {

    try {
      var response = await fetch(global.config.server.connection.url + "/api/Account/Delete?userId=" + obj.userId, {
        method: "DELETE",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
        },
      })
    } catch (ex) {
      this.errorAlert("OPS - Rusk i maskineriet. Kontakt IT dersom problemet vedvarer")
      return;
    }

    if (response.ok) {
      var data = this.state.employeeArray
      data.splice(this.state.rowToBeDeleted, 1);
      this.setState({ employeeArray: data });
      this.successAlert("Employee deleted succesfully")
      return;
    }

    if (!response.ok) {
      console.log(response)
      this.errorAlert("That did not work. " + response.message)
      return;
    }

    this.setState({
      alert: null,
    });
  };


  handleChange = (event) => {

    let employee = this.state.selectedEmployee;


    switch (event.target.id) {
      case 'firstName':
        {
          employee.firstName = event.target.value
          this.setState({
            selectedEmployee: employee
          });
          return
        }
      case 'lastName':
        {
          employee.lastName = event.target.value
          this.setState({
            selectedEmployee: employee
          });
          return
        }
      case 'email':
        {
          employee.email = event.target.value
          this.setState({
            selectedEmployee: employee
          });
          return
        }
      case 'phone':
        {
          employee.phone = event.target.value
          this.setState({
            selectedEmployee: employee
          });
          return
        }
      case 'oldUserId':
        {
          employee.oldUserId = event.target.value
          this.setState({
            selectedEmployee: employee
          });
          return
        }
      default:
        return
    }
  };


  successAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={message}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          You are awesome!
        </ReactBSAlert>
      )
    });
  };

  errorAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="OPS!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {message}
        </ReactBSAlert>
      )
    });
  };

  confirmAlert = (message, obj) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title="Delete employee?"
          onConfirm={() => this.onConfirmDelete(obj)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="info"
        >
          {message}
          <br></br><br></br>
          <span style={{ fontSize: '.9em', color: '#EB6532' }}><i>If the employee has an associated <b> warehouse</b>, the warehouse will be made available to another employee</i></span>
        </ReactBSAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  toggleModalNotice = (obj) => {
    this.setState({
      modalNotice: !this.state.modalNotice,
      selectedEmployee: obj
    });
  };

  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };


  render() {

    const { error, loading } = this.state;

    if (loading) {
      return <Loader />
    }

    if (error) {
      return (
        <div className="content">
          <h2>Ops - Error</h2>
        </div>
      )
    }

    return (
      <>
        {this.state.alert}

        <div className="content">

          <Modal
            isOpen={this.state.modalNotice}
            toggle={this.toggleModalNotice}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={this.toggleModalNotice}
              >
                <i className="nc-icon nc-simple-remove" />
              </button>
              <h5 className="modal-title" id="myModalLabel">
                Edit employee data
              </h5>
            </div>
            <div className="modal-body">
              <div className="instruction">
                <Row>
                  <Col md="8">
                    <strong>Username: </strong>{this.state.selectedEmployee.userName}
                    <br></br>

                    <strong>Company: </strong>{this.state.selectedEmployee.company}
                    <br></br><br></br>

                    <strong>First name: </strong>
                    <FormGroup>
                      <Input id="firstName" defaultValue={this.state.selectedEmployee.firstName} type="text" onChange={this.handleChange} />
                    </FormGroup>

                    <strong>Last name: </strong>
                    <FormGroup>
                      <Input id="lastName" defaultValue={this.state.selectedEmployee.lastName} type="text" onChange={this.handleChange} />
                    </FormGroup>

                    <strong>Email: </strong>
                    <FormGroup>
                      <Input id="email" defaultValue={this.state.selectedEmployee.email} type="text" onChange={this.handleChange} />
                    </FormGroup>

                    <strong>Phone: </strong>
                    <FormGroup>
                      <Input id="phone" defaultValue={this.state.selectedEmployee.phone} type="text" onChange={this.handleChange} />
                    </FormGroup>

                    <strong>Old User ID: </strong>
                    <FormGroup>
                      <Input id="oldUserId" defaultValue={this.state.selectedEmployee.oldUserId} type="text" onChange={this.handleChange} />
                    </FormGroup>
                  </Col>

                  <Col md="4">
                    <div>
                      <img
                        alt="..."
                        className="avatar border-gray"
                        src={this.state.selectedEmployee.userImageRaw}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <Button
                className="btn-round"
                color="info"
                data-dismiss="modal"
                type="button"
                onClick={this.updateEmployee}
              >
                Good to go
              </Button>
            </div>

          </Modal>



          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">All Employees</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.employeeArray}
                    filterable
                    columns={[
                      {
                        accessor: "userImage",
                        sortable: false,
                        filterable: false,
                        width: 60,
                      },
                      {
                        Header: "Fornavn",
                        accessor: "firstName",
                        filterable: false
                      },
                      {
                        Header: "Etternavn",
                        accessor: "lastName",
                        filterable: false
                      },
                      {
                        Header: "Team",
                        accessor: "team",
                        filterable: false,
                        width: 80,
                      },
                      {
                        Header: "Telefon",
                        accessor: "phone",
                        filterable: false
                      },
                      {
                        Header: "Epost",
                        accessor: "email",
                        filterable: false
                      },
                      {
                        Header: "RegNr.",
                        accessor: "warehouseId",
                        filterable: false
                      },
                      {
                        Header: "Gammel Id",
                        accessor: "oldUserId",
                        filterable: false
                      },
                      {
                        Header: "Valg",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        width: 100
                      }
                    ]}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom
                    /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
                    className="-striped -highlight info-pagination"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ViewEmployees;
