import React from "react";
import Loader from "../Loader/Loader";
import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
} from "reactstrap";

const o_date = new Intl.DateTimeFormat();
const f_date = (m_ca, m_it) => Object({ ...m_ca, [m_it.type]: m_it.value });
const m_date = o_date.formatToParts().reduce(f_date, {});

if (m_date.month.length < 2) {
    m_date.month = "0" + m_date.month
}
if (m_date.day.length < 2) {
    m_date.dat = "0" + m_date.day
}

class StatsBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            fromDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            isLoading: false,
            spinnerActive: false,
            loadingBookingsToday: true,
            loadingComplaints: true,
            loadingBookings: false,
            bookingsSubArray: [],
            jobTypeRadioArray: [],
            bookingsArray: [],
            reklamasjon: 0,
            tilbud: 0,
            tilbudAkseptert: 0,
            selectedCompany: 0,
            bookings: "",
            complaints: "",
            openedCollapses: [],
            selectedUserName: "",
            expanded: {}
        };
    }

    componentDidMount() {
        
        var urlJobsToday = ""
        var urlComplaintsToday = ""

        if (this.props.company === "boligrorleggeren") {
            urlJobsToday = "/api/Dashboards/JobsTodayBoligrorleggeren"
            urlComplaintsToday = "/api/Dashboards/ComplaintsTodayBoligrorleggeren"
        } else {
            urlJobsToday = "/api/Dashboards/JobsTodayAllCompanies"
            urlComplaintsToday = "/api/Dashboards/ComplaintsTodayAllCompanies"
        }


        fetch(global.config.server.connection.url + urlJobsToday, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        loadingBookingsToday: false,
                        bookings: result
                    });
                },
            )

        fetch(global.config.server.connection.url + urlComplaintsToday, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        loadingComplaints: false,
                        complaints: result
                    });
                },
            )

        fetch(global.config.server.connection.url + "/api/Reports/GetJobsDonePrCompany?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate +"&companyName=" +this.props.company, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        loadingBookings: false,
                        spinnerActive: false,
                        bookingsArray: result.map((prop, key) => {
                            return {
                                id: key,
                                companyName: prop["companyName"],
                                numberOfBookings: prop["numberOfBookings"],
                            }
                        })
                    });
                },
            )

    }

    render() {

        const { loadingBookingsToday, loadingComplaints, loadingBookings } = this.state;

        if (loadingBookingsToday || loadingComplaints || loadingBookings) {
            return <Loader />
        }

        return (
            <>
                <Row>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-chart-bar-32 text-warning" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Bookinger i fjor</p>
                                            <CardTitle tag="p">
                                                {this.state.bookings[0][0]}
                                            </CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fa fa-calendar" /> På denne dag, for ett år siden
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>

                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-chart-bar-32 text-warning" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Bookinger i dag</p>
                                            <CardTitle tag="p">
                                                {this.state.bookings[0][1]}
                                            </CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fa fa-calendar" />Totalt antall bookinger i dag
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>

                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-favourite-28 text-primary" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Budsjett</p>
                                            <CardTitle tag="p">
                                                {this.props.company === 'boligelektrikeren' ? '33' : this.props.company === 'boligrorleggeren' ? '0' : '55'}
                                            </CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fa fa-bar-chart" />Budsjetterte bookinger i dag
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>


                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-ambulance text-danger" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Reklamasjoner</p>
                                            <CardTitle tag="p"> {this.state.complaints}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <i className="fa fa-thumbs-down" /> Reklamasjoner booket i dag
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

export default StatsBar;
