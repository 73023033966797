import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/Auth.jsx";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/css/loader.css";
import { AuthRequired } from "helpers/AuthRequired";


const hist = createBrowserHistory();

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {      
      redirectToReferrer: false
    };
  } 

    render() {

      return (
        <Router history={hist}>
        <Switch>
        <Route 
          path="/admin" 
          render={() => (
            <AuthRequired
            />)}
          />
          <Route path="/auth" render={props => <AuthLayout {...props} />} />
          <Redirect to="/auth/login" />
        </Switch>
      </Router>
      )
    }
  }
  
  export default App;