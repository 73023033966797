import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import routes from "routes.js";

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      access_token: false,
      isAdmin: this.props.isAdmin,
    };
  }

  componentDidMount() {
    // Initialize PerfectScrollbar if needed
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }

  componentWillUnmount() {
    // Clean up PerfectScrollbar on unmount
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(prevProps) {
    // Reset scroll position on route change
    if (this.props.location !== prevProps.location) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

getRoutes = (routes) => {
  return routes.map((prop, key) => {
    if (prop.collapse) {
      return this.getRoutes(prop.views);
    }
    if (prop.layout === "/admin") {
      return (
        <Route
          key={key}
          path={prop.layout + prop.path}
          exact={prop.exact}
          component={(routeProps) => (
            <prop.component
              {...routeProps}
              isAdmin={this.state.isAdmin}
              company={prop.company}
            />
          )}
        />
      );
    } else {
      return null;
    }
  });
};

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  handleMiniClick = () => {
    this.setState((prevState) => ({
      sidebarMini: !prevState.sidebarMini,
    }));
    document.body.classList.toggle("sidebar-mini");
  };

  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          isAdmin={this.state.isAdmin}
        />
        <div className="main-panel" ref="mainPanel">
          <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
          <Switch>{this.getRoutes(routes)}</Switch>
          {this.props.location.pathname.indexOf("full-screen-map") !== -1 ? null : (
            <Footer fluid />
          )}
        </div>
      </div>
    );
  }
}

export default Admin;
