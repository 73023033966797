import React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import '../Popup.scss';


function SjekklisteStandardarbeid(props) {
  console.log("THE PROPS =>>", props)

  function getStandardarbeidSources() {
    this.setState({
        loading: true,
    })

    fetch(global.config.server.connection.url + "/api/Booking/GetStandardarbeidSources", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
        },
    })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    loading: false,
                    standardarbeidOKArray: result.filter(function (standardarbeid) {
                        return standardarbeid.group === 1;
                    }),
                    standardarbeidNotOKArray: result.filter(function (standardarbeid) {
                        return standardarbeid.group === 0;
                    }),
                    standardarbeidInternalArray: result.filter(function (standardarbeid) {
                        return standardarbeid.group === 2;
                    }),
                });
            },
            (error) => {
                this.setState({
                    error
                });
            }
        )
}
 
  return (
    <div className='popup' style={{ overflow: 'scroll', display: props.showKontrollSkjema }}>
      <header style={{ textAlign: 'center' }}>
        Sjekkliste for standardarbeid
      </header>

      <article>
        <FormControl component="fieldset">
          <RadioGroup
            onChange={props.standardarbeidRadioChange}
          //defaultValue="Alle generelle småservicejobber, og alt som faller naturlig inn under dette."
          >
            <div style={{ textAlign: 'center', paddingBottom: 1 + 'em' }}>
              <Button style={{ background: '#EF8157', color: '#fff', marginRight: 1 + 'em', width: 98, height: 36 }} onClick={props.closePopup}>Avbryt</Button>
              <Button style={{ background: '#6BD098', color: '#fff', marginRight: 1 + 'em', width: 98, height: 36 }} onClick={props.nextClickHandler}>Neste</Button>
            </div>

            <FormLabel component="legend" style={{ fontWeight: 'bold', color: '#A0A0A0', textDecoration: 'underline', textIndent: 20 + 'px' }}>INTERNT</FormLabel>

            {props.standardarbeidInternalArray.map(v => (
              <FormControlLabel
                value={v.name}
                control={<Radio id={String(v.id)} />}
                label={v.name}
                key={v.id}
              />
            ))}
            <FormControlLabel
              value='Syk med egenmelding'
              control={<Radio id='26' />}
              label='Syk med egenmelding'
              key='26'
            />
            <FormControlLabel
              value='Syk med sykemelding'
              control={<Radio id='21' />}
              label='Syk med sykemelding'
              key='21'
            />

            <br></br>

            <FormLabel component="legend" style={{ fontWeight: 'bold', color: '#6BD098', textDecoration: 'underline', textIndent: 20 + 'px' }}>JOBBER VI SKAL TA PÅ OSS</FormLabel>
            {props.standardarbeidOKArray.map(v => (
              <FormControlLabel
                value={v.name}
                control={<Radio id={String(v.id)} />}
                label={v.name}
                key={v.id}

              />
            ))}

            <br></br>

            <FormLabel component="legend" style={{ fontWeight: 'bold', color: '#EF8157', textDecoration: 'underline', textIndent: 20 + 'px' }}>JOBBER VI IKKE SKAL TA PÅ OSS</FormLabel>
            {props.standardarbeidNotOKArray.map(v => (
              <FormControlLabel
                value={v.name}
                control={<Radio id={String(v.id)} />}
                label={v.name}
                key={v.id}
              />
            ))}

          </RadioGroup>
        </FormControl>

      </article>

      <footer>
        <Button style={{ background: '#EF8157', color: '#fff' }} onClick={props.closePopup}>Avbryt</Button>
        <Button style={{ background: '#6BD098', color: '#fff' }} onClick={props.nextClickHandler}>Neste</Button>
      </footer>
    </div>
  )
}

export default SjekklisteStandardarbeid;