export const prognose = [
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 95
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 95
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 95
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 95
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 95
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 95
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 95
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 95
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 95
    },
    {
        "omsetning": 8314633,
        "antallJobber": 705,
        "snittOmsetning": 12500,
        "kortbetaling": 95
    },
    {
        "omsetning": 9200000,
        "antallJobber": 750,
        "snittOmsetning": 12500,
        "kortbetaling": 95
    },
    {
        "omsetning": 7019500,
        "antallJobber": 593,
        "snittOmsetning": 12500,
        "kortbetaling": 95
    },
]


export const budsjett = [
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 97
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 97
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 97
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 97
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 97
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 97
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 97
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 97
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 97
    },
    {
        "omsetning": 10000000,
        "antallJobber": 35,
        "snittOmsetning": 12500,
        "kortbetaling": 97
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 97
    },
    {
        "omsetning": 10000000,
        "antallJobber": 38,
        "snittOmsetning": 12500,
        "kortbetaling": 97
    },
]