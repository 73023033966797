import React from "react";
// react plugin used to create a form with multiple steps
import ReactWizard from "react-bootstrap-wizard";

// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import { Col } from "reactstrap";

// loader
import Loader from "components/Loader/Loader";

// wizard steps
import Step1 from "./WizardCustomerSteps/CustomerStep1.jsx";
import Step2 from "./WizardCustomerSteps/CustomerStep2.jsx";
import Step3 from "./WizardCustomerSteps/CustomerStep3.jsx";

var steps = [
  {
    stepName: "Account",
    stepIcon: "nc-icon nc-touch-id",
    component: Step1
  },
  {
    stepName: "User Role",
    stepIcon: "nc-icon nc-single-02",
    component: Step2
  },
  {
    stepName: "User Info",
    stepIcon: "nc-icon nc-pin-3",
    component: Step3
  }
];

class WizardCustomer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      alert: null,
      loading: false,
      error: null
    };

    this.finishButtonClick = this.finishButtonClick.bind(this);
  }

  successAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Customer registered!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          Have a great day.
        </ReactBSAlert>
      )
    });
  };

  errorAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="OPS!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          Something went wrong, pleasy try again.
        </ReactBSAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  finishButtonClick(allStates) {
    this.setState({ loading: true });

    // //save everything 
    fetch(global.config.server.connection.url + "/api/Account/Register", {

      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
      },
      body: JSON.stringify({
        userName: allStates["Account"].username,
        userImge: allStates["Account"].imageUrl,
        Password: 'rokea123',
        confirmPassword: 'rokea123',
        phoneNumber: allStates["Account"].phone,
        email: allStates["Account"].email,
        userRole: allStates["User Role"].userRightValue,
        department: allStates["User Role"].departmentID,
        firstName: allStates["User Info"].firstName,
        lastName: allStates["User Info"].lastName,
        startDate: allStates["User Info"].startDate,
      })
    })
      .then(response => {
        if (response.ok) {
          this.setState({
            loading: false
          });
          console.log("USER CREATED")
          this.successAlert()
        } else {
          this.errorAlert();
          throw new Error('Something went wrong ...Call log API');
        }
      })
      .catch(error => this.setState({ error, loading: false }));
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return (
        <div className="content">
          <div className="wizard-container">
            <Loader />
          </div>
        </div>
      )
    }

    return (
      <>
        {this.state.alert}

        <div className="content">

          <Col className="mr-auto ml-auto" md="10">
            <ReactWizard
              steps={steps}
              navSteps
              validate
              title="Register new customer"
              description=""
              headerTextCenter
              finishButtonClasses="btn-primary"
              nextButtonClasses="btn-primary"
              previousButtonClasses="btn-wd"
              finishButtonClick={this.finishButtonClick}
            />
          </Col>

        </div>



      </>
    );
  }
}

export default WizardCustomer;
