import React from "react";
import classnames from "classnames";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col
} from "reactstrap";

class ChangePwd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      loading: false,
      oldPwd: "",
      newPwd: "",
      repPwd: "",
      oldPwdState: "",
      newPwdState: "",
      repPwdState: ""
    };

    this.buttonClick = this.buttonClick.bind(this);

  }

  successAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Password changed!"
          onConfirm={() => this.hideSuccessAlert()}
          onCancel={() => this.hideSuccessAlert()}
          confirmBtnBsStyle="info"
        >
          Take care now.
        </ReactBSAlert>
      )
    });
  };

  errorAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Auch!"
          onConfirm={() => this.hideErrorAlert()}
          onCancel={() => this.hideErrorAlert()}
          confirmBtnBsStyle="info"
        >
          That did not work. Please try again.
        </ReactBSAlert>
      )
    });
  };

  hideSuccessAlert = () => {
    this.setState({
      alert: null
    });
    window.location.replace("/admin/dashboard")
  };

  hideErrorAlert = () => {
    this.setState({
      alert: null
    });
  };

  change = (event, stateName) => {

    switch (stateName) {
      case "oldPwd":
        if (this.verifyLenght(event.target.value, 8)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "newPwd":
        if (this.verifyPwd(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "repPwd":
        if (this.verifyRepeatedPwd(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }



    this.setState({ [stateName]: event.target.value });
  }

  onBlur = (value, stateName) => {
    this.setState({ [stateName + "Focus"]: false });
    this.setState({ stateName: value.target.value });
  }

  verifyPwd = value => {
    var pwdRex = /^(?=.*\d){2,}(?=.*[a-zA-Z]).{8,}$/;
    if (pwdRex.test(value)) {
      return true;
    }
    return false;
  };


  verifyLenght = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  verifyRepeatedPwd = (value) => {
    if (value === this.state.newPwd) {
      return true;
    }
    return false;
  };

  isValidated = () => {
    if (
      this.state.oldPwdState === "has-success" &&
      this.state.newPwdState === "has-success" &&
      this.state.repPwdState === "has-success"
    ) {
      return true;
    } else {
      if (this.state.oldPwdState !== "has-success") {
        this.setState({ oldPwdState: "has-danger" });
      }
      if (this.state.newPwdState !== "has-success") {
        this.setState({ newPwdState: "has-danger" });
      }
      if (this.state.repPwdState !== "has-success") {
        this.setState({ repPwdState: "has-danger" });
      }
      return false;
    }
  };

  buttonClick() {
    // this.setState({ loading: true });

    if (this.isValidated()) {
      this.setState({ loading: true });

      // //save everything 
      //  fetch("https://localhost:44347/api/Account/ChangePassword", {
      fetch(global.config.server.connection.url + "/api/Account/ChangePassword", {

        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
        },
        body: JSON.stringify({
          oldPassword: this.state.oldPwd,
          newPassword: this.state.newPwd
        })
      })
        .then(response => {
          if (response.ok) {
            this.setState({
              loading: false
            });
            console.log("PWD CHANGED")
            this.successAlert()
          } else {
            this.setState({
              loading: false
            });
            console.log("PWD NOT CHANGED")
            this.errorAlert();
            throw new Error('Something went wrong ...Call log API');
          }
        })
        .catch(error => this.setState({ error, loading: false }));

    }
  }

  render() {
    return (
      <>
        {this.state.alert}

        <div className="content">
          <Row>
            <Col md="12">
              <Card>

                <CardHeader>
                  <CardTitle tag="h4">Reset Password</CardTitle>
                </CardHeader>

                <CardBody>

                  <Row>
                    <Label sm="2">Current Password</Label>
                    <Col sm="10">

                      <InputGroup
                        className={classnames(this.state.oldPwdState, {
                          "input-group-focus": this.state.oldPwdFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>

                          </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          name="oldPwd"
                          type="password"
                          value={this.state.oldPwd}
                          onChange={e => this.change(e, "oldPwd")}
                          onFocus={e => this.setState({ oldPwdFocus: true })}
                          onBlur={e => this.onBlur(e, "oldPwd")}
                        />
                        {this.state.oldPwdState === "has-danger" ? (
                          <label className="error">Passwords are minimum 8 characters</label>
                        ) : null}
                      </InputGroup>

                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">New Password</Label>
                    <Col sm="10">

                      <InputGroup
                        className={classnames(this.state.newPwdState, {
                          "input-group-focus": this.state.newPwdFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>

                          </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          name="newPwd"
                          type="password"
                          value={this.state.newPwd}
                          onChange={e => this.change(e, "newPwd")}
                          onFocus={e => this.setState({ newPwdFocus: true })}
                          onBlur={e => this.onBlur(e, "newPwd")}
                        />
                        {this.state.newPwdState === "has-danger" ? (
                          <label className="error">A valid password is minimum 8 characters containint at least one number</label>
                        ) : null}
                      </InputGroup>

                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Retype new Password</Label>
                    <Col sm="10">

                      <InputGroup
                        className={classnames(this.state.repPwdState, {
                          "input-group-focus": this.state.repPwdFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>

                          </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          name="oldPwd"
                          type="password"
                          value={this.state.repPwd}
                          onChange={e => this.change(e, "repPwd")}
                          onFocus={e => this.setState({ repPwdFocus: true })}
                          onBlur={e => this.onBlur(e, "repPwd")}
                        />
                        {this.state.repPwdState === "has-danger" ? (
                          <label className="error">The passwords must match</label>
                        ) : null}
                      </InputGroup>

                    </Col>
                  </Row>

                </CardBody>

                <CardFooter>
                  <Button className="btn-round" color="info" onClick={this.buttonClick}>
                    Change my password
                  </Button>
                </CardFooter>

              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ChangePwd;
