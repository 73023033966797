import React from "react";
import Loader from "../components/Loader/Loader";
import ReactTable from "react-table";
import ReactDatetime from "react-datetime";
import { prognose } from "../components/Budget/budget"
import html2canvas from 'html2canvas';
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    Row,
    Col,
} from "reactstrap";

const d = new Date();
const monthNumber = d.getMonth();


const o_date = new Intl.DateTimeFormat();
const f_date = (m_ca, m_it) => Object({ ...m_ca, [m_it.type]: m_it.value });
const m_date = o_date.formatToParts().reduce(f_date, {});

if (m_date.month.length < 2) {
    m_date.month = "0" + m_date.month
}
if (m_date.day.length < 2) {
    m_date.dat = "0" + m_date.day
}

var tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
tomorrow = tomorrow.toISOString().split('T')[0]

const getTotals = (data, key) => {
    let total = 0;
    data.forEach(item => {
        total += item[key];
    });
    return total;
};

const getSnittOmsetning = (data, key) => {
    let sum = 0;
    data.forEach(item => {
        sum += item[key];
    });
    let snittOmsetning = Math.round(sum / data.length, 2)
    return snittOmsetning;
};

// const getKortbetaling = (data, key) => {
//     let sum = 0;
//     data.forEach(item => {
//         sum += item[key];
//     });
//     let kortbetaling = sum / data.length

//     let result = Math.round(kortbetaling * 10) / 10;

//     return result;

// };

class DailyCheckOut extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            company: props.company,
            toDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            fromDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            revenueCurrentMonth: 0,
            bookingsSubArray: [],
            dailyCheckOutArray: [],
            dailyCheckOutArrayOriginal: [],
            bookings: "",
            openedCollapses: [],
            expanded: {},
            loadingBookings: false,
            loadingNextDay: true,
            loadingCardPaymentPercentage: true,
        };

        this.getData = this.getData.bind(this);
        this.sendReport = this.sendReport.bind(this);
        this.getWeekdaysInMonth = this.getWeekdaysInMonth.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    async getData() {
        //revenue current month
        const { company } = this.state;
        const url = `${global.config.server.connection.url}/api/Reports/GetRevenueCurrentMonth?companyName=${company}`;
        fetch(url, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        revenueCurrentMonth: result,
                    });
                },
            )

        //jobs booked next day
        await fetch(global.config.server.connection.url + "/api/Reports/GetJobsNextDay?nextDayDate=" + tomorrow +"&companyName=" +company, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        jobsNextDay: result,
                        loadingNextDay: false,
                    });
                },
            )

        //card payment percentage of total revenue
        await fetch(global.config.server.connection.url + "/api/Reports/GetCardPaymentPercentage?date=" + this.state.fromDate +"&companyName=" +company, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        cardPaymentPercentage: result,
                        loadingCardPaymentPercentage: false,
                    });
                },
            )


        //salesreport
        fetch(global.config.server.connection.url + "/api/Reports/GetDailyCheckOut?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate +"&companyName=" +company, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        dailyCheckOutArray: result.map((prop, key) => {
                            return {
                                id: prop["id"],
                                name: prop["name"],
                                antallJobber: prop["antallJobber"],
                                snittOmsetning: this.toCurrency(prop["snittOmsetning"]),
                                snittOmsetningAlt: prop["snittOmsetning"],
                                totalOmsetning: this.toCurrency(prop["totalOmsetning"]),
                                totalOmsetningAlt: prop["totalOmsetning"],
                                kortBetaling: prop["kortBetaling"],
                            }
                        }),
                        dailyCheckOutArrayOriginal: result.map((prop, key) => {
                            return {
                                id: prop["id"],
                                name: prop["name"],
                                antallJobber: prop["antallJobber"],
                                snittOmsetning: this.toCurrency(prop["snittOmsetning"]),
                                snittOmsetningAlt: prop["snittOmsetning"],
                                totalOmsetning: this.toCurrency(prop["totalOmsetning"]),
                                totalOmsetningAlt: prop["totalOmsetning"],
                                kortBetaling: prop["kortBetaling"],
                            }
                        })
                    });

                    //totalt
                    this.state.dailyCheckOutArray.push({
                        id: '',
                        name: <div><b>TOTALT: </b></div>,
                        totalOmsetning: <div><b> {this.toCurrency(getTotals(this.state.dailyCheckOutArray, "totalOmsetningAlt"))} </b></div>,
                        antallJobber: <div><b> {getTotals(this.state.dailyCheckOutArray, "antallJobber")} </b></div>,
                        snittOmsetning: <div><b> {this.toCurrency(getSnittOmsetning(this.state.dailyCheckOutArray, "snittOmsetningAlt"))} </b></div>,
                        kortBetaling: <div><b> {this.state.cardPaymentPercentage} </b></div>,
                        jobberNesteDag: <div><b> {this.state.jobsNextDay} </b></div>,
                        isSumRow: true,
                    })

                    //prognose
                    this.state.dailyCheckOutArray.push({
                        id: '',
                        name: <div><b>PROGNOSE:</b></div>,
                        totalOmsetning: <div><b> {this.toCurrency(Math.round((prognose[monthNumber].omsetning - this.state.revenueCurrentMonth) / this.getBusinessDatesCount()))} </b></div>, //getRemanningDays
                        antallJobber: <div><b>{Math.round(prognose[monthNumber].antallJobber / this.getWeekdaysInMonth(monthNumber, 2022))*10/10}</b></div>,
                        snittOmsetning: <div><b>{this.toCurrency(prognose[monthNumber].snittOmsetning)}</b></div>,
                        kortBetaling: <div><b> {prognose[monthNumber].kortbetaling} </b></div>,
                    })

                    //avvik
                    this.state.dailyCheckOutArray.push({
                        id: '',
                        name: <div><b>AVVIK:</b></div>,
                        totalOmsetning: <div><b> {this.toCurrency(Math.round(getTotals(this.state.dailyCheckOutArrayOriginal, "totalOmsetningAlt") - (prognose[monthNumber].omsetning - this.state.revenueCurrentMonth) / this.getBusinessDatesCount()))} </b></div>,
                        antallJobber: <div><b> {Math.round(getTotals(this.state.dailyCheckOutArrayOriginal, "antallJobber") - prognose[monthNumber].antallJobber / this.getWeekdaysInMonth(monthNumber, 2022))}</b></div>,
                        snittOmsetning: <div><b>{this.toCurrency(getSnittOmsetning(this.state.dailyCheckOutArrayOriginal, "snittOmsetningAlt") - prognose[monthNumber].snittOmsetning)}</b></div>,
                        kortBetaling: <div><b> {Math.round((this.state.cardPaymentPercentage - prognose[monthNumber].kortbetaling) * 10) / 10} </b></div>,
                    })


                    this.setState({
                        loadingBookings: false,
                        spinnerActive: false,
                    })
                },
                (error) => {
                    console.log("error")
                    this.setState({
                        error
                    });
                }
            )
    }

    sendReport(){
        html2canvas(document.body).then((canvas) => {
            var dataURL = canvas.toDataURL();
            console.log(dataURL)

            fetch(global.config.server.connection.url + "/api/Reports/SendDailyCheckOutReport?base64ImageString", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, ''),
                },
                body: dataURL,
                 
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result)
                    },
                )

            // let a = document.createElement("a");
            // a.download = "daglig-check-out.png";
            // a.href = canvas.toDataURL("image/png");
            // a.click();
          });
    }

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter(item => item !== collapse)
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses
            });
        }
    };

    changeFromDate = (event) => {
        this.setState({ ...this.state, fromDate: event.format("DD-MM-YYYY") })
    }

    changeToDate = (event) => {
        this.setState({ ...this.state, toDate: event.format("DD-MM-YYYY") })
    }

    toCurrency(numberString) {
        let number = parseFloat(numberString);
        return number.toLocaleString('no-NO');
    }

    daysInMonth(iMonth, iYear)
    {
    return 32 - new Date(iYear, iMonth, 32).getDate();
    }

    isWeekday(year, month, day) {
        var d = new Date(year, month, day).getDay();
        return d !==0 && day !==6;
        }

    getWeekdaysInMonth(month, year) {
        var days = this.daysInMonth(month, year);
        // console.log("DAYS IN MONTH ===>>")
        // console.log(days)

        var weekdays = 0;
        for(var i=0; i< days; i++) {
            if (this.isWeekday(year, month, i+1)) weekdays++;
        }
        
        // console.log("WEEKDAYS IN MONTH ===>>")
        // console.log(weekdays)

        return weekdays;
        }

    getRemanningDays = function () {
        var date = new Date();
        var time = new Date(date.getTime());
        time.setMonth(date.getMonth() + 1);
        time.setDate(0);
        var days = time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
        // console.log("REMAINING DAYS IN MONTH ===>>")
        // console.log(days)

        return days;
    }

    // getBusinessDatesCount(startDate, endDate) {
        
    //     let count = 0;
    //     const curDate = new Date(startDate.getTime());
    //     while (curDate <= endDate) {
    //         const dayOfWeek = curDate.getDay();
    //         if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
    //         curDate.setDate(curDate.getDate() + 1);
    //     }
    //     alert(count);
    //     return count;
    // }


    getBusinessDatesCount() {
        let count = 0;
        let curDate = new Date();
        let year = curDate.getFullYear();
        let month = curDate.getMonth();
        let toDateTemp = new Date(year, month + 1, 0);
        
        let toDate = new Date(toDateTemp);
    
        while (curDate <= toDate) {
            const dayOfWeek = curDate.getDay();
            if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
            curDate.setDate(curDate.getDate() + 1);
        }
        return count;
    }

    render() {
        const { loadingBookings, loadingNextDay, loadingCardPaymentPercentage } = this.state;

        if (loadingBookings || loadingNextDay || loadingCardPaymentPercentage) {
            return <Loader />
        }

        return (
            <>
                <div className="content">

                    <Row id="printBody">
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Daglig salgsrapport</CardTitle>
                                    <Row>
                                        <Col md="2">
                                            <Label style={{ color: 'black' }}><b>Velg dato:</b></Label>
                                            <ReactDatetime
                                                value={this.state.fromDate}
                                                closeOnSelect={true}
                                                dateFormat='DD-MM-YY'
                                                timeFormat={false}
                                                onChange={this.changeFromDate}
                                                onBlur={this.getData}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Velg fra-dato",
                                                }}


                                            />
                                        </Col>
                                        {/* <Col md="2">
                                            <Label>Til dato:</Label>
                                            <ReactDatetime
                                                value={this.state.toDate}
                                                closeOnSelect={true}
                                                dateFormat='DD-MM-YY'
                                                timeFormat={false}
                                                onChange={this.changeToDate}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Velg til-dato"
                                                }}
                                            />
                                        </Col> */}

                                        <Col md="10" style={{ textAlign: "left", position: 'relative' }}>
                                            <div style={{ position: 'absolute', bottom: 0 + 'px', left: 50 + 'px' }}><b>Totalt antall dager igjen av måned: {this.getRemanningDays()}  
                                            <br></br>  Arbeidsdager igjen av måned: {this.getBusinessDatesCount()} </b></div>
                                            <div style={{ position: 'absolute', bottom: 0 + 'px', left: 100 + 'px' }}><b></b></div>
                                        </Col>

                                        {/* <Col md="2" style={{ textAlign: "right" }}>
                                            <Button outline color="secondary" onClick={e => this.sendReport()}>
                                                Send rapport
                                            </Button>
                                        </Col> */}

                                    </Row>
                                </CardHeader>

                              

                                <CardBody>
                                    <ReactTable
                                        expanded={this.state.expanded}
                                        data={this.state.dailyCheckOutArray}
                                        pageSize={this.state.dailyCheckOutArray.length}
                                        showPagination={false}
                                        previousText="Forrige"
                                        nextText="Neste"
                                        className="-striped -highlight secondary-pagination"
                                        columns={[
                                            {
                                                expander: true,
                                                width: 35,
                                                Expander: ({ isExpanded, ...rest }) => {
                                                    if (rest.row.name.type == null) {
                                                        return (
                                                            <div>{isExpanded ? <span><b>-</b></span> : <span><b>+</b></span>}</div>
                                                        );
                                                    }

                                                    return <span />;
                                                }
                                            },
                                            // {
                                            //     Header: "Id",
                                            //     id: "id",
                                            //     accessor: "id",
                                            //     filterable: false,
                                            //     sortable: false,
                                            //     width: 100
                                            // },
                                            {
                                                Header: "Navn",
                                                id: "name",
                                                accessor: "name",
                                                filterable: false,
                                                sortable: false,
                                                headerStyle: { textAlign: 'left' }
                                            },
                                            {
                                                Header: "Omsetning",
                                                id: "totalOmsetning",
                                                accessor: "totalOmsetning",
                                                //Cell: props => <div>{this.toCurrency(props.value)} </div>,
                                                filterable: false,
                                                sortable: false,
                                                width: 140,
                                                style: { textAlign: 'right' }
                                            },
                                            {
                                                Header: "Antall Jobber",
                                                id: "antallJobber",
                                                accessor: "antallJobber",
                                                filterable: false,
                                                sortable: false,
                                                width: 150,
                                                headerStyle: { textAlign: 'left' },
                                                style: { textAlign: 'right' }
                                            },
                                            {
                                                Header: "Snitt Omsetning",
                                                id: "snittOmsetning",
                                                accessor: "snittOmsetning",
                                                //Cell: props => <div>{this.toCurrency(props.value)} </div>,
                                                filterable: false,
                                                sortable: false,
                                                width: 150,
                                                style: { textAlign: 'right' }
                                            },
                                            {
                                                Header: "Kortbetaling",
                                                id: "kortOmsetning",
                                                accessor: "kortBetaling",
                                                filterable: false,
                                                sortable: false,
                                                width: 140,
                                                //Cell: props => <div>{props.value} %</div>,
                                                headerStyle: { textAlign: 'left' },
                                                style: { textAlign: 'right' }
                                            },
                                            {
                                                Header: "Jobber i morgen",
                                                id: "jobberNesteDag",
                                                accessor: "jobberNesteDag",
                                                filterable: false,
                                                sortable: false,
                                                width: 170,
                                                // Cell: props => <div>{this.toCurrency(props.value)} </div>,
                                                headerStyle: { textAlign: 'left' },
                                                style: { textAlign: 'right' }
                                            }
                                        ]}

                                        getTrProps={(state, rowInfo, column, instance, expanded) => {

                                            if (rowInfo.original.isSumRow === true) {
                                                return {
                                                    style:
                                                    {
                                                        borderTop: "solid 1px gray",
                                                        //background: 'white',
                                                        color: 'black'
                                                    }
                                                }
                                            }

                                            return {
                                                onClick: e => {
                                                    if (this.state.rowexpanded === rowInfo.viewIndex) {
                                                        this.setState({ expanded: { [rowInfo.viewIndex]: false } });
                                                        this.setState({ rowexpanded: null });
                                                    }
                                                    else {
                                                        fetch(global.config.server.connection.url + "/api/Reports/GetDailyCheckOutDetails?fromDate=" + this.state.fromDate + "&salgsingeniorId=" + rowInfo.original.id, {
                                                            method: "GET",
                                                            headers: {
                                                                'Accept': 'application/json',
                                                                'Content-Type': 'application/json',
                                                                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
                                                            },
                                                        })
                                                            .then(res => res.json())
                                                            .then(
                                                                (result) => {
                                                                    this.setState({
                                                                        spinnerActive: false,
                                                                        bookingsSubArray: result.map((prop, key) => {
                                                                            return {
                                                                                id: key,
                                                                                fgrNr: prop["fgrNr"],
                                                                                kundeNr: prop["kundeNr"],
                                                                                montorNavn: prop["montorNavn"],
                                                                                omsetning: this.toCurrency(prop["omsetning"]),
                                                                                kortbetaling: this.toCurrency(prop["kortBetaling"]),
                                                                                
                                                                            }
                                                                        })
                                                                    });
                                                                },
                                                            )

                                                        this.setState({ spinnerActive: true });
                                                        this.setState({ selectedUserName: rowInfo.row.employeeName });
                                                        this.setState({ expanded: { [rowInfo.viewIndex]: true } });
                                                        this.setState({ rowexpanded: rowInfo.viewIndex });
                                                    }
                                                }
                                            };
                                        }}

                                        SubComponent={row => {
                                            return (
                                                <div style={{ padding: "20px" }}>
                                                    <ReactTable
                                                        minRows={1}
                                                        data={this.state.bookingsSubArray}
                                                        // getTrProps={(state, rowInfo, column, instance, expanded) => {
                                                        //     return {
                                                        //         style: {
                                                        //             background: '#AAA7A4',
                                                        //             color: 'white'
                                                        //         }
                                                        //     }
                                                        // }}
                                                        columns={[
                                                            {
                                                                Header: "FgrNr",
                                                                accessor: "fgrNr",
                                                                width: 150,
                                                                filterable: false,
                                                                sortable: false
                                                            },
                                                            {
                                                                Header: "KundeNr",
                                                                accessor: "kundeNr",
                                                                width: 150,
                                                                filterable: false,
                                                                sortable: false,
                                                                headerStyle: { textAlign: 'left' }
                                                            },
                                                            {
                                                                Header: "Montør",
                                                                accessor: "montorNavn",
                                                                width: 300,
                                                                filterable: false,
                                                                sortable: false
                                                            },
                                                            {
                                                                Header: "Omsetning",
                                                                accessor: "omsetning",
                                                                filterable: false,
                                                                sortable: false,
                                                                headerStyle: { textAlign: 'right' },
                                                                style: { textAlign: 'right' },
                                                                width: 150,
                                                            },
                                                            {
                                                                Header: "Kortbetaling",
                                                                accessor: "kortbetaling",
                                                                filterable: false,
                                                                sortable: false,
                                                                headerStyle: { textAlign: 'right' },
                                                                style: { textAlign: 'right' },
                                                            },
                                                        ]}
                                                        showPagination={false}
                                                    />

                                                </div>
                                            );
                                        }}

                                    />

                                    {/* <br></br><br></br>

                                    <ReactTable
                                        minRows={1}
                                        data={this.state.dailyCheckOutArray}
                                        // getTrProps={(state, rowInfo, column, instance, expanded) => {
                                        //     return {
                                        //         style: {
                                        //             background: '#AAA7A4',
                                        //             color: 'white'
                                        //         }
                                        //     }
                                        // }}
                                        columns={[
                                            {
                                                Header: "Budsjett/Prognose",
                                                accessor: "name",
                                                width: 285,
                                                filterable: false,
                                                sortable: false,
                                            },
                                            {
                                                Header: "OMsetning",
                                                accessor: "totalOmsetning",
                                                filterable: false,
                                                sortable: false,
                                                width: 150,
                                                headerStyle: { textAlign: 'left'},
                                                style: { textAlign: 'right'},
                                            },
                                            {
                                                Header: "Antall jobber",
                                                accessor: "antallJobber",
                                                filterable: false,
                                                sortable: false,
                                                width: 150,
                                                style: { textAlign: 'right'},
                                            },
                                            {
                                                Header: "Snitt omsetning",
                                                accessor: "snittOmsetning",
                                                filterable: false,
                                                sortable: false,
                                                width: 150,
                                                headerStyle: { textAlign: 'left'},
                                                style: { textAlign: 'right'},
                                            },
                                            {
                                                Header: "Kortbetaling",
                                                accessor: "kortBetaling",
                                                filterable: false,
                                                sortable: false,
                                                width: 150,
                                                headerStyle: { textAlign: 'left'},
                                                style: { textAlign: 'right'},
                                            },
                                        ]}
                                        showPagination={false}
                                    /> */}

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </div>
            </>
        );
    }
}

export default DailyCheckOut;
