const chartCustomerSurvey = {
    data: {
        labels: [
            'Boligelektrikeren',
        ],
        datasets: [
            {
                label: "Anbefale",
                borderColor: "#fcc468",
                fill: true,
                backgroundColor: "#fcc468",
                hoverBorderColor: "#fcc468",
                borderWidth: 2,
                data: []
            },
            {
                label: "Effektivitet",
                borderColor: "#4cbdd7",
                fill: true,
                backgroundColor: "#4cbdd7",
                hoverBorderColor: "#4cbdd7",
                borderWidth: 2,
                data: []
            },
            {
                label: "Bruke igjen",
                borderColor: "#EF8157",
                fill: true,
                backgroundColor: "#EF8157",
                hoverBorderColor: "#EF8157",
                borderWidth: 2,
                data: []
            },
            {
                label: "Kvalitet",
                borderColor: "#6BD098",
                fill: true,
                backgroundColor: "#6BD098",
                hoverBorderColor: "#6BD098",
                borderWidth: 2,
                data: []
            },
            {
                label: "Pålitelighet",
                borderColor: "#BB1257",
                fill: true,
                backgroundColor: "#BB1257",
                hoverBorderColor: "#BB1257",
                borderWidth: 2,
                data: []
            },
            {
                label: "Pris",
                borderColor: "#FF5615",
                fill: true,
                backgroundColor: "#FF5615",
                hoverBorderColor: "#FF5615",
                borderWidth: 2,
                data: []
            },
            {
                label: "Totalpris",
                borderColor: "#FF1266",
                fill: true,
                backgroundColor: "#FF1266",
                hoverBorderColor: "#FF1266",
                borderWidth: 2,
                data: []
            }
        ]
    },
    options: {
        tooltips: {
            tooltipFillColor: "rgba(0,0,0,0.5)",
            tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipFontSize: 14,
            tooltipFontStyle: "normal",
            tooltipFontColor: "#fff",
            tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
            tooltipTitleFontSize: 14,
            tooltipTitleFontStyle: "bold",
            tooltipTitleFontColor: "#fff",
            tooltipYPadding: 6,
            tooltipXPadding: 6,
            tooltipCaretSize: 8,
            tooltipCornerRadius: 6,
            tooltipXOffset: 10
        },
        legend: {
            display: true
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        fontColor: "#9f9f9f",
                        fontStyle: "bold",
                        beginAtZero: true,
                        maxTicksLimit: 4,
                        padding: 20
                    },
                    gridLines: {
                        zeroLineColor: "transparent",
                        display: true,
                        drawBorder: true,
                        color: "#9f9f9f"
                    }
                }
            ],
            xAxes: [
                {
                    barPercentage: 0.6,
                    gridLines: {
                        zeroLineColor: "white",
                        display: true,
                        drawBorder: true,
                        color: "transparent"
                    },
                    ticks: {
                        padding: 20,
                        fontColor: "#9f9f9f",
                        fontStyle: "bold"
                    }
                }
            ]
        }
    }
}
export default chartCustomerSurvey