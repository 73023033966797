import React from "react";
// react plugin used to create charts
import Loader from "../components/Loader/Loader";
import ReactTable from "react-table";
import ReactDatetime from "react-datetime";
import LoadingOverlay from 'react-loading-overlay';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Label,
    Row,
    Col,
} from "reactstrap";


const o_date = new Intl.DateTimeFormat();
const f_date = (m_ca, m_it) => Object({ ...m_ca, [m_it.type]: m_it.value });
const m_date = o_date.formatToParts().reduce(f_date, {});

if (m_date.month.length < 2) {
    m_date.month = "0" + m_date.month
}
if (m_date.day.length < 2) {
    m_date.dat = "0" + m_date.day
}

class DailyCheckOut extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            company: props.company,
            toDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            fromDate: m_date.day + '-' + m_date.month + '-' + m_date.year,
            loadingBookings: false,
            bookingsSubArray: [],
            bookingsArray: [],
            bookings: "",
            openedCollapses: [],
            spinnerActive: false,
            expanded: {}
        };

        this.refreshClick = this.refreshClick.bind(this);
    }

    componentDidMount() {

        const { company } = this.state;

        fetch(global.config.server.connection.url + "/api/Reports/GetJobsDonesPrCompany?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate +"&companyName=" +company, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    this.setState({
                        loadingBookings: false,
                        spinnerActive: false,
                        bookingsArray: result.map((prop, key) => {
                            return {
                                id: key,
                                companyName: prop["companyName"],
                                numberOfBookings: prop["numberOfBookings"],
                            }
                        })
                    });
                },
            )
    }

    collapsesToggle = collapse => {
        let openedCollapses = this.state.openedCollapses;
        if (openedCollapses.includes(collapse)) {
            this.setState({
                openedCollapses: openedCollapses.filter(item => item !== collapse)
            });
        } else {
            openedCollapses.push(collapse);
            this.setState({
                openedCollapses: openedCollapses
            });
        }
    };

    refreshClick() {
        const { company } = this.state;

        this.setState({
            spinnerActive: true,
        })

        fetch(global.config.server.connection.url + "/api/Reports/GetJobsDonesPrCompany?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate +"&companyName=" +company, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result)
                    this.setState({
                        loadingBookings: false,
                        spinnerActive: false,
                        bookingsArray: result.map((prop, key) => {
                            return {
                                id: key,
                                companyName: prop["companyName"],
                                numberOfBookings: prop["numberOfBookings"],
                            }
                        })
                    });
                },
            )
    }





    changeFromDate = (event) => {
        this.setState({ ...this.state, fromDate: event.format("DD-MM-YYYY") })
    }

    changeToDate = (event) => {
        this.setState({ ...this.state, toDate: event.format("DD-MM-YYYY") })
    }


    render() {

        const { loadingBookings, spinnerActive } = this.state;

        if (loadingBookings) {
            return <Loader />
        }

        return (
            <>
                <div className="content">
                
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Jobber utført pr. selskap</CardTitle>
                                    <Row>
                                        <Col md="2">
                                            <Label>Fra dato:</Label>
                                            <ReactDatetime
                                                value={this.state.fromDate}
                                                closeOnSelect={true}
                                                dateFormat='DD-MM-YY'
                                                timeFormat={false}
                                                onChange={this.changeFromDate}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Velg fra-dato",
                                                }}


                                            />
                                        </Col>
                                        <Col md="2">
                                            <Label>Til dato:</Label>
                                            <ReactDatetime
                                                value={this.state.toDate}
                                                closeOnSelect={true}
                                                dateFormat='DD-MM-YY'
                                                timeFormat={false}
                                                onChange={this.changeToDate}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Velg til-dato"
                                                }}
                                            />
                                        </Col>
                                    </Row>

                                    <Row style={{ marginTop: 10 + 'px' }}>
                                        <Col md="12">
                                            <Button className="btn-round" color="success" onClick={e => this.refreshClick()}>
                                                Oppdater
                                            </Button>
                                        </Col>
                                    </Row>

                                </CardHeader>

                                <LoadingOverlay
                                    active={spinnerActive}
                                    spinner
                                    styles={{
                                        spinner: (base) => ({
                                            ...base,
                                            width: '150px',
                                            '& svg circle': {
                                                stroke: 'rgba(107, 208, 152, 1)'
                                            }
                                        })
                                    }}
                                    text='Loading your content...'
                                >
                                </LoadingOverlay>

                                <CardBody>
                                    <ReactTable
                                        expanded={this.state.expanded}
                                        data={this.state.bookingsArray}
                                        defaultPageSize={5}
                                        showPaginationTop
                                        showPaginationBottom
                                        previousText="Forrige"
                                        nextText="Neste"
                                        className="-striped -highlight success-pagination"
                                        columns={[
                                            {
                                                Header: "Selskap",
                                                accessor: "companyName",
                                                filterable: false
                                            },
                                            {
                                                Header: "Antall jobber",
                                                accessor: "numberOfBookings",
                                                filterable: false,
                                                headerStyle: { textAlign: 'left' }
                                            },
                                        ]}
                                        getTrProps={(state, rowInfo, column, instance, expanded) => {
                                            return {
                                                onClick: e => {
                                                    if (this.state.rowexpanded === rowInfo.viewIndex) {
                                                        this.setState({ expanded: { [rowInfo.viewIndex]: false } });
                                                        this.setState({ rowexpanded: null });
                                                    }
                                                    else {
                                                        fetch(global.config.server.connection.url + "/api/Reports/GetJobsDonesPrCompanyDetails?fromDate=" + this.state.fromDate + "&toDate=" + this.state.toDate + "&selectedCompany=" + rowInfo.original.companyName +"&companyName=" +this.state.company, {
                                                            method: "POST",
                                                            headers: {
                                                                'Accept': 'application/json',
                                                                'Content-Type': 'application/json',
                                                                'Authorization': 'Bearer ' + sessionStorage.access_token.replace(/['"]+/g, '')
                                                            },
                                                        })
                                                            .then(res => res.json())
                                                            .then(
                                                                (result) => {
                                                                    console.log(result)
                                                                    this.setState({
                                                                        spinnerActive: false,
                                                                        bookingsSubArray: result.map((prop, key) => {
                                                                            return {
                                                                                id: key,
                                                                                jobId: prop["jobId"],
                                                                                companyName: prop["companyName"],
                                                                                source: prop["source"],
                                                                                jobType: prop["jobType"],
                                                                                date: prop["date"],
                                                                                time: prop["time"],
                                                                            }
                                                                        })
                                                                    });
                                                                },
                                                            )

                                                        this.setState({ spinnerActive: true });
                                                        this.setState({ selectedUserName: rowInfo.row.employeeName });
                                                        this.setState({ expanded: { [rowInfo.viewIndex]: true } });
                                                        this.setState({ rowexpanded: rowInfo.viewIndex });
                                                    }
                                                }
                                            };
                                        }}

                                        //localhost:44347
                                        SubComponent={row => {

                                            return (
                                                <div style={{ padding: "20px" }}>
                                                    <ReactTable
                                                        minRows={1}
                                                        data={this.state.bookingsSubArray}
                                                        columns={[
                                                            {
                                                                Header: "JobId",
                                                                accessor: "jobId",
                                                                width: 80,
                                                                filterable: false,
                                                            },
                                                            {
                                                                Header: "Selskap",
                                                                accessor: "companyName",
                                                                width: 150,
                                                                filterable: false,
                                                                headerStyle: { textAlign: 'left' }
                                                            },
                                                            {
                                                                Header: "Kilde",
                                                                accessor: "source",
                                                                width: 150,
                                                                filterable: false
                                                            },
                                                            {
                                                                Header: "Jobbtype",
                                                                accessor: "jobType",
                                                                filterable: false
                                                            },
                                                            {
                                                                Header: "Dato",
                                                                accessor: "date",
                                                                filterable: false,
                                                                width: 150,
                                                            },
                                                            {
                                                                Header: "Tid",
                                                                accessor: "time",
                                                                width: 90,
                                                                filterable: false,
                                                                headerStyle: { textAlign: 'left' }
                                                            },

                                                        ]}
                                                        showPagination={false}
                                                    />
                                                </div>
                                            );
                                        }}

                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </div>
            </>
        );
    }
}

export default DailyCheckOut;
